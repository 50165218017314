import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Settings.style';
import { AuthContext } from '../../../contexts/Auth';
import { SnackbarContext } from '../../../contexts/Snackbar';
import { StudiesContext } from '../../../contexts/Studies';
import Grid from '@material-ui/core/Grid';
import {
  Menu,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import ChangeStatusNoteDialog from './ChangeStatusNoteDialog';
import ApplicationDetails from './ApplicationDetails';
import StudyService from '../../../Services/StudyService/StudyService';
import firebase from '../../../Firebase';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import NewOfflineVisitDialog from './NewOfflineVisit/NewOfflineVisitDialog';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import '../../../../node_modules/antd/dist/antd.css';
import ChangeNotesDialog from './ChangeNotesDialog';
import ApplicationComments from '../../../Components/comments/ApplicationComments';

var momentTZ = require('moment-timezone');

const useStyles = makeStyles(MuiStyleFunction);

const AccountDetails = ({ study, tab }) => {
  const classes = useStyles();

  const { authUser, userData } = useContext(AuthContext);

  const { studies, setStudies } = useContext(StudiesContext);
  const { show } = useContext(SnackbarContext);

  useEffect(() => { }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [timezonesAnchorEl, setTimezonesAnchorEl] = useState(null);
  const [noteType, setNoteType] = useState('edit');
  const [openReviewDrawer, setOpenReviewDrawer] = useState(false);
  const [applicationId, setApplicationId] = useState(null);

  const handleCloseStatusMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseTimezonesMenu = () => {
    setTimezonesAnchorEl(null);
  };

  const [currentApplication, setCurrentApplication] = useState(null);

  const [search, setSearch] = useState('');
  const [applicationsToShow, setApplicationsToShow] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openStatusNoteDialog, setOpenStatusNoteDialog] = useState(false);
  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [openNewOfflineVisitDialog, setOpenNewOfflineVisitDialog] =
    useState(false);

  const [timezone, setTimezone] = useState('My Timezone');

  const [expanded, setExpanded] = useState(null);

  const handleClickOnStatusMenu = (event, index) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentApplication(applicationsToShow[index]);
    setNoteType('new');
  };

  const handleClickOnEditNote = (event, index) => {
    event.stopPropagation();
    setCurrentApplication(applicationsToShow[index]);
    setNoteType('edit');
    setOpenNotesDialog(true);
  };

  const handleChangeSearch = (value) => {
    setSearch(value);
    if (value === '') {
      setApplicationsToShow(applications);
      return;
    }
    let matchedApplications = [];
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      let patientName =
        application.userData.firstName.toLowerCase() +
        ' ' +
        application.userData.lastName.toLowerCase();
      let studyAddress = application.studyAddress.toLowerCase();
      let studyName = application.studyLocation.toLowerCase();
      let email = application.userData.email.toLowerCase();
      if (
        patientName.includes(value.toLowerCase()) ||
        studyAddress.includes(value.toLowerCase()) ||
        studyName.includes(value.toLowerCase()) ||
        email.includes(value.toLowerCase())
      ) {
        matchedApplications.push(application);
      }
    }
    setApplicationsToShow(matchedApplications);
  };

  useEffect(() => { }, [applicationsToShow]);

  useEffect(() => {
    if (study) {
      let applications = study.applications;
      let relevants = [];
      for (let index = 0; index < applications.length; index++) {
        const application = applications[index];
        const status = application.status[application.status.length - 1].status;
        if (!application.userData) continue;
        if (tab === 'Applied') {
          if (
            status === 'Applied' ||
            status === 'Different Date Needed' ||
            status === 'Schedule Appointment' ||
            status === 'Update Progress'
          ) {
            relevants.push(application);
          }
        } else if (tab === 'Pending') {
          if (status === 'Pending') {
            relevants.push(application);
          }
        } else if (tab === 'Scheduled') {
          if (status === 'Confirmed Appointment') {
            relevants.push(application);
          }
        } else if (tab === 'Failed') {
          if (status === 'Not Eligible') {
            relevants.push(application);
          }
          if (status === 'Failed') {
            relevants.push(application);
          }
        } else if (tab === 'Eligible') {
          if (status === 'Completed') {
            relevants.push(application);
          }
        } else if (tab === 'Post Call') {
          if (status === 'TBD') {
            relevants.push(application);
          }
        }
      }
      setApplications(
        relevants.sort((a, b) => b.appliedAt.seconds - a.appliedAt.seconds),
      );
      setApplicationsToShow(
        relevants.sort((a, b) => b.appliedAt.seconds - a.appliedAt.seconds),
      );
      setLoading(false);
    }
  }, [study]);

  const openReview = (id) => {
    setApplicationId(id);
    setOpenReviewDrawer(true);
  };

  const getFormattedDate = (item) => {
    var meetingDataAndTimeInUserTimezone = item.selectedDate
      ? moment(item.selectedDate.seconds * 1000).format('MM/DD/YYYY')
      : 'Not Selected';
    return meetingDataAndTimeInUserTimezone;
  };

  const getTimeInLocalTimezone = (item) => {
    if (!item.selectedDate) return 'Not Selected';
    let meetingDateTime = `${moment(item.selectedDate.seconds * 1000).format(
      'YYYY-MM-DD',
    )} ${item.selectedTime}`;
    var meetingDataAndTimeInUserTimezone = momentTZ.tz(
      meetingDateTime,
      'YYYY-MM-DD h:mm A',
      item.availability.tz,
    );
    if (timezone !== 'Patient Timezone') {
      meetingDataAndTimeInUserTimezone.tz(moment.tz.guess());
    }
    return meetingDataAndTimeInUserTimezone.format('h:mm a z');
  };

  const getApplitedTimeAsDaysAgo = (appliedAt) => {
    var currDate = moment();
    if (currDate.diff(moment(appliedAt.seconds * 1000), 'days') === 0) {
      return moment(appliedAt.seconds * 1000).fromNow();
    }
    return 'at ' + moment(appliedAt.seconds * 1000).format('LL');
  };

  const changeStatus = (status) => {
    setOpenStatusNoteDialog(true);
    setNewStatus(status);
    handleCloseStatusMenu();
  };

  const changeTimezone = (selectedTimezone) => {
    setTimezone(selectedTimezone);
    setTimezonesAnchorEl(null);
  };

  const getFormattedStatus = (status) => {
    if (status === 'Failed') {
      return 'Not Eligible';
    } else if (status === 'Applied') {
      return 'Applied';
    } else if (status === 'Pending') {
      return 'Pending';
    } else if (status === 'Scheduled') {
      return 'Confirmed Appointment';
    } else if (status === 'Completed') {
      return 'Completed';
    } else if (status === 'Failed') {
      return 'Not Eligible';
    } else if (status === 'Post Call') {
      return 'TBD';
    }
    return 'Applied';
  };

  const updateApplicationNotes = async (note) => {
    let updatedStudiesArray = studies;
    for (let index = 0; index < updatedStudiesArray.length; index++) {
      let currentStudy = updatedStudiesArray[index];
      if (currentStudy.document.id === study.document.id) {
        for (
          let applicationIndex = 0;
          applicationIndex < currentStudy.applications.length;
          applicationIndex++
        ) {
          let application = currentStudy.applications[applicationIndex];
          if (application.id === currentApplication.id) {
            application.notes = note;
            currentStudy.applications[applicationIndex] = application;
            updatedStudiesArray[index] = currentStudy;
            setStudies([...updatedStudiesArray]);
            show('success', 'Saved');
          }
        }
      }
    }
    StudyService.updateApplicationByDoc(currentApplication.doc.ref, {
      notes: note,
    });
  };

  const updateApplicationWithNewStatus = async (status, note) => {
    setLoading(true);
    let statusArray = currentApplication.status;
    statusArray.push({
      status: status,
      datetime: firebase.firestore.Timestamp.now(),
      note: note,
    });
    let data = { status: statusArray, notes: note };
    console.log('data', data);
    await StudyService.updateApplicationByDoc(currentApplication.doc.ref, data);
    setLoading(false);
  };

  const editApplicationStatusNote = async (newNote) => {
    setLoading(true);
    const doc = currentApplication.doc;
    let data = currentApplication;
    data.notes = newNote;
    delete data.doc;
    await StudyService.updateApplicationByDoc(doc.ref, data);
    setLoading(false);
  };

  const checkIfToShowNotification = (application) => {
    const status = application.status[application.status.length - 1].status;
    if (
      (status === 'Applied' ||
        status === 'Different Date Needed' ||
        status === 'Schedule Appointment') &&
      !application.reviewed
    ) {
      return true;
    }
  };

  return (
    <div
      style={{
        overflow: 'hidden',
        fontFamily: "'Poppins', sans-serif",
        textAlign: 'left',
        paddingBottom: 24,
      }}
    >
      <Backdrop
        className={classes.backdrop}
        open={loading}
        style={{ zIndex: 99999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ChangeStatusNoteDialog
        noteType={noteType}
        edit={editApplicationStatusNote}
        currentApplication={currentApplication}
        status={newStatus}
        open={openStatusNoteDialog}
        setOpen={setOpenStatusNoteDialog}
        update={updateApplicationWithNewStatus}
      />
      <ChangeNotesDialog
        update={updateApplicationNotes}
        open={openNotesDialog}
        setOpen={setOpenNotesDialog}
      />
      <NewOfflineVisitDialog
        open={openNewOfflineVisitDialog}
        setOpen={setOpenNewOfflineVisitDialog}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseStatusMenu}
      >
        <MenuItem
          onClick={() => changeStatus('Pending')}
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          Pending
        </MenuItem>
        <MenuItem
          onClick={() => changeStatus('Scheduled')}
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          Scheduled
        </MenuItem>
        <MenuItem
          onClick={() => changeStatus('Post Call')}
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          Post Call
        </MenuItem>
        <MenuItem
          onClick={() => changeStatus('Failed')}
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          Failed
        </MenuItem>
        <MenuItem
          onClick={() => changeStatus('Completed')}
          style={{ fontFamily: "'Poppins', sans-serif" }}
        >
          Completed
        </MenuItem>
      </Menu>
      <Hidden smDown>
        <Grid container direction="row" spacing={8}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            style={{ paddingBottom: 32 }}
          >
            <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
              <Typography className={classes.settingTitle} variant="h2">
                {applications.length} {tab}
              </Typography>
              <div style={{ flexGrow: 1 }}>
                <input
                  value={search}
                  placeholder="Search"
                  className={classes.searchInput}
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
              </div>
              <div
                onClick={(e) => setTimezonesAnchorEl(e.currentTarget)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 12,
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: 16,
                    color: '#4A4A4A70',
                  }}
                >
                  {timezone}
                </div>
                <ExpandMoreIcon
                  style={{
                    color: '#4A4A4A70',
                    width: 24,
                    height: 24,
                    marginTop: 6,
                  }}
                  size={24}
                />
              </div>
              {tab === 'Scheduled' && (
                <div
                  onClick={(e) =>
                    window.open(
                      'https://app.knowrare.com/signup?offline=true',
                      '_blank',
                    )
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 12,
                    cursor: 'pointer',
                  }}
                >
                  <img
                    alt=""
                    src={require('../../../Assets/Images/newOfflineVisit.png')}
                    style={{ cursor: 'pointer', height: 32, marginLeft: 8 }}
                  />
                </div>
              )}
              <Menu
                anchorEl={timezonesAnchorEl}
                keepMounted
                open={Boolean(timezonesAnchorEl)}
                onClose={handleCloseTimezonesMenu}
              >
                <MenuItem
                  onClick={() => changeTimezone('My Timezone')}
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                >
                  My Timezone
                </MenuItem>
                <MenuItem
                  onClick={() => changeTimezone('Patient Timezone')}
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                >
                  Patient Timezone
                </MenuItem>
              </Menu>
            </div>
            {/* <Divider /> */}
            {applicationsToShow.map((item, index) => (
              <Accordion
                key={index}
                elevation={0}
                classes={{ root: classes.accordion }}
                style={{ position: 'static', marginTop: 24 }}
                expanded={expanded === index}
              >
                <AccordionSummary
                  classes={{ root: classes.accordionSummary }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div
                    style={{
                      height: 62,
                      display: 'flex',
                      flexFlow: 'row',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'column',
                        flexGrow: 1,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: 12,
                          alignItems: 'center',
                          fontSize: 18,
                          fontWeight: 600,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                          }}
                        >
                          {checkIfToShowNotification(item) && (
                            <div
                              style={{
                                width: 6,
                                height: 6,
                                borderRadius: 3,
                                background: 'rgb(240, 99, 99)',
                                marginTop: 2,
                              }}
                            ></div>
                          )}
                          <div>
                            {`${item.userData.firstName} ${item.userData.lastName}`}
                          </div>
                        </div>
                        <div>
                          <div style={{ display: 'flex', gap: 12 }}>
                            {item.status[item.status.length - 1].status ===
                              'Different Date Needed' && (
                                <div className={classes.tagLabel}>RESCHEDULE</div>
                              )}
                            {item.offlineVisit === true && (
                              <div className={classes.tagLabel}>
                                OFFLINE VISIT
                              </div>
                            )}
                            {tab !== 'Failed' &&
                              tab !== 'Eligible' &&
                              getTimeInLocalTimezone(item) !== 'Not Selecte' &&
                              (getFormattedDate(item) !== 'Not Selected' ||
                                getTimeInLocalTimezone(item) !==
                                'Not Selected') && (
                                <div
                                  className={classes.appointmentDateTimeLabel}
                                >
                                  {getTimeInLocalTimezone(item)} •{' '}
                                  {getFormattedDate(item)}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: 14 }}>
                          Applied {getApplitedTimeAsDaysAgo(item.appliedAt)}{' '}
                          with {item.studyLocation}
                        </div>
                        <div
                          className={classes.detailsButton}
                          onClick={() =>
                            setExpanded(expanded === index ? null : index)
                          }
                        >
                          <div style={{ marginRight: 4 }}>Review</div>
                          {expanded !== index ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLessRoundedIcon />
                          )}
                        </div>
                      </div>
                      {item.status[item.status.length - 1].changedBy && (
                        <div style={{ opacity: 0.5 }}>
                          <i>
                            Last updated by{' '}
                            {item.status[item.status.length - 1].changedBy.name}
                          </i>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        'height': 62,
                        'display': 'flex',
                        'flexFlow': 'row',
                        'gap': 12,
                        '&:hover': { opacity: 0.5 },
                      }}
                    >
                      <img
                        id={item.userId + String(index)}
                        key={item.userId + String(index)}
                        onClick={(e) => handleClickOnStatusMenu(e, index)}
                        alt=""
                        src={require('../../../Assets/Images/moveStatusIcon.png')}
                        className={classes.changeInquirieStatusButton}
                      />

                      <ApplicationComments study={study} application={applicationsToShow[index]} />
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetails }}>
                  <ApplicationDetails item={item} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container direction="row" spacing={8} style={{ marginTop: -16 }}>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flexGrow: 1 }}>
                <input
                  value={search}
                  placeholder="Search"
                  className={classes.searchInputMobile}
                  onChange={(e) => handleChangeSearch(e.target.value)}
                />
              </div>
              <Menu
                anchorEl={timezonesAnchorEl}
                keepMounted
                open={Boolean(timezonesAnchorEl)}
                onClose={handleCloseTimezonesMenu}
              >
                <MenuItem
                  onClick={() => changeTimezone('My Timezone')}
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                >
                  My Timezone
                </MenuItem>
                <MenuItem
                  onClick={() => changeTimezone('Patient Timezone')}
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                >
                  Patient Timezone
                </MenuItem>
              </Menu>
            </div>
            {applicationsToShow.map((item, index) => (
              <Accordion
                key={index}
                elevation={0}
                classes={{ root: classes.accordionMobile }}
                style={{
                  border: checkIfToShowNotification(item)
                    ? '1px solid red'
                    : 'none',
                  position: 'static',
                  marginTop: 24,
                }}
                expanded={expanded === index}
              >
                <AccordionSummary
                  classes={{ root: classes.accordionSummary }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div
                    style={{
                      height: 'auto',
                      display: 'flex',
                      flexFlow: 'row',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'column',
                        flexGrow: 1,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: 12,
                          alignItems: 'center',
                          fontSize: 18,
                          fontWeight: 600,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                          }}
                        >
                          <div>
                            {`${item.userData.firstName} ${item.userData.lastName}`}
                          </div>
                        </div>
                        <div>
                          <div style={{ display: 'flex', gap: 12 }}>
                            {item.status[item.status.length - 1].status ===
                              'Different Date Needed' && (
                                <div className={classes.tagLabel}>RESCHEDULE</div>
                              )}
                            {item.offlineVisit === true && (
                              <div className={classes.tagLabel}>
                                OFFLINE VISIT
                              </div>
                            )}
                            {tab !== 'Failed' &&
                              tab !== 'Eligible' &&
                              getTimeInLocalTimezone(item) !== 'Not Selecte' &&
                              (getFormattedDate(item) !== 'Not Selected' ||
                                getTimeInLocalTimezone(item) !==
                                'Not Selected') && (
                                <div
                                  className={classes.appointmentDateTimeLabel}
                                  style={{
                                    fontSize: 10,
                                    flex: 'none',
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    height: 18,
                                  }}
                                >
                                  {getTimeInLocalTimezone(item)} •{' '}
                                  {getFormattedDate(item)}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: 12 }}>
                          Applied {getApplitedTimeAsDaysAgo(item.appliedAt)}{' '}
                          with {item.studyLocation}
                        </div>
                        <div
                          className={classes.detailsButton}
                          onClick={() =>
                            setExpanded(expanded === index ? null : index)
                          }
                        >
                          {expanded !== index ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLessRoundedIcon />
                          )}
                        </div>
                      </div>
                      {item.status[item.status.length - 1].changedBy && (
                        <div style={{ opacity: 0.5, fontSize: 12 }}>
                          <i>
                            Last updated by{' '}
                            {item.status[item.status.length - 1].changedBy.name}
                          </i>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        'height': 62,
                        'display': 'flex',
                        'flexFlow': 'row',
                        'gap': 12,
                        '&:hover': { opacity: 0.5 },
                      }}
                    ></div>
                  </div>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetails }}>
                  <ApplicationDetails item={item} />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      gap: 8,
                      marginTop: 16,
                    }}
                  >
                    <img
                      id={item.userId + String(index)}
                      key={item.userId + String(index)}
                      onClick={(e) => handleClickOnStatusMenu(e, index)}
                      alt=""
                      src={require('../../../Assets/Images/moveStatusIcon.png')}
                      className={classes.changeInquirieStatusButton}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
};

export default React.memo(AccountDetails);
