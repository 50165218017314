import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { AuthContext } from '../../contexts/Auth.js';
import { SnackbarContext } from '../../contexts/Snackbar.js';

import DiseasesTextField from './DiseasesTextField.js';
import { useNavigate } from 'react-router-dom';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    alignItems: 'center',
    padding: 16,
    textAlign: 'center',
    gap: 24,
  },
  title: {
    fontSize: mobile ? 28 : 32,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    color: '#4A4A4A',
    marginTop: 12,
    maxWidth: 525,
  },
  description: {
    fontSize: 16,
    fontFamily: "'Poppins', sans-serif",
    color: '#4A4A4A',
    marginTop: 12,
    maxWidth: 552,
  },
  copyButton: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    height: 44,
    borderRadius: 12,
    textTransform: 'none',
    fontSize: 16,
    color: '#4fb4e8',
  },
  generateButton: {
    'fontFamily': theme.typography.sofiaPro,
    'fontWeight': 'bold',
    'color': 'white',
    'height': 44,
    'borderRadius': 12,
    'textTransform': 'none',
    'fontSize': 16,
    'backgroundColor': '#4fb4e8',
    '&:hover': {
      backgroundColor: '#4fb4e8',
    },
    '&:disabled': {
      opacity: 0.5,
      color: 'white',
    },
  },
  box: {
    background: 'white',
    borderRadius: 12,
    boxShadow: '0px 6px 27px -7px rgba(0, 0, 0, 0.12)',
    padding: '32px 64px',
    width: 500,
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    alignItems: 'center',
    marginTop: 24,
    textAlign: 'center',
    gap: 16,
  },
  codeBox: {
    backgroundColor: '#F4F4F4',
    padding: 16,
    borderRadius: 8,
    color: '#4A4A4A',
    fontFamily: 'SF monospace',
    textAlign: 'left',
    marginTop: 16,
    minHeight: 80,
    width: '100%',
    wordBreak: 'break-all',
  },
}));

function Page404() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const { show } = useContext(SnackbarContext);

  const [utm, setUtm] = useState(null);
  const [disease, setDisease] = useState(null);

  const [code, setCode] = useState(null);

  useEffect(() => { }, []);

  const openChromeDownloadPageInNewTab = () => {
    let url = 'https://www.google.com/chrome/?brand=CHBD&brand=BNSD';
    window.open(url, '_blank').focus();
  };

  const createLink = () => {
    if (!utm) {
      alert('UTM is required');
      return;
    }
    // let link = 'https://app-knowrare.web.app/signup'
    let link = 'https://app.knowrare.com/signup';
    if (disease) {
      link += '?disease=' + disease.replace(/ /g, '%20');
    }
    if (utm) {
      if (disease) {
        link += '&utm=' + utm.replace(/ /g, '%20');
      } else {
        link += '?utm=' + utm.replace(/ /g, '%20');
      }
    }
    setCode(link);
  };

  const createWidget = () => {
    if (!utm) {
      alert('UTM is required');
      return;
    }
    let link = 'https://knowrare-widget.web.app/';
    if (disease) {
      link += '?disease=' + disease.replace(/ /g, '%20');
    }
    if (utm) {
      if (disease) {
        link += '&utm=' + utm.replace(/ /g, '%20');
      } else {
        link += '?utm=' + utm.replace(/ /g, '%20');
      }
    }
    setCode(
      `<iframe frameborder="0" src="${link}" width="800" height="600"></iframe>`,
    );
  };

  const copyCode = () => {
    navigator.clipboard.writeText(code);
    show('success', 'Copied to clipboard');
  };

  return (
    <div className={classes.root}>
      <div style={{ width: '100%', display: 'flex', marginTop: 32 }}>
        <div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
          <img
            alt=""
            src={require('../../Assets/Images/backArrow.png')}
            height={10}
            style={{ display: 'inline-block' }}
          />
          <Typography
            variant="button"
            style={{
              fontSize: 18,
              color: '#4fb4e8',
              marginLeft: 8,
              textTransform: 'none',
              fontWeight: 'bold',
              marginTop: 4,
            }}
          >
            Back
          </Typography>
        </div>
      </div>
      <div className={classes.box}>
        <Typography variant="h2" style={{ fontSize: 42, color: '#4A4A4A' }}>
          Widget Generator
        </Typography>
        <TextField
          label="Enter utm (Required)"
          onChange={(e) => setUtm(e.target.value)}
          fullWidth
          style={{ marginTop: 16 }}
        />
        <DiseasesTextField disease={disease} setDisease={setDisease} />
        <Button
          disabled={!utm}
          onClick={createLink}
          className={classes.generateButton}
          fullWidth
          style={{ marginTop: 16 }}
        >
          Create Link
        </Button>
        <Button
          disabled={!utm}
          onClick={createWidget}
          className={classes.generateButton}
          fullWidth
        >
          Create Widget
        </Button>
        {code && (
          <div className={classes.codeBox}>
            {code ? code : 'Widget code here...'}
          </div>
        )}
        {code && (
          <Button onClick={copyCode} className={classes.copyButton}>
            Copy Code
          </Button>
        )}
      </div>
    </div>
  );
}

export default Page404;
