import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import DatePickerMobile from './DatePicker';

const { RangePicker } = DatePicker;

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles({
  list: {
    width: mobile ? '100vw' : 272,
    position: 'relative',
    paddingBottom: 140,
  },
  fullList: {
    width: 'auto',
  },
  heading: {
    letterSpacing: '0.3em',
    color: '#4fb4e8',
    fontSize: 12,
    fontWeight: 'bold',
  },
  title: {
    marginTop: 68,
    marginLeft: 12,
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold',
  },
  collapsIcon: {
    color: '#4fb4e8',
  },
  formControl: {
    fontFamily: "'Poppins', sans-serif",
  },
  selectedDate: {
    marginTop: 2,
    display: 'inline-block',
    verticleAlign: 'middle',
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    float: 'left',
    opacity: 0.4,
    width: '50%',
    fontSize: 14,
  },
  selectedDateIcon: {
    float: 'right',
    textAlign: 'right',
    display: 'inline-block',
    verticleAlign: 'middle',
    width: '50%',
  },
  checkboxTitle: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
  },
  selectedCheckboxTitle: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    opacity: 0.4,
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    lifetime: false,
    lastMonth: false,
    diagnosed: false,
    notDiagnosed: false,
    applied: false,
    scheduled: false,
    completed: false,
    failed: false,
  });

  const [datesRange, setDatesRange] = React.useState(null);

  const [centers, setCenters] = React.useState({});

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    let studyCenters = {};
    for (let index = 0; index < props.data.length; index++) {
      const item = props.data[index];
      studyCenters[item.studyCenter] = false;
    }
    setCenters(studyCenters);
  }, [props.data]);

  useEffect(() => {
    let filteredData = [];
    let {
      lifetime,
      lastMonth,
      diagnosed,
      notDiagnosed,
      applied,
      scheduled,
      completed,
      failed,
    } = state;
    for (let index = 0; index < props.data.length; index++) {
      const item = props.data[index];
      if (lifetime || lastMonth || datesRange || (fromDate && toDate)) {
        if (!lifetime) {
          if (lastMonth) {
            if (
              !(
                moment(item.appliedAt.format) >=
                moment().subtract(1, 'month').startOf('month') &&
                moment(item.appliedAt.format) <=
                moment().add(1, 'month').endOf('month')
              )
            ) {
              continue;
            }
          }
          if (datesRange) {
            if (
              !(
                moment(item.appliedAt.format) >= datesRange[0] &&
                moment(item.appliedAt.format) <= datesRange[1]
              )
            ) {
              continue;
            }
          }
          if (fromDate && toDate) {
            if (
              !(
                moment(item.appliedAt.format) >= moment(fromDate) &&
                moment(item.appliedAt.format) <= moment(toDate)
              )
            ) {
              continue;
            }
          }
        }
      }
      if (diagnosed || notDiagnosed) {
        if (!diagnosed) {
          if (item.diagnosed.diagnosed) continue;
        }
        if (!diagnosed) {
          if (item.diagnosed.diagnosed) continue;
        }
      }
      if (applied || failed || completed || scheduled) {
        if (!applied) {
          if (item.status === 'Applied') continue;
        }
        if (!failed) {
          if (item.status === 'Not Eligible') continue;
        }
        if (!completed) {
          if (item.status === 'Completed') continue;
        }
        if (!scheduled) {
          if (
            item.status !== 'Applied' &&
            item.status !== 'Completed' &&
            item.status !== 'Not Eligible'
          )
            continue;
        }
      }
      // check if one of the centers is selected
      let centerSelected = false;
      Object.keys(centers).forEach((key) => {
        if (centers[key]) {
          centerSelected = true;
        }
      });
      if (centerSelected) {
        if (!centers[item.studyCenter]) continue;
      }
      filteredData.push(item);
    }
    props.setFilteredData(filteredData);
  }, [state, datesRange, centers, toDate]);

  useEffect(() => {
    if (datesRange) {
      setState({ ...state, lifetime: false, lastMonth: false });
    } else {
      setState({ ...state, lifetime: false, lastMonth: false });
    }
  }, [datesRange]);

  useEffect(() => {
    if (state.lifetime || state.lastMonth) {
      setDatesRange(null);
    }
  }, [state]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    props.setOpenFilter(open);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      Filters
    </div>
  );

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeStudyCenter = (event) => {
    setCenters({ ...centers, [event.target.name]: event.target.checked });
  };

  const { gilad, jason, antoine } = state;

  return (
    <div>
      <React.Fragment key={'right'}>
        <Drawer
          anchor={'right'}
          open={props.openFilter}
          open={props.openFilter}
          onClose={() => toggleDrawer(false)}
          style={{ height: '100vh', minHeight: '100vh' }}
        >
          <div className={classes.list}>
            <div style={{ width: '100%' }}>
              <IconButton
                onClick={toggleDrawer(false)}
                style={{ position: 'absolute', top: 16, right: 12 }}
              >
                <img
                  alt=""
                  src={require('../../../Assets/Images/xFilterIcon.png')}
                  style={{ width: 14, height: 14 }}
                />
              </IconButton>
            </div>
            <Typography variant="h2" className={classes.title}>
              Filters
            </Typography>
            <Accordion
              defaultExpanded={true}
              style={{ marginTop: 12 }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.collapsIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h2" className={classes.heading}>
                  DURATION
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    width: '100%',
                    borderTop: '1px solid rgba(0,0,0,0.2)',
                    paddingTop: 20,
                    marginTop: -20,
                    cursor: 'pointer',
                  }}
                >
                  {mobile ? (
                    <div>
                      <div>
                        <DatePickerMobile
                          date={fromDate}
                          setDate={setFromDate}
                          label={'From'}
                        />
                      </div>
                      <div style={{ marginTop: 12, marginBottom: 12 }}>
                        <DatePickerMobile
                          date={toDate}
                          setDate={setToDate}
                          label={'To'}
                        />
                      </div>
                    </div>
                  ) : (
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        value={datesRange}
                        format={'MM-DD-YYYY'}
                        onChange={(dates, datesString) => setDatesRange(dates)}
                      />
                    </Space>
                  )}

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.lifetime}
                          onChange={handleChange}
                          name="lifetime"
                        />
                      }
                      label={
                        <div
                          className={
                            !state.lifetime
                              ? classes.selectedCheckboxTitle
                              : classes.checkboxTitle
                          }
                        >
                          Lifetime
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.lastMonth}
                          onChange={handleChange}
                          name="lastMonth"
                        />
                      }
                      label={
                        <div
                          className={
                            !state.lastMonth
                              ? classes.selectedCheckboxTitle
                              : classes.checkboxTitle
                          }
                        >
                          Last Month
                        </div>
                      }
                    />
                  </FormGroup>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true} elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.collapsIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="h2" className={classes.heading}>
                  DIAGNOSED
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    width: '100%',
                    borderTop: '1px solid rgba(0,0,0,0.2)',
                    paddingTop: 20,
                    marginTop: -20,
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.diagnosed}
                              onChange={handleChange}
                              name="diagnosed"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.diagnosed
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              Yes
                            </div>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.notDiagnosed}
                              onChange={handleChange}
                              name="notDiagnosed"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.notDiagnosed
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              No
                            </div>
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true} elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.collapsIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="h2" className={classes.heading}>
                  STATUS
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    width: '100%',
                    borderTop: '1px solid rgba(0,0,0,0.2)',
                    paddingTop: 20,
                    marginTop: -20,
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.applied}
                              onChange={handleChange}
                              name="applied"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.applied
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              Applied
                            </div>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.scheduled}
                              onChange={handleChange}
                              name="scheduled"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.scheduled
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              Scheduled
                            </div>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.completed}
                              onChange={handleChange}
                              name="completed"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.completed
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              Completed
                            </div>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.failed}
                              onChange={handleChange}
                              name="failed"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.failed
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              Failed
                            </div>
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* {props.includeStudyCenter && (
                    <Accordion defaultExpanded={true} elevation={0}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.collapsIcon} />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        >
                        <Typography variant="h2" className={classes.heading}>STUDY CENTER</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{width: '100%', borderTop: '1px solid rgba(0,0,0,0.2)', paddingTop: 20, marginTop: -20}}>
                                <div style={{width: '100%'}}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                        {Object.keys(centers).map((item, index) => (
                                            <FormControlLabel key={index}
                                                control={<Checkbox checked={centers[item]} onChange={handleChangeStudyCenter} name={item} />}
                                                label={<div className={!centers[item] ? classes.selectedCheckboxTitle : classes.checkboxTitle}>{item}</div>}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )} */}
            {/* <div style={{position: 'fixed', bottom: 0, height: 60, width: '100%'}}>
                    <Button style={{height: 44, width: 'calc(100% - 24px)', width: 248, left: 12, textTransform: 'none', fontWeight: 'bold', fontSize: 16, marginTop: 4, color: 'white', backgroundColor: '#4A4A4A', borderRadius: 10}}>
                        Apply
                    </Button>
                </div> */}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
