import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import { useNavigate } from 'react-router-dom';
// import {DialogContext} from '../../../contexts/Dialog';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    textAlign: 'left',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 17,
    marginTop: 10,
    cursor: 'pointer',
    marginBottom: 10,
  },
  profileButton: {
    marginLeft: 35,
    marginTop: 18,
    width: 50,
    height: 50,
  },
  avatar: {
    width: mobile ? 36 : 40,
    height: mobile ? 36 : 40,
    borderRadius: mobile ? 18 : 20,
    backgroundColor: '#4fb4e830',
    color: '#4fb4e8',
    marginTop: mobile ? 16 : 12,
    fontSize: 16,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    marginLeft: 0,
  },
}));

export default function SettingMenu(props) {
  const navigate = useNavigate();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const {openSettingsDialog, openApplicationDialog} = React.useContext(DialogContext)

  const handleOpenPopover = (e) => {
    if (mobile) {
      // navigate('/home/settings')
      setAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClosePopover = (e) => {
    setAnchorEl(null);
  };

  const clickOnMyProfile = () => {
    setAnchorEl(null);
    // openSettingsDialog()
  };

  const clickOnMyApplication = () => {
    setAnchorEl(null);
    // openApplicationDialog()
  };

  const openPrivacy = () => {
    setAnchorEl(null);
    var win = window.open('https://knowrare.com/privacy', '_blank');
    win.focus();
  };

  const openTerms = () => {
    setAnchorEl(null);
    var win = window.open('https://knowrare.com/terms', '_blank');
    win.focus();
  };

  return (
    <div>
      {/* <IconButton aria-describedby={'popover'} onClick={handleOpenPopover} edge="end" className={classes.profileButton} color="inherit" aria-label="menu"> */}
      {/* <img alt='' src={require('../../../Assets/Images/settingsIcon.png')} style={{height: 24, marginTop: 0, width: 24}}/> */}
      <div className={classes.avatar} onClick={handleOpenPopover}>
        {props.userData.firstName[0] + props.userData.lastName[0]}
      </div>
      {/* </IconButton> */}
      <Popover
        id={'popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div
          style={{ padding: 12, paddingTop: 0, paddingBottom: 0, width: 208 }}
        >
          {/* <div className={classes.menuButton} onClick={()=> clickOnMyProfile()}>Edit Profile</div>
                        <Divider />
                        <div className={classes.menuButton} onClick={()=> clickOnMyApplication()}>Edit Application</div>
                        <Divider /> */}
          {/* <div className={classes.menuButton} onClick={()=> {setAnchorEl(null); navigate('/home/applications') } }>My Study Applications</div>
                        <Divider /> */}
          {/* <div className={classes.menuButton} onClick={()=> {setAnchorEl(null); navigate('/home/archive') } }>Previous Studies</div>
                        <Divider />  
                        <div className={classes.menuButton} onClick={()=> openTerms()}>Terms & Conditions</div>
                        <Divider />
                        <div className={classes.menuButton} onClick={()=> openPrivacy()}>Privacy Policy</div>
                        <Divider /> */}
          <div
            className={classes.menuButton}
            onClick={() => props.logout()}
            style={{ color: '#4fb4e8' }}
          >
            Log Out
          </div>
          <div className={classes.menuButton}>v1.2.2</div>
        </div>
      </Popover>
    </div>
  );
}
