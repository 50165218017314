import React, { useState, useContext, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import StudyService from '../../../../Services/StudyService/StudyService';
import { AuthContext } from '../../../../contexts/Auth';
import { SnackbarContext } from '../../../../contexts/Snackbar';
import { StudiesContext } from '../../../../contexts/Studies';
import MedicationsAutocomplete from './MedicationsAutoComplete';
import MedicationsForClient from './MedicationsForClient';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  saveButton: {
    width: '100%',
    borderRadius: 10,
    background: '#4fb4e8',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
    height: 44,
  },
});

const useStyles = makeStyles((theme) => ({
  saveButton: {
    'width': '100%',
    'borderRadius': 10,
    'background': '#4fb4e8',
    'color': 'white',
    'fontWeight': 'bold',
    'textTransform': 'none',
    'height': 44,
    '&:hover': {
      color: 'white',
      background: '#4fb4e8',
    },
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="h2"
        style={{ fontSize: 26, marginTop: 32, marginBottom: 16 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  open,
  setOpen,
  application,
  study,
}) {
  const classes = useStyles();

  const { studies, getStudies, setStudies } = useContext(StudiesContext);
  const { show } = useContext(SnackbarContext);
  const [medications, setMedications] = useState([]);

  useEffect(() => {
    if (application) {
      setMedications(application.medications);
    }
  }, [application]);

  const handleClose = () => {
    setOpen(false);
  };

  const saveNew = () => {
    let currentApplicationId = application.id;
    let applicationWithNewData = application;
    applicationWithNewData.medications = medications;
    // NEW
    let updatedStudiesArray = studies;
    for (let index = 0; index < updatedStudiesArray.length; index++) {
      let currentStudy = updatedStudiesArray[index];
      if (currentStudy.document.id === study.document.id) {
        for (
          let applicationIndex = 0;
          applicationIndex < currentStudy.applications.length;
          applicationIndex++
        ) {
          let app = currentStudy.applications[applicationIndex];
          if (app.id === currentApplicationId) {
            app.medications = medications;
            currentStudy.applications[applicationIndex] = app;
            updatedStudiesArray[index] = currentStudy;
            setStudies([...updatedStudiesArray]);
            show('success', 'Saved');
          }
        }
      }
    }
    StudyService.updateApplicationByDoc(applicationWithNewData.doc.ref, {
      medications: medications,
    });
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth={'sm'} onClose={handleClose} open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Medications
        </DialogTitle>
        <DialogContent dividers>
          {study &&
            study.studyData.inclusionMedications &&
            study.studyData.inclusionMedications.length > 0 ? (
            <MedicationsForClient
              setMedications={setMedications}
              medications={medications}
              options={[...study.studyData.inclusionMedications]}
            />
          ) : (
            <MedicationsAutocomplete
              setMedications={setMedications}
              medications={medications}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => saveNew()}
            color="primary"
            fullWidth
            className={classes.saveButton}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
