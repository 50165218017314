import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Chart from '../Chart';
import AgeChart from '../AgeChart';
import { PieChart } from 'react-minimal-pie-chart';
import StudyOverviewSkeleton from './StudyOverviewSkeleton';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiStyleFunction from './StudyOverview.style';
import moment from 'moment';
import Lottie from 'lottie-react';
import Loader from '../../../../Assets/Lotties/general-loader.json';
import { StudyContext } from '../../../../contexts/StudyContext';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles(MuiStyleFunction);

const StudyOverview = ({ study }) => {
  const classes = useStyles();

  const [top5, setTop5] = useState([]);

  const { leads, deals } = useContext(StudyContext);

  const [topStudyCenterType, setTopStudyCenterType] = useState('Applied');
  const [whatLeadsToShowInChart, setWhatLeadsToShowInChart] = useState('Leads');
  const [whatAgesToShowInChart, setWhatAgesToShowInChart] = useState('Applied');

  const getAgeFromDate = (date) => {
    let splitedDate = date.split('-');
    let formattedDate =
      splitedDate[0] + '-' + splitedDate[2] + '-' + splitedDate[1];
    return moment().diff(formattedDate, 'years', false);
  };

  const get10Minus = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (
        whatAgesToShowInChart === 'Completed' &&
        application.status[application.status.length - 1].status !== 'Completed'
      ) {
        continue;
      }
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age10minus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge11to18 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (
        whatAgesToShowInChart === 'Completed' &&
        application.status[application.status.length - 1].status !== 'Completed'
      ) {
        continue;
      }
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age11to18 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge19to29 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (
        whatAgesToShowInChart === 'Completed' &&
        application.status[application.status.length - 1].status !== 'Completed'
      ) {
        continue;
      }
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age19to29 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge30to55 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (
        whatAgesToShowInChart === 'Completed' &&
        application.status[application.status.length - 1].status !== 'Completed'
      ) {
        continue;
      }
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age30to55 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge56to74 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (
        whatAgesToShowInChart === 'Completed' &&
        application.status[application.status.length - 1].status !== 'Completed'
      ) {
        continue;
      }
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age56to74 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge75plus = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (
        whatAgesToShowInChart === 'Completed' &&
        application.status[application.status.length - 1].status !== 'Completed'
      ) {
        continue;
      }
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age75plus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getFemale = () => {
    let applications = study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (female / (male + female)) * 100;
  };

  const getMale = () => {
    let applications = study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (male / (male + female)) * 100;
  };

  const getTopMedictions = () => {
    let applications = study.applications;
    let medications = {};
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      application.medications.forEach((medication) => {
        if (medications.hasOwnProperty(medication)) {
          medications[medication] = medications[medication] + 1;
        } else {
          medications[medication] = 1;
        }
      });
    }

    let top5Medications = [
      { value: -1 },
      { value: -1 },
      { value: -1 },
      { value: -1 },
      { value: -1 },
      { value: -1 },
    ];

    for (let index = 0; index < Object.keys(medications).length; index++) {
      const centerObject = medications[Object.keys(medications)[index]];
      if (
        centerObject >= top5Medications[0].value &&
        centerObject >= top5Medications[1].value &&
        centerObject >= top5Medications[2].value &&
        centerObject >= top5Medications[3].value &&
        centerObject >= top5Medications[4].value
      ) {
        top5Medications = [
          { medication: Object.keys(medications)[index], value: centerObject },
          top5Medications[0],
          top5Medications[1],
          top5Medications[2],
          top5Medications[3],
        ];
      } else if (
        centerObject >= top5Medications[1].value &&
        centerObject >= top5Medications[2].value &&
        centerObject >= top5Medications[3].value &&
        centerObject >= top5Medications[4].value
      ) {
        top5Medications = [
          top5Medications[0],
          { medication: Object.keys(medications)[index], value: centerObject },
          top5Medications[1],
          top5Medications[2],
          top5Medications[3],
        ];
      } else if (
        centerObject >= top5Medications[2].value &&
        centerObject >= top5Medications[3].value &&
        centerObject >= top5Medications[4].value
      ) {
        top5Medications = [
          top5Medications[0],
          top5Medications[1],
          { medication: Object.keys(medications)[index], value: centerObject },
          top5Medications[2],
          top5Medications[3],
        ];
      } else if (
        centerObject >= top5Medications[3].value &&
        centerObject >= top5Medications[4].value
      ) {
        top5Medications = [
          top5Medications[0],
          top5Medications[1],
          top5Medications[2],
          { medication: Object.keys(medications)[index], value: centerObject },
          top5Medications[3],
        ];
      } else if (centerObject >= top5Medications[4].value) {
        top5Medications = [
          top5Medications[0],
          top5Medications[1],
          top5Medications[2],
          top5Medications[3],
          { medication: Object.keys(medications)[index], value: centerObject },
        ];
      }
    }

    return top5Medications;
  };

  const getTopSymptoms = () => {
    let applications = study.applications;
    let medications = {};
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      application.symptoms.forEach((medication) => {
        if (medications.hasOwnProperty(medication)) {
          medications[medication] = medications[medication] + 1;
        } else {
          medications[medication] = 1;
        }
      });
    }

    let top5Medications = [
      { value: -1 },
      { value: -1 },
      { value: -1 },
      { value: -1 },
      { value: -1 },
      { value: -1 },
    ];

    for (let index = 0; index < Object.keys(medications).length; index++) {
      const centerObject = medications[Object.keys(medications)[index]];
      if (
        centerObject >= top5Medications[0].value &&
        centerObject >= top5Medications[1].value &&
        centerObject >= top5Medications[2].value &&
        centerObject >= top5Medications[3].value &&
        centerObject >= top5Medications[4].value
      ) {
        top5Medications = [
          { medication: Object.keys(medications)[index], value: centerObject },
          top5Medications[0],
          top5Medications[1],
          top5Medications[2],
          top5Medications[3],
        ];
      } else if (
        centerObject >= top5Medications[1].value &&
        centerObject >= top5Medications[2].value &&
        centerObject >= top5Medications[3].value &&
        centerObject >= top5Medications[4].value
      ) {
        top5Medications = [
          top5Medications[0],
          { medication: Object.keys(medications)[index], value: centerObject },
          top5Medications[1],
          top5Medications[2],
          top5Medications[3],
        ];
      } else if (
        centerObject >= top5Medications[2].value &&
        centerObject >= top5Medications[3].value &&
        centerObject >= top5Medications[4].value
      ) {
        top5Medications = [
          top5Medications[0],
          top5Medications[1],
          { medication: Object.keys(medications)[index], value: centerObject },
          top5Medications[2],
          top5Medications[3],
        ];
      } else if (
        centerObject >= top5Medications[3].value &&
        centerObject >= top5Medications[4].value
      ) {
        top5Medications = [
          top5Medications[0],
          top5Medications[1],
          top5Medications[2],
          { medication: Object.keys(medications)[index], value: centerObject },
          top5Medications[3],
        ];
      } else if (centerObject >= top5Medications[4].value) {
        top5Medications = [
          top5Medications[0],
          top5Medications[1],
          top5Medications[2],
          top5Medications[3],
          { medication: Object.keys(medications)[index], value: centerObject },
        ];
      }
    }

    return top5Medications;
  };

  const getStudyLocations = () => {
    let studyLocations = {};
    for (let index = 0; index < study.allStudyCenters.length; index++) {
      const center = study.allStudyCenters[index];
      const centerData = center.data();
      studyLocations[centerData.LocationFacility] = centerData.LocationAddress;
    }
    return studyLocations;
  };

  const getTopLocations = () => {
    let studyLocations = getStudyLocations();
    let applications = study.applications;
    let data = [];
    let centers = {};
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (topStudyCenterType === 'Completed') {
        let status = application.status[application.status.length - 1].status;
        if (status !== 'Completed') {
          continue;
        }
      }
      let stateShortName = studyLocations[application.studyLocation];
      let stateLongName = studyLocations[application.studyLocation];
      if (
        centers.hasOwnProperty(application.studyLocation.replace(/-/g, ' '))
      ) {
        centers[application.studyLocation.replace(/-/g, ' ')].applications =
          centers[application.studyLocation.replace(/-/g, ' ')].applications +
          1;
      } else {
        centers[application.studyLocation.replace(/-/g, ' ')] = {
          applications: 1,
          stateShortName,
          stateLongName,
          studyCenter: application.studyLocation.replace(/-/g, ' '),
          fill: '#4fb4e8',
        };
      }
    }

    let top5 = [
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
    ];

    for (let index = 0; index < Object.keys(centers).length; index++) {
      const centerObject = centers[Object.keys(centers)[index]];
      if (
        centerObject.applications >= top5[0].applications &&
        centerObject.applications >= top5[1].applications &&
        centerObject.applications >= top5[2].applications &&
        centerObject.applications >= top5[3].applications &&
        centerObject.applications >= top5[4].applications
      ) {
        top5 = [centerObject, top5[0], top5[1], top5[2], top5[3]];
      } else if (
        centerObject.applications >= top5[1].applications &&
        centerObject.applications >= top5[2].applications &&
        centerObject.applications >= top5[3].applications &&
        centerObject.applications >= top5[4].applications
      ) {
        top5 = [top5[0], centerObject, top5[1], top5[2], top5[3]];
      } else if (
        centerObject.applications >= top5[2].applications &&
        centerObject.applications >= top5[3].applications &&
        centerObject.applications >= top5[4].applications
      ) {
        top5 = [top5[0], top5[1], centerObject, top5[2], top5[3]];
      } else if (
        centerObject.applications >= top5[3].applications &&
        centerObject.applications >= top5[4].applications
      ) {
        top5 = [top5[0], top5[1], top5[2], centerObject, top5[3]];
      } else if (centerObject.applications >= top5[4].applications) {
        top5 = [top5[0], top5[1], top5[2], top5[3], centerObject];
      }
    }
    setTop5(top5);
  };

  const getTopProcedures = () => {
    let applications = study.applications;
    let procedures = {};

    var arrayConstructor = [].constructor;
    var objectConstructor = {}.constructor;

    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.procedures.constructor === objectConstructor) {
        // JSON
        let keys = Object.keys(application.procedures);
        keys.forEach((procedure) => {
          if (procedures.hasOwnProperty(procedure)) {
            if (application.procedures[procedure] === true) {
              procedures[procedure] = procedures[procedure] + 1;
            }
          } else {
            if (application.procedures[procedure] === true) {
              procedures[procedure] = 1;
            }
          }
        });
      } else {
        // ARRAY
        application.procedures.forEach((procedure) => {
          if (procedures.hasOwnProperty(procedure)) {
            procedures[procedure] = procedures[procedure] + 1;
          } else {
            procedures[procedure] = 1;
          }
        });
      }
    }

    let top5Procedures = [
      { value: -1 },
      { value: -1 },
      { value: -1 },
      { value: -1 },
      { value: -1 },
      { value: -1 },
    ];

    for (let index = 0; index < Object.keys(procedures).length; index++) {
      const centerObject = procedures[Object.keys(procedures)[index]];
      if (
        centerObject >= top5Procedures[0].value &&
        centerObject >= top5Procedures[1].value &&
        centerObject >= top5Procedures[2].value &&
        centerObject >= top5Procedures[3].value &&
        centerObject >= top5Procedures[4].value
      ) {
        top5Procedures = [
          { procedures: Object.keys(procedures)[index], value: centerObject },
          top5Procedures[0],
          top5Procedures[1],
          top5Procedures[2],
          top5Procedures[3],
        ];
      } else if (
        centerObject >= top5Procedures[1].value &&
        centerObject >= top5Procedures[2].value &&
        centerObject >= top5Procedures[3].value &&
        centerObject >= top5Procedures[4].value
      ) {
        top5Procedures = [
          top5Procedures[0],
          { procedures: Object.keys(procedures)[index], value: centerObject },
          top5Procedures[1],
          top5Procedures[2],
          top5Procedures[3],
        ];
      } else if (
        centerObject >= top5Procedures[2].value &&
        centerObject >= top5Procedures[3].value &&
        centerObject >= top5Procedures[4].value
      ) {
        top5Procedures = [
          top5Procedures[0],
          top5Procedures[1],
          { procedures: Object.keys(procedures)[index], value: centerObject },
          top5Procedures[2],
          top5Procedures[3],
        ];
      } else if (
        centerObject >= top5Procedures[3].value &&
        centerObject >= top5Procedures[4].value
      ) {
        top5Procedures = [
          top5Procedures[0],
          top5Procedures[1],
          top5Procedures[2],
          { procedures: Object.keys(procedures)[index], value: centerObject },
          top5Procedures[3],
        ];
      } else if (centerObject >= top5Procedures[4].value) {
        top5Procedures = [
          top5Procedures[0],
          top5Procedures[1],
          top5Procedures[2],
          top5Procedures[3],
          { procedures: Object.keys(procedures)[index], value: centerObject },
        ];
      }
    }
    return top5Procedures;
  };

  const getTotalNumberOfApplicationsInTheFirst3 = () => {
    if (top5[2].applications !== -1) {
      return top5[2].applications + top5[1].applications + top5[0].applications;
    } else if (top5[1].applications !== -1) {
      return top5[0].applications + top5[1].applications;
    } else if (top5[0].applications !== -1) {
      return top5[0].applications;
    }
    return 0;
  };

  useEffect(() => {
    getTopLocations();
  }, [topStudyCenterType]);

  return (
    <div>
      {/* <ApplicationDialog main={this} /> */}
      {study ? (
        <div>
          <div
            style={{
              paddingTop: 0,
              paddingBottom: mobile ? 80 : 40,
              width: '100%',
              marginTop: mobile ? 0 : 24,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                xl={3}
                style={{ alignItems: 'center' }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        flexFlow: 'column',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 16,
                        paddingTop: 12,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                      }}
                    >
                      <Typography variant="h4" className={classes.colorNumber}>
                        {leads.length}
                      </Typography>
                      <Typography
                        variant="h4"
                        className={classes.numberDescription}
                      >
                        Leads
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        flexFlow: 'column',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 16,
                        paddingTop: 12,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                      }}
                    >
                      <Typography variant="h4" className={classes.colorNumber}>
                        {study.applied}
                      </Typography>
                      <Typography
                        variant="h4"
                        className={classes.numberDescription}
                      >
                        Applied
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        flexFlow: 'column',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 16,
                        paddingTop: 12,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: '#4fb4e8',
                        borderRadius: 8,
                      }}
                    >
                      <Typography variant="h4" className={classes.whiteNumber}>
                        {study.completed}
                      </Typography>
                      <Typography
                        variant="h4"
                        className={classes.whiteNumberDescription}
                      >
                        Completed
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                {/* {study.withUsersData && ( */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  style={{ alignItems: 'center', marginTop: 24 }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 283,
                      boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                      backgroundColor: 'white',
                      borderRadius: 8,
                      padding: 24,
                    }}
                  >
                    <Typography className={classes.boxTitle} variant="h2">
                      Gender
                    </Typography>
                    {study.withUsersData ? (
                      <div
                        style={{
                          display: 'inline-block',
                          width: '60%',
                          verticalAlign: 'top',
                          marginTop: 12,
                        }}
                      >
                        {study.applicants === 0 ? (
                          <div
                            style={{
                              marginTop: 80,
                              textAlign: 'center',
                              fontSize: 22,
                            }}
                          >
                            No Data Yet
                          </div>
                        ) : (
                          <PieChart
                            animate={false}
                            label={({ dataEntry }) => dataEntry.value + '%'}
                            labelStyle={{
                              fontSize: 11,
                              fontFamily: "'Poppins', sans-serif",
                              fontWeight: 600,
                              color: '#FFFFFF',
                            }}
                            style={{
                              color: 'white',
                              color: '#FFFFFF',
                              height: 178,
                              marginTop: 10,
                            }}
                            data={[
                              {
                                title: 'Male',
                                value: parseInt(getMale()),
                                color: '#4fb4e8',
                              },
                              {
                                title: 'Female',
                                value: parseInt(getFemale()),
                                color: '#4fb4e830',
                              },
                            ]}
                          />
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: 178,
                          marginTop: 10,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Lottie
                          animationData={Loader}
                          loop={true}
                          style={{ height: 160, width: 160 }}
                        />
                      </div>
                    )}
                    {study.withUsersData && (
                      <div
                        style={{
                          width: '40%',
                          marginRight: 0,
                          display: 'inline-block',
                          verticalAlign: 'top',
                          marginTop: 24,
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e8',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Male
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e830',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Female
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
                {/* )} */}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                xl={4}
                style={{ alignItems: 'center' }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: mobile ? 'auto' : 344,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{ flexGrow: 1 }}
                        >
                          Monthly Totals
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{
                            marginRight: 12,
                            cursor: 'pointer',
                            color:
                              whatLeadsToShowInChart === 'Leads'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() => setWhatLeadsToShowInChart('Leads')}
                        >
                          Leads
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{
                            cursor: 'pointer',
                            color:
                              whatLeadsToShowInChart === 'Applications'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() =>
                            setWhatLeadsToShowInChart('Applications')
                          }
                        >
                          Applications
                        </Typography>
                      </div>
                      <Chart
                        study={study}
                        whatLeadsToShowInChart={whatLeadsToShowInChart}
                        leads={leads}
                        deals={deals}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        minHeight: mobile ? 'auto' : 344,
                        height: 'auto',
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{ flexGrow: 1 }}
                        >
                          Top 5
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        <Grid
                          container
                          pirection="row"
                          justifyContent="space-around"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Grid item xs={12} sm={12} md={4} xl={4}>
                            <div className={classes.top5Title}>SYMPTOMS</div>
                            <Divider />
                            {getTopSymptoms().map((item, index) => (
                              <div key={index}>
                                {item.value !== -1 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: 12,
                                    }}
                                    key={index}
                                  >
                                    <div className={classes.top5Label}>
                                      {item.medication}
                                    </div>
                                    <div className={classes.top5Value}>
                                      {item.value}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} xl={4}>
                            <div className={classes.top5Title}>MEDICATIONS</div>
                            <Divider />
                            {getTopMedictions().map((item, index) => (
                              <div key={index}>
                                {item.value !== -1 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: 12,
                                    }}
                                    key={index}
                                  >
                                    <div className={classes.top5Label}>
                                      {item.medication}
                                    </div>
                                    <div className={classes.top5Value}>
                                      {item.value}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} xl={4}>
                            <div className={classes.top5Title}>PROCEDURES</div>
                            <Divider />
                            {getTopProcedures().map((item, index) => (
                              <div key={index}>
                                {item.value !== -1 && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: 12,
                                    }}
                                    key={index}
                                  >
                                    <div className={classes.top5Label}>
                                      {item.procedures}
                                    </div>
                                    <div className={classes.top5Value}>
                                      {item.value}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                xl={5}
                style={{ alignItems: 'center' }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {/* {study.withUsersData && ( */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: mobile ? 'auto' : 344,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{ flexGrow: 1 }}
                        >
                          Age
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{
                            marginRight: 12,
                            cursor: 'pointer',
                            color:
                              whatAgesToShowInChart === 'Applied'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() => setWhatAgesToShowInChart('Applied')}
                        >
                          Applied
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{
                            cursor: 'pointer',
                            color:
                              whatAgesToShowInChart === 'Completed'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() => setWhatAgesToShowInChart('Completed')}
                        >
                          Completed
                        </Typography>
                      </div>
                      {study.withUsersData ? (
                        <AgeChart
                          get10Minus={get10Minus}
                          getAge11to18={getAge11to18}
                          getAge19to29={getAge19to29}
                          getAge30to55={getAge30to55}
                          getAge56to74={getAge56to74}
                          getAge75plus={getAge75plus}
                          study={study}
                          whatAgesToShowInChart={whatAgesToShowInChart}
                        />
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: mobile ? 'auto' : 280,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Lottie
                            animationData={Loader}
                            loop={true}
                            style={{ height: 160, width: 160 }}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                  {/* )} */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: mobile ? 'auto' : 344,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{ flexGrow: 1 }}
                        >
                          Top Study Centers
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{
                            marginRight: 12,
                            cursor: 'pointer',
                            color:
                              topStudyCenterType === 'Applied'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() => setTopStudyCenterType('Applied')}
                        >
                          Applied
                        </Typography>
                        <Typography
                          className={classes.boxTitle}
                          variant="h2"
                          style={{
                            cursor: 'pointer',
                            color:
                              topStudyCenterType === 'Completed'
                                ? '#4fb4e8'
                                : 'rgba(74,74,74,0.6)',
                          }}
                          onClick={() => setTopStudyCenterType('Completed')}
                        >
                          Completed
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {top5.length > 0 && top5[0].applications === -1 && (
                          <div
                            style={{
                              marginTop: 90,
                              textAlign: 'center',
                              fontSize: 22,
                            }}
                          >
                            No Data Yet
                          </div>
                        )}
                        {top5.length > 0 && top5[0].applications != -1 && (
                          <div
                            className={classes.locationProgressLine}
                            style={{
                              marginTop: 16,
                              width: `${(top5[0].applications /
                                  getTotalNumberOfApplicationsInTheFirst3()) *
                                100
                                }%`,
                              background: '#4fb4e8',
                              transition: 'width 1s',
                            }}
                          >
                            <div style={{ flexGrow: 1 }}></div>
                            <div>{top5[0].applications}</div>
                          </div>
                        )}
                        {top5.length > 1 && top5[1].applications != -1 && (
                          <div
                            className={classes.locationProgressLine}
                            style={{
                              marginTop: 6,
                              width: `${(top5[1].applications /
                                  getTotalNumberOfApplicationsInTheFirst3()) *
                                100
                                }%`,
                              background: '#4fb4e850',
                              transition: 'width 1s',
                            }}
                          >
                            <div style={{ flexGrow: 1 }}></div>
                            <div>{top5[1].applications}</div>
                          </div>
                        )}
                        {top5.length > 2 && top5[2].applications != -1 && (
                          <div
                            className={classes.locationProgressLine}
                            style={{
                              marginTop: 6,
                              width: `${(top5[2].applications /
                                  getTotalNumberOfApplicationsInTheFirst3()) *
                                100
                                }%`,
                              background: '#4fb4e830',
                              transition: 'width 1s',
                            }}
                          >
                            <div style={{ flexGrow: 1 }}></div>
                            <div>{top5[2].applications}</div>
                          </div>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginTop: 24,
                          }}
                        >
                          {top5.length > 0 && top5[0].applications != -1 && (
                            <div style={{ maxWidth: '33%' }}>
                              <div
                                style={{
                                  width: 14,
                                  height: 14,
                                  borderRadius: '50%',
                                  padding: 10,
                                  background: '#4fb4e8',
                                }}
                              ></div>
                              <div className={classes.locationName}>
                                {top5[0].studyCenter}
                              </div>
                              <div className={classes.locationState}>
                                {top5[0].stateLongName}
                              </div>
                            </div>
                          )}
                          {top5.length > 1 && top5[1].applications != -1 && (
                            <div
                              style={{
                                marginLeft: 4,
                                marginRight: 4,
                                maxWidth: '33%',
                              }}
                            >
                              <div
                                style={{
                                  width: 14,
                                  height: 14,
                                  borderRadius: '50%',
                                  padding: 10,
                                  background: '#4fb4e850',
                                }}
                              ></div>
                              <div className={classes.locationName}>
                                {top5[1].studyCenter}
                              </div>
                              <div className={classes.locationState}>
                                {top5[1].stateLongName}
                              </div>
                            </div>
                          )}
                          {top5.length > 2 && top5[2].applications != -1 && (
                            <div style={{ maxWidth: '33%' }}>
                              <div
                                style={{
                                  width: 14,
                                  height: 14,
                                  borderRadius: '50%',
                                  padding: 10,
                                  background: '#4fb4e830',
                                }}
                              ></div>
                              <div className={classes.locationName}>
                                {top5[2].studyCenter}
                              </div>
                              <div className={classes.locationState}>
                                {top5[2].stateLongName}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <StudyOverviewSkeleton />
      )}
    </div>
  );
};

export default StudyOverview;
