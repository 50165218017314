const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

export default (theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(2),
      '&:hover': {
        backgroundColor: '#ed3636',
      },
    },
    '& 	.MuiOutlinedInput-root': {
      textAlign: 'center',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 30,
    },
  },
  backdrop: {
    zIndex: 9999999999,
  },
  backdropCircular: {
    zIndex: 999999999999,
    color: '#4fb4e8',
  },
  almostThereLabel: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 42,
    fontWeight: 700,
    marginTop: 24,
  },
  almostThereText: {
    color: '#313131',
    fontSize: 17,
    fontWeight: 400,
    opacity: 0.8,
    marginTop: 24,
    width: mobile ? '100%' : 520,
    textAlign: 'center',
  },
  title: {
    color: '#4fb4e8',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 15,
    fontWeight: 900,
    marginBottom: 12,
  },
  studiesTitle: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 12,
    fontWeight: 900,
    marginTop: 12,
    opacity: 0.4,
  },
  sponsoredLabel: {
    color: '#4fb4e8',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 12,
    fontWeight: 700,
  },
  studyNamePage: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontSize: mobile ? 18 : 26,
    fontWeight: 700,
  },
  sectionTitlePage: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontSize: mobile ? 18 : 24,
    fontWeight: 700,
  },
  sectionTextPage: {
    color: '#4A4A4A',
    fontSize: mobile ? 16 : 18,
    fontWeight: 400,
    marginTop: 12,
  },
  locationTitlePage: {
    color: '#4A4A4A',
    fontSize: 15,
    fontWeight: 400,
  },
  locationSubTitlePage: {
    color: '#4A4A4A',
    fontSize: 15,
    fontWeight: 400,
    opacity: 0.4,
  },
  sectionSubTitlePage: {
    color: '#4fb4e8',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 12,
    fontWeight: 700,
    marginTop: mobile ? 12 : 24,
  },
  companyNamePage: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontSize: mobile ? 12 : 16,
    fontWeight: 700,
    opacity: 0.4,
  },
  phaseBox: {
    backgroundColor: 'rgba(0,0,0,0.08)',
    borderRadius: 4,
    display: 'flex',
    padding: 4,
    alignItems: 'center',
    height: 22,
    fontFamily: "'Poppins', sans-serif",
    fontSize: 12,
    fontWeight: 'bold',
    alignContent: 'center',
    justifyContent: 'center',
  },
  phaseStatus: {
    color: '#4A4A4A',
    opacity: 0.6,
    marginLeft: 4,
  },
  boxes: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: 12,
  },
  virtualTag: {
    marginTop: -1,
    marginLeft: 8,
  },
  dividerPage: {
    backgroundColor: 'rgba(0,0,0,0.14)',
    width: '100%',
    height: 2,
    marginTop: 24,
    marginBottom: 24,
  },
  applyButton: {
    backgroundColor: '#4fb4e8',
    height: 42,
    borderRadius: 10,
    fontSize: 15,
    width: '100%',
    fontWeight: 700,
    textTransform: 'none',
    color: 'white',
  },
  question: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 22,
    fontWeight: 900,
    marginBottom: 12,
    marginTop: 24,
  },
  helper: {
    color: '#4A4A4A',
    opacity: 0.4,
    fontFamily: "'Poppins', sans-serif",
    fontSize: 17,
    fontWeight: 600,
    marginBottom: 12,
    marginTop: 12,
  },
  yesno: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4fb4e8',
    height: 50,
    width: mobile ? '100%' : 400,
    color: '#A84545',
    fontSize: 17,
    textAlign: 'left',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ed3636',
    },
  },
  question_red: {
    color: '#4fb4e8',
  },
  selectedBox: {
    width: mobile ? '100%' : 400,
    height: 46,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    backgroundColor: '#FDEFF0',
    color: '#A84545',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: 17,
    marginTop: 12,
  },
  boxTitle: {
    marginTop: 20,
    fontSize: 22,
    color: 'rgba(49, 49, 49, 0.8)',
  },
  scheduleTitle: {
    marginTop: 16,
    fontSize: 12,
    // lineHeight: 20,
    fontWeight: 'bold',
    letterSpacing: '2.92px',
    color: '#4fb4e8',
  },
  boxSubTitle: {
    marginTop: 15,
    marginBottom: 20,
    fontSize: 17,
    color: 'rgba(49, 49, 49, 0.8)',
  },
  selectBox: {
    width: mobile ? '100%' : 400,
    height: 46,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    backgroundColor: '#FEF6F5',
    color: '#CB8F8F',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    fontSize: 17,
    marginTop: 12,
  },
  nextButton: {
    textTransform: 'none',
    width: 132,
    height: 52,
    borderRadius: 26,
    color: '#4fb4e8',
    border: '2px solid black',
    borderColor: '#4fb4e8',
    backgroundColor: 'white',
    background: 'white',
    '&:hover': {
      color: 'white',
    },
    '&:disabled': {
      color: 'rgba(0,0,0,0.3)',
      borderColor: 'rgba(0,0,0,0.3)',
    },
    fontSize: 17,
  },
  // grayNextButton: {

  // },
  previousButton: {
    textTransform: 'none',
    width: 132,
    height: 52,
    borderRadius: 26,
    color: 'rgba(0,0,0,0.3)',
    fontSize: 17,
    backgroundColor: 'transparent',
    fontFamily: "'Poppins', sans-serif",
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
    },
  },
  alreadyHaveAccount: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    marginTop: theme.spacing(2),
    fontSize: 18,
  },
  generalError: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: '#4fb4e8',
    fontFamily: "'Poppins', sans-serif",
    textAlign: 'left',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: 18,
  },
  signIn: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: '#4fb4e8',
    fontFamily: "'Poppins', sans-serif",
    textDecoration: 'underline',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 18,
  },
  cancel: {
    color: '#4fb4e8',
    fontFamily: "'Poppins', sans-serif",
    cursor: 'pointer',
    fontSize: 17,
    color: '#4A4A4A',
    opacity: 0.5,
    textTransform: 'none',
  },
  formContainer: {
    position: 'relative',
    // maxWidth: 500,
    marginBottom: mobile ? 30 : 50,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: mobile ? 20 : 20,
    width: '100%',
    boxShadow: mobile ? 'none' : '0px 0px 25px -8px rgba(0, 0, 0, 0.3);',
  },
  submit: {
    backgroundColor: '#4fb4e8',
    color: 'white',
    borderRadius: 16,
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: 18,
    height: 55,
  },
  errorMessage: {
    color: '#4fb4e8',
    marginTop: theme.spacing(1),
    textAlign: 'left',
    fontSize: 16,
    marginLeft: 2,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  memberDetails: {
    color: '#4fb4e8',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
  },
  loading: {
    marginTop: theme.spacing(2.5),
    height: 55,
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    width: '25ch',
  },
  errorUser: {
    width: '100%',
    textAlign: 'left',
    color: '#4fb4e8',
    fontSize: mobile ? 16 : 22,
    marginTop: theme.spacing(2),
    fontFamily: "'Poppins', sans-serif",
  },
  errorSignupButton: {
    color: '#4fb4e8',
    fontFamily: "'Poppins', sans-serif",
    alignSelf: 'baseline',
    textDecoration: 'underline',
    fontSize: mobile ? 16 : 22,
  },
  submit: {
    backgroundColor: '#4fb4e8',
    color: 'white',
    borderRadius: 16,
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: 18,
    height: 55,
  },
  facebookSubmit: {
    backgroundColor: '#1877F2',
    color: 'white',
    borderRadius: 16,
    textTransform: 'none',
    fontSize: mobile ? 18 : 24,
    height: mobile ? 48 : 62,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    marginTop: 200,
    '&:hover': {
      backgroundColor: '#1877F2 !important',
    },
  },
  googleSubmit: {
    backgroundColor: '#FFF',
    color: 'white',
    borderRadius: 16,
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: mobile ? 18 : 24,
    height: mobile ? 48 : 62,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    '&:hover': {
      backgroundColor: '#FFF !important',
    },
  },
  facebookSubmitPage: {
    backgroundColor: '#1877F2',
    color: 'white',
    borderRadius: 16,
    textTransform: 'none',
    fontSize: mobile ? 18 : 24,
    height: mobile ? 48 : 62,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    marginTop: 42,
    width: mobile ? '100%' : 520,
    '&:hover': {
      backgroundColor: '#1877F2 !important',
    },
  },
  googleSubmitPage: {
    backgroundColor: '#FFF',
    color: 'white',
    borderRadius: 16,
    marginTop: theme.spacing(1),
    textTransform: 'none',
    fontSize: mobile ? 18 : 24,
    height: mobile ? 48 : 62,
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    width: mobile ? '100%' : 520,
    '&:hover': {
      backgroundColor: '#FFF !important',
    },
  },
  dontHaveAccount: {
    alignSelf: 'baseline',
    display: 'inline-block',
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    marginTop: theme.spacing(4),
    fontSize: mobile ? 18 : 24,
  },
  signUp: {
    alignSelf: 'baseline',
    display: 'inline-block',
    fontFamily: "'Poppins', sans-serif",
    color: '#4fb4e8',

    textDecoration: 'underline',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    fontSize: mobile ? 18 : 24,
  },
  formContainerStep1: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: mobile ? 400 : 600,
    backgroundColor: mobile ? 'transparent' : 'white',
    borderRadius: 30,
    padding: mobile ? 20 : 40,
    width: '100%',
    // boxShadow: mobile ? 'none' : '0px 0px 25px -8px rgba(0, 0, 0, 0.1);'
  },
  submitButton: {
    height: 48,
    borderRadius: 12,
    backgroundColor: '#4fb4e8',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
    display: 'inline-block',
    width: 'calc(100% - 48px)',
    fontSize: 16,
    left: 24,
    position: 'absolute',
    bottom: 18,
    boxShadow: '0px 2px 12px rgba(51, 51, 51, 0.14)',
  },
  applyingLoader: {
    position: 'absolute',
    bottom: 18,
  },
});
