import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button as AntButton } from 'antd';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { deleteApplication } from '../../Services/ApplicationService/ApplicationService';
import { SnackbarContext } from '../../contexts/Snackbar';
import StudyService, {
  updateApplicationByDoc,
} from '../../Services/StudyService/StudyService';

export default function DeleteApplicationDialog({
  application,
  onApplicationDeleted,
}) {
  const { show } = React.useContext(SnackbarContext);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [confirmationText, setConfirmationText] = React.useState('');
  const [confirmWithReason, setConfirmWithReason] = React.useState(false);
  const [deleteReason, setDeleteReason] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setConfirmationText('');
    setDeleteReason('');
    setOpen(false);
  };

  const confirmationRequired = application?.title || 'DELETE';

  const isDeleteButtonEnabled = () => {
    return (
      confirmationText === confirmationRequired &&
      (!confirmWithReason || deleteReason.trim().length > 0)
    );
  };

  const handleDelete = async () => {
    if (!isDeleteButtonEnabled()) return;

    setLoading(true);
    try {
      await StudyService.updateApplicationByDoc(application.doc.ref, {
        deleted: true,
        reason: confirmWithReason ? deleteReason : null,
      });
      show('success', 'Application deleted successfully');
      if (onApplicationDeleted) {
        onApplicationDeleted(application.id);
      }
      handleClose();
    } catch (error) {
      show('error', error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <AntButton danger type="primary" onClick={handleClickOpen}>
        Delete
      </AntButton>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Delete Application</DialogTitle>
        <DialogContent>
          <DialogContentText color="error">
            Warning: This action cannot be undone. All data associated with this
            application will be permanently removed.
          </DialogContentText>

          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="body2">
              To confirm deletion, please type{' '}
              <strong>{confirmationRequired}</strong> below:
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label={`Type "${confirmationRequired}" to confirm`}
              variant="outlined"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              error={
                confirmationText.length > 0 &&
                confirmationText !== confirmationRequired
              }
              helperText={
                confirmationText.length > 0 &&
                confirmationText !== confirmationRequired
                  ? "Text doesn't match"
                  : ''
              }
            />
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={confirmWithReason}
                onChange={(e) => setConfirmWithReason(e.target.checked)}
                color="primary"
              />
            }
            label="Include reason for deletion"
          />

          {confirmWithReason && (
            <TextField
              fullWidth
              margin="normal"
              label="Reason for deletion"
              variant="outlined"
              multiline
              rows={3}
              value={deleteReason}
              onChange={(e) => setDeleteReason(e.target.value)}
              required
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              variant="contained"
              color="error"
              onClick={handleDelete}
              disabled={!isDeleteButtonEnabled()}
            >
              Delete Application
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
