import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  deleteButton: {
    width: '100%',
    borderRadius: 10,
    background: '#4A4A4A20',
    color: '#4A4A4A',
    fontWeight: 'bold',
    textTransform: 'none',
    height: 44,
    '&:hover': {
      background: '#4A4A4A20',
      color: '#4A4A4A',
    }
  },
  saveButton: {
    width: '100%',
    borderRadius: 10,
    background: '#4fb4e8',
    color: 'white',
    marginTop: 16,
    fontWeight: 'bold',
    textTransform: 'none',
    height: 44,
    '&:hover': {
      background: '#4fb4e8',
      color: 'white',
    }
  }
});

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    width: '100%',
    borderRadius: 10,
    background: '#4A4A4A20',
    color: '#4A4A4A',
    fontWeight: 'bold',
    textTransform: 'none',
    height: 44,
    '&:hover': {
      background: '#4A4A4A20',
      color: '#4A4A4A',
    }
  },
  saveButton: {
    width: '100%',
    borderRadius: 10,
    background: '#4fb4e8',
    color: 'white',
    marginTop: 16,
    fontWeight: 'bold',
    textTransform: 'none',
    height: 44,
    '&:hover': {
      background: '#4fb4e8',
      color: 'white',
    }
  },
  answer: {
    fontFamily: "'Poppins', sans-serif",
    marginTop: 24,
    marginBottom: 24,
    fontSize: 20
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h2" style={{ fontSize: 26, marginTop: 32, marginBottom: 16 }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column'
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({ open, setOpen, additionalCriteria, indexInEdit, deleteAdditionalCriteria, updateAdditionalCriteria }) {

  const classes = useStyles()

  const [question, setQuestion] = React.useState((indexInEdit !== null) ? additionalCriteria[indexInEdit].question : '')
  const [answer, setAnswer] = React.useState((indexInEdit !== null) ? additionalCriteria[indexInEdit].answer : '')

  const [studyType, setStudyType] = React.useState((indexInEdit !== null && additionalCriteria[indexInEdit].question === 'What type of study is this?') ? additionalCriteria[indexInEdit].answer.split('This study is a ')[1].split(' study')[0] : '')

  const handleChange = (event) => {
    setStudyType(event.target.value);
  };

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {/* {console.log('indexInEdit', indexInEdit)} */}
      {/* {console.log('additionalCriteria', additionalCriteria)} */}
      <Dialog fullWidth maxWidth={'sm'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Additional Criteria
        </DialogTitle>
        <DialogContent dividers>
          <TextField fullWidth variant="outlined" value={question} onChange={(e) => setQuestion(e.target.value)} disabled={
            question === 'What phase is the study currently in?' ||
            question === 'What type of study is this?' ||
            question === 'How do I know if I am eligible for this study?'
          } />
          {question === 'What type of study is this?' ? (
            <>
              <div className={classes.answer}>{`This study is a ${studyType} study`}</div>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Study Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studyType}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value={'Injection'}>Injection</MenuItem>
                  <MenuItem value={'Oral'}>Oral</MenuItem>
                  <MenuItem value={'Infusion'}>Infusion</MenuItem>
                  <MenuItem value={'Oral G-Tube'}>Oral G-Tube</MenuItem>
                </Select>
              </FormControl>
            </>
          ) : (
            <TextField multiline fullWidth variant="outlined" rows={24} style={{ marginTop: 24 }} value={answer} onChange={(e) => setAnswer(e.target.value)} />
          )}
        </DialogContent>
        <DialogActions>
          {(
            question !== 'What phase is the study currently in?' &&
            question !== 'What type of study is this?' &&
            question !== 'How do I know if I am eligible for this study?'
          ) && (
              <Button onClick={() => deleteAdditionalCriteria()} color="primary" fullWidth className={classes.deleteButton}>
                Delete
              </Button>
            )}
          <Button
            onClick={() => question === 'What type of study is this?' ? updateAdditionalCriteria(question, `This study is a ${studyType} study`) : updateAdditionalCriteria(question, answer)} color="primary" fullWidth className={classes.saveButton}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
