import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  list: {
    width: 272,
    position: 'relative',
    paddingBottom: 140,
  },
  fullList: {
    width: 'auto',
  },
  heading: {
    letterSpacing: '0.3em',
    color: '#4fb4e8',
    fontSize: 12,
    fontWeight: 'bold',
  },
  title: {
    marginTop: 68,
    marginLeft: 12,
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold',
  },
  collapsIcon: {
    color: '#4fb4e8',
  },
  formControl: {
    fontFamily: "'Poppins', sans-serif",
  },
  selectedDate: {
    marginTop: 2,
    display: 'inline-block',
    verticleAlign: 'middle',
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    float: 'left',
    opacity: 0.4,
    width: '50%',
    fontSize: 14,
  },
  selectedDateIcon: {
    float: 'right',
    textAlign: 'right',
    display: 'inline-block',
    verticleAlign: 'middle',
    width: '50%',
  },
  checkboxTitle: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
  },
  selectedCheckboxTitle: {
    color: '#4A4A4A',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    opacity: 0.4,
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    lifetime: true,
    lastMonth: true,
    diagnosed: true,
    notDiagnosed: true,
    live: true,
    pending: true,
    paused: true,
    optedOut: true,
  });

  const [datesRange, setDatesRange] = React.useState(null);

  const [centers, setCenters] = React.useState({});
  const [locations, setLocations] = useState({});

  useEffect(() => {
    let studyCenters = {};
    let locations = {};
    for (let index = 0; index < props.data.length; index++) {
      const item = props.data[index];
      studyCenters[item.studyCenter] = true;
      locations[item.location] = true;
    }
    setCenters(studyCenters);
    setLocations(locations);
  }, [props.data]);

  useEffect(() => {
    let filteredData = [];
    let { lifetime, lastMonth, live, pending, paused, optedOut } = state;
    for (let index = 0; index < props.data.length; index++) {
      const item = props.data[index];
      if (lifetime) {
        if (!centers[item.studyCenter]) continue;
        if (!locations[item.location]) continue;
        if (live === false) {
          if (item.status.studyCenterData?.status === 'Live') {
            continue;
          }
        }
        if (pending === false) {
          if (item.status.studyCenterData?.status === 'Pending') {
            continue;
          }
        }
        if (paused === false) {
          if (
            item.status.studyCenterData?.status === 'Paused' ||
            item.status.studyCenterData?.status === 'paused'
          ) {
            continue;
          }
        }
        if (optedOut === false) {
          if (item.status.studyCenterData?.status === 'Opted Out') {
            continue;
          }
        }
        filteredData.push(item);
      } else if (lastMonth) {
        let dateIsOk = true;
        item.applications.forEach((application) => {
          if (
            !(
              moment(application.appliedAt._seconds) >=
              moment().subtract(1, 'month').startOf('month') &&
              moment(application.appliedAt._seconds) <=
              moment().subtract(1, 'month').endOf('month')
            )
          ) {
            dateIsOk = false;
          }
        });
        if (!dateIsOk) continue;
        if (!centers[item.studyCenter]) continue;
        if (!locations[item.location]) continue;
        if (live === false) {
          if (item.status.studyCenterData?.status === 'Live') {
            continue;
          }
        }
        if (pending === false) {
          if (item.status.studyCenterData?.status === 'Pending') {
            continue;
          }
        }
        if (paused === false) {
          if (
            item.status.studyCenterData?.status === 'Paused' ||
            item.status.studyCenterData?.status === 'paused'
          ) {
            continue;
          }
        }
        if (optedOut === false) {
          if (item.status.studyCenterData?.status === 'Opted Out') {
            continue;
          }
        }
        filteredData.push(item);
      } else if (datesRange) {
        let dateIsOk = true;
        item.applications.forEach((application) => {
          if (
            !(
              moment(application.appliedAt._seconds) >= datesRange[0] &&
              moment(application.appliedAt._seconds) <= datesRange[1]
            )
          ) {
            dateIsOk = false;
          }
        });
        if (!dateIsOk) continue;
        if (!centers[item.studyCenter]) continue;
        if (!locations[item.location]) continue;
        if (live === false) {
          if (item.status.studyCenterData?.status === 'Live') {
            continue;
          }
        }
        if (pending === false) {
          if (item.status.studyCenterData?.status === 'Pending') {
            continue;
          }
        }
        if (paused === false) {
          if (
            item.status.studyCenterData?.status === 'Paused' ||
            item.status.studyCenterData?.status === 'paused'
          ) {
            continue;
          }
        }
        if (optedOut === false) {
          if (item.status.studyCenterData?.status === 'Opted Out') {
            continue;
          }
        }
        filteredData.push(item);
      }
    }
    props.setFilteredData(filteredData);
  }, [state, datesRange, centers, locations]);

  useEffect(() => {
    if (datesRange) {
      setState({ ...state, lifetime: false, lastMonth: false });
    } else {
      setState({ ...state, lifetime: true, lastMonth: true });
    }
  }, [datesRange]);

  useEffect(() => {
    if (state.lifetime || state.lastMonth) {
      setDatesRange(null);
    }
  }, [state]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    props.setOpenFilter(open);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeStudyCenter = (event) => {
    setCenters({ ...centers, [event.target.name]: event.target.checked });
  };

  const handleChangeLocation = (event) => {
    setLocations({ ...locations, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <React.Fragment key={'right'}>
        <Drawer
          anchor={'right'}
          open={props.openFilter}
          onClose={() => toggleDrawer(false)}
          style={{ height: '100vh', minHeight: '100vh' }}
        >
          <div className={classes.list}>
            <div style={{ width: '100%' }}>
              <IconButton
                onClick={toggleDrawer(false)}
                style={{ position: 'absolute', top: 16, right: 12 }}
              >
                <img
                  alt=""
                  src={require('../../../Assets/Images/xFilterIcon.png')}
                  style={{ width: 14, height: 14 }}
                />
              </IconButton>
            </div>
            <Typography variant="h2" className={classes.title}>
              Filters
            </Typography>
            <Accordion defaultExpanded={true} elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.collapsIcon} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="h2" className={classes.heading}>
                  STATUS
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    width: '100%',
                    borderTop: '1px solid rgba(0,0,0,0.2)',
                    paddingTop: 20,
                    marginTop: -20,
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.live}
                              onChange={handleChange}
                              name="live"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.live
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              Live
                            </div>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.pending}
                              onChange={handleChange}
                              name="pending"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.pending
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              Pending
                            </div>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.paused}
                              onChange={handleChange}
                              name="paused"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.paused
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              Paused
                            </div>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.optedOut}
                              onChange={handleChange}
                              name="optedOut"
                            />
                          }
                          label={
                            <div
                              className={
                                !state.optedOut
                                  ? classes.selectedCheckboxTitle
                                  : classes.checkboxTitle
                              }
                            >
                              Opted Out
                            </div>
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              defaultExpanded={true}
              style={{ marginTop: 12 }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.collapsIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h2" className={classes.heading}>
                  DURATION
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    width: '100%',
                    borderTop: '1px solid rgba(0,0,0,0.2)',
                    paddingTop: 20,
                    marginTop: -20,
                    cursor: 'pointer',
                  }}
                >
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      value={datesRange}
                      format={'MM-DD-YYYY'}
                      onChange={(dates, datesString) => setDatesRange(dates)}
                    />
                  </Space>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.lifetime}
                          onChange={handleChange}
                          name="lifetime"
                        />
                      }
                      label={
                        <div
                          className={
                            !state.lifetime
                              ? classes.selectedCheckboxTitle
                              : classes.checkboxTitle
                          }
                        >
                          Lifetime
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.lastMonth}
                          onChange={handleChange}
                          name="lastMonth"
                        />
                      }
                      label={
                        <div
                          className={
                            !state.lastMonth
                              ? classes.selectedCheckboxTitle
                              : classes.checkboxTitle
                          }
                        >
                          Last Month
                        </div>
                      }
                    />
                  </FormGroup>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true} elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.collapsIcon} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography variant="h2" className={classes.heading}>
                  STUDY CENTER
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    width: '100%',
                    borderTop: '1px solid rgba(0,0,0,0.2)',
                    paddingTop: 20,
                    marginTop: -20,
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                      <FormGroup>
                        {Object.keys(centers).map((item, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={centers[item]}
                                onChange={handleChangeStudyCenter}
                                name={item}
                              />
                            }
                            label={
                              <div
                                className={
                                  !centers[item]
                                    ? classes.selectedCheckboxTitle
                                    : classes.checkboxTitle
                                }
                              >
                                {item}
                              </div>
                            }
                          />
                        ))}
                      </FormGroup>
                      {/* <FormHelperText>Be careful</FormHelperText> */}
                    </FormControl>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true} elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.collapsIcon} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography variant="h2" className={classes.heading}>
                  LOCATION
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    width: '100%',
                    borderTop: '1px solid rgba(0,0,0,0.2)',
                    paddingTop: 20,
                    marginTop: -20,
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                      <FormGroup>
                        {Object.keys(locations).map((item, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={locations[item]}
                                onChange={handleChangeLocation}
                                name={item}
                              />
                            }
                            label={
                              <div
                                className={
                                  !locations[item]
                                    ? classes.selectedCheckboxTitle
                                    : classes.checkboxTitle
                                }
                              >
                                {item}
                              </div>
                            }
                          />
                        ))}
                      </FormGroup>
                      {/* <FormHelperText>Be careful</FormHelperText> */}
                    </FormControl>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            {/* <div style={{position: 'fixed', bottom: 0, height: 60, width: '100%'}}>
                    <Button style={{height: 44, width: 'calc(100% - 24px)', width: 248, left: 12, textTransform: 'none', fontWeight: 'bold', fontSize: 16, marginTop: 4, color: 'white', backgroundColor: '#4A4A4A', borderRadius: 10}}>
                        Apply
                    </Button>
                </div> */}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
