import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import UpcomingVisitDialog from '../Study/StudyOverview/UpcomingVisitDialog';
import { useNavigate } from 'react-router-dom';
var momentTZ = require('moment-timezone');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    marginTop: 12,
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#4fb4e8',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  appointmentsBox: {
    width: '100%',
    height: 64,
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    borderRadius: 10,
    padding: 8,
    marginTop: 12,
    cursor: 'pointer',
  },
  timeBox: {
    backgroundColor: '#4fb4e8',
    borderRadius: 8,
    textAlign: 'center',
    color: '#FFFFFF',
    // width: 86,
    paddingLeft: 8,
    paddingRight: 8,
    marginTop: 9,
    float: 'right',
    padding: 8,
    fontSize: 14,
  },
  appointmentTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 4,
  },
  appointmentSubTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    opacity: 0.5,
    marginTop: 4,
  },
  summaryRoot: {
    borderBottom: '1px solid rgb(200,200,200)',
    padding: 0,
    background: 'transparent',
  },
  accordionRoot: {
    padding: 0,
    background: 'transparent',
  },
  detailsRoot: {
    padding: 0,
    paddingTop: 12,
    background: 'transparent',
  },
}));

export default function SimpleAccordion({ study }) {
  const navigate = useNavigate();
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [openUpcomingVisitPopup, setOpenUpcomingVisitPopup] = useState(false);
  const [patientName, setPatientName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (study && study.applications) {
      let applications = study.applications;
      let itemsData = [];
      for (let index = 0; index < applications.length; index++) {
        const application = applications[index];
        const status = application.status[application.status.length - 1].status;
        if (status === 'Confirmed Appointment') {
          itemsData.push(application);
        }
      }
      const sortedItems = itemsData.sort(
        (a, b) => b.selectedDate.seconds - a.selectedDate.seconds,
      );
      setItems(sortedItems);
    }
  }, [study]);

  const openUpcomingVisit = () => {
    setOpenUpcomingVisitPopup(true);
  };

  const openMeeting = (item) => {
    setApplication(item);
    let doctorTimezone = moment.tz.guess();
    // console.log('doctorTimezone', doctorTimezone)
    // doctorTimezone = 'America/Los_Angeles'
    let meetingDateTime = `${moment(item.selectedDate.seconds * 1000).format(
      'YYYY-MM-DD',
    )} ${reformatTime(item.selectedTime)}`;
    var meetingDataAndTimeInUserTimezone = momentTZ.tz(
      meetingDateTime,
      item.availability.tz,
    );
    let doctorDataAndTime = meetingDataAndTimeInUserTimezone
      .clone()
      .tz(doctorTimezone);
    let tempDoctorTime = doctorDataAndTime;
    if (
      moment(new Date()) > tempDoctorTime.subtract(10, 'minutes') &&
      moment(new Date()) < tempDoctorTime.add(1, 'hour')
    ) {
      navigate('/home/meeting/' + item.doc.id);
    } else {
      setPatientName(item.userData.firstName);
      // setTime(momentTZ(meetingDateTime).tz(doctorTimezone).format('h:mm a'))
      setTime(getTimeInLocalTimezone());
      setDate(doctorDataAndTime.format('ll'));
      openUpcomingVisit();
    }
  };

  const reformatTime = (selectedTime) => {
    let type = selectedTime.includes('PM') ? 'pm' : 'am';
    // console.log('type', type)
    if (type === 'pm') {
      let splitedTime = selectedTime.split(' PM')[0];
      let splitedHourMinutes = splitedTime.split(':');
      let hour = splitedHourMinutes[0];
      if (hour === '12') {
        return '12' + ':' + splitedHourMinutes[1];
      } else if (hour === '1' || hour === '01') {
        return '13' + ':' + splitedHourMinutes[1];
      } else if (hour === '2' || hour === '02') {
        return '14' + ':' + splitedHourMinutes[1];
      } else if (hour === '3' || hour === '03') {
        return '15' + ':' + splitedHourMinutes[1];
      } else if (hour === '4' || hour === '04') {
        return '16' + ':' + splitedHourMinutes[1];
      } else if (hour === '5' || hour === '05') {
        return '17' + ':' + splitedHourMinutes[1];
      } else if (hour === '6' || hour === '06') {
        return '18' + ':' + splitedHourMinutes[1];
      } else if (hour === '7' || hour === '07') {
        return '19' + ':' + splitedHourMinutes[1];
      } else if (hour === '8' || hour === '08') {
        return '20' + ':' + splitedHourMinutes[1];
      } else if (hour === '9' || hour === '09') {
        return '21' + ':' + splitedHourMinutes[1];
      } else if (hour === '10') {
        return '22' + ':' + splitedHourMinutes[1];
      } else if (hour === '11') {
        return '23' + ':' + splitedHourMinutes[1];
      }
    } else {
      let splitedTime = selectedTime.split(' AM')[0];
      let splitedHourMinutes = splitedTime.split(':');
      return splitedTime;
    }
  };

  // const getTimeInLocalTimezone = (item) => {
  //   let doctorTimezone = moment.tz.guess()
  //   console.log('doctorTimezone', doctorTimezone)
  //   // doctorTimezone = 'America/Los_Angeles'
  //   let meetingDateTime = `${moment(item.selectedDate.seconds * 1000).format('YYYY-MM-DD')} ${reformatTime(item.selectedTime)}`
  //   console.log('meetingDateTime', meetingDateTime)
  //   console.log('item.availability.tz', item.availability.tz)
  //   var meetingDataAndTimeInUserTimezone = momentTZ.tz(meetingDateTime, item.availability.tz)
  //   console.log('meetingDataAndTimeInUserTimezone', meetingDataAndTimeInUserTimezone)
  //   let doctorDataAndTime = meetingDataAndTimeInUserTimezone.clone().tz(doctorTimezone);
  //   return doctorDataAndTime.format('h:mm a z')
  // }

  const getTimeInLocalTimezone = (item) => {
    let doctorTimezone = moment.tz.guess();
    // console.log('doctorTimezone', doctorTimezone)
    // doctorTimezone = 'America/Los_Angeles'
    let meetingDateTime = `${moment(item.selectedDate.seconds * 1000).format(
      'YYYY-MM-DD',
    )} ${item.selectedTime}`;
    // console.log('meetingDateTime', meetingDateTime)
    // console.log('item.availability.tz', item.availability.tz)
    var meetingDataAndTimeInUserTimezone = momentTZ
      .tz(meetingDateTime, 'YYYY-MM-DD h:mm A', item.availability.tz)
      .format('h:mm a z');
    return meetingDataAndTimeInUserTimezone;
    // // console.log('meetingDataAndTimeInUserTimezone', meetingDataAndTimeInUserTimezone)
    // let doctorDataAndTime = meetingDataAndTimeInUserTimezone.clone().tz(doctorTimezone);
    // // console.log('doctorDataAndTime', doctorDataAndTime)
    // // console.log('h:mm a', doctorDataAndTime.format('h:mm a'))
    // return doctorDataAndTime.format('h:mm a z')
  };

  return (
    <div className={classes.root}>
      <UpcomingVisitDialog
        application={application}
        open={openUpcomingVisitPopup}
        setOpen={setOpenUpcomingVisitPopup}
        patientName={patientName}
        date={date}
        time={time}
      />
      <div
        style={{
          height: (items.length + 1) * (192 / 3),
          width: '100%',
          marginTop: -12,
        }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={classes.appointmentsBox}
            onClick={() => openMeeting(item)}
          >
            <div
              style={{
                width: '50%',
                textAlign: 'left',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            >
              <Typography className={classes.appointmentTitle} variant="h2">
                {item.userData.firstName}
              </Typography>
              <Typography className={classes.appointmentSubTitle} variant="h2">
                {moment(item.selectedDate.seconds * 1000).format('ll')}
              </Typography>
            </div>
            <div
              style={{
                width: '50%',
                textAlign: 'right',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            >
              <Typography className={classes.timeBox} variant="h2">
                {getTimeInLocalTimezone(item)}
              </Typography>
            </div>
          </div>
        ))}
        {items.length === 0 && (
          <div
            style={{
              width: '100%',
              borderRadius: 12,
              marginTop: 0,
              padding: 24,
              backgroundColor: '#F6F6F6',
              color: '#B1B1B1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h2" style={{ color: '#B1B1B1', fontSize: 18 }}>
              No Virtual Visits
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
