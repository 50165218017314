import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  movePatientButton: {
    width: '100%',
    height: 44,
    borderRadius: 10,
    background: '#4fb4e8',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        style={{
          fontFamily: "'Poppins', sans-serif",
          color: '#4A4A4A',
          fontWeight: 'bold',
          fontSize: 24,
        }}
        variant='h6'
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ChangeNotesDialog({ open, setOpen, update }) {
  const [notes, setNotes] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const updateStatus = () => {
    update(notes);
    handleClose();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          Application notes
        </DialogTitle>
        <DialogContent>
          <Typography
            gutterBottom
            style={{
              fontFamily: "'Poppins', sans-serif",
              color: '#4A4A4A70',
            }}
          >
            Please enter any detailed information associated with the patient’s
            visit results.
          </Typography>
          <TextField
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            multiline
            placeholder={'Enter result details here...'}
            style={{ fontFamily: "'Poppins', sans-serif", marginTop: 24 }}
            rows={4}
            variant={'outlined'}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ paddingLeft: 8, paddingRight: 8, width: '100%' }}>
            <Button
              onClick={updateStatus}
              autoFocus
              color='primary'
              style={{
                width: '100%',
                height: 44,
                borderRadius: 10,
                background: '#4fb4e8',
                color: 'white',
                fontWeight: 'bold',
                marginTop: 12,
                marginBottom: 12,
                textTransform: 'none',
              }}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
