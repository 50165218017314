import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../../../node_modules/antd/dist/antd.css';
import { Table } from 'antd';
import Button from '@material-ui/core/Button';
import LocationsFilterDrawer from './LocationsFilterDrawer';
import { FilterContext } from '../../../contexts/Filter';
import StudyCenterStatus from '../Master/StudyCenterStatus';
import { StudyContext } from '../../../contexts/StudyContext';
import ChangeLocationNotesDialog from '../InquiriesTab/ChangeLocationNotesDialog';
import EditCenterNameDialog from '../../../Components/comments/ChangeCenterName';
import StudyCenterComments from '../../../Components/comments/StudyCenterComments';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "'Poppins', sans-serif",
  },
  mobileTitle: {
    fontSize: 22,
    color: '#4A4A4A',
    paddingBottom: 24,
  },
  filterButton: {
    width: 76,
    height: 38,
    borderRadius: 8,
    backgroundColor: '#4fb4e830',
    color: '#4fb4e8',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    marginTop: 16,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  searchInput: {
    backgroundColor: '#F4F4F4',
    width: '100%',
    maxWidth: 350,
    marginBottom: 16,
    height: 38,
    borderRadius: 10,
    color: '#4A4A4A',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    border: 'none',
    marginTop: 16,
    paddingRight: 8,
    paddingLeft: 8,
    // marginTop: 6,
    paddingRight: 32,
  },
}));

const StudyTable = (props) => {
  const classes = useStyle();

  const { study, centers } = useContext(StudyContext);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const { openFilter, setOpenFilter } = useContext(FilterContext);
  const [applicationsToShow, setApplicationsToShow] = useState([]);
  const [search, setSearch] = useState('');
  const [loadingTable, setLoadingTable] = useState(false);
  const [openLocationNodeDialog, setOpenLocationNodeDialog] = useState(false);
  const [currentLocationInEdit, setCurrentLocationInEdit] = useState(null);

  const [columns, setColumns] = useState([
    {
      title: '#',
      dataIndex: 'key',
      width: 45,
    },
    {
      title: 'Study Center',
      dataIndex: 'studyCenter',
      render(studyCenter) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              fontSize: 14,
              fontWeight: 600,
            }}
          >

            <span>{studyCenter.name}</span>
            <EditCenterNameDialog studyCenterData={studyCenter.studyCenterData}
              studyCenterRef={studyCenter.studyCenterRef} />
          </div>
        );
      }
    },
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 200,
      render: (data) => (
        <StudyCenterStatus
          studyCenterData={data.studyCenterData}
          studyCenterRef={data.studyCenterRef}
        />
      ),
    },
    {
      title: 'Applications',
      dataIndex: 'applications',
      sorter: {
        compare: (a, b) => a.applications - b.applications,
      },
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      sorter: {
        compare: (a, b) => a.completed - b.completed,
      },
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      width: 170,
      render: (center) => (
        <StudyCenterComments studyCenter={center.studyCenterData} studyCenterRef={center.studyCenterRef} />
      )
    }
    // {
    //   title: 'Failed',
    //   dataIndex: 'notEligible',
    //   sorter: {
    //     compare: (a, b) => a.notEligible - b.notEligible,
    //   },
    // },
    // {
    //   title: 'Notes',
    //   dataIndex: 'notes',
    //   width: 170,
    //   render: (result) => (
    //     <>
    //       <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
    //         <Popover
    //           placement="top"
    //           style={{ borderRadius: 10 }}
    //           title={() => (
    //             <div
    //               style={{
    //                 color: '#4fb4e8',
    //                 fontSize: 11,
    //                 fontWeight: 900,
    //                 letterSpacing: 1,
    //                 fontFamily: "'Poppins', sans-serif",
    //               }}
    //             >
    //               NOTE
    //             </div>
    //           )}
    //           content={() => (
    //             <div
    //               style={{
    //                 color: '#4A4A4A',
    //                 fontSize: 13,
    //                 fontWeight: 'normal',
    //                 letterSpacing: 0.24,
    //                 fontFamily: "'Poppins', sans-serif",
    //                 maxWidth: 340,
    //                 display: 'flex',
    //                 gap: 16,
    //                 flexDirection: 'column',
    //               }}
    //             >
    //               {result.studyCenterData?.notes || 'N/A'}
    //               <AntButton
    //                 onClick={() => {
    //                   setOpenLocationNodeDialog(true);
    //                   setCurrentLocationInEdit(result);
    //                 }}
    //               >
    //                 Edit
    //               </AntButton>
    //             </div>
    //           )}
    //           trigger="click"
    //         >
    //           <Button
    //             style={{
    //               border: 'none',
    //               padding: 0,
    //               backgroundColor: 'none',
    //               background: 'none',
    //               width: 24,
    //               height: 24,
    //               marginTop: 0,
    //             }}
    //           >
    //             <img
    //               alt=""
    //               src={require('../../../Assets/Images/blackNoteIcon.png')}
    //               width={24}
    //               height={24}
    //             />
    //           </Button>
    //         </Popover>
    //       </div>
    //     </>
    //   ),
    // },
  ]);

  useEffect(() => {
    let applications = study.applications;
    setApplicationsToShow(applications);
    return;
  }, []);

  useEffect(() => {
    let applications = applicationsToShow;
    let studyCenters = centers;
    let data = [];
    let centersData = {};
    for (let index = 0; index < studyCenters.length; index++) {
      const studyCenterObject = studyCenters[index];
      const studyCenterData = studyCenterObject.data();
      centersData[studyCenterData.LocationFacility] = {
        completed: 0,
        applied: 0,
        notEligible: 0,
        scheduled: 0,
        location: studyCenterData.LocationAddress,
        studyCenter: studyCenterData.LocationFacility,
        applications: [],
        studyCenterObject: studyCenterObject,
      };
    }
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const applicationStatus =
        application.status[application.status.length - 1].status;
      if (centersData.hasOwnProperty(application.studyLocation)) {
        if (applicationStatus === 'Completed') {
          centersData[application.studyLocation].completed =
            centersData[application.studyLocation].completed + 1;
          let relevantApplications =
            centersData[application.studyLocation].applications;
          relevantApplications.push(application);
          centersData[application.studyLocation].applications =
            relevantApplications;
        } else if (applicationStatus === 'Applied') {
          centersData[application.studyLocation].applied =
            centersData[application.studyLocation].applied + 1;
          let relevantApplications =
            centersData[application.studyLocation].applications;
          relevantApplications.push(application);
          centersData[application.studyLocation].applications =
            relevantApplications;
        } else if (applicationStatus === 'Confirmed Appointment') {
          centersData[application.studyLocation].scheduled =
            centersData[application.studyLocation].scheduled + 1;
          let relevantApplications =
            centersData[application.studyLocation].applications;
          relevantApplications.push(application);
          centersData[application.studyLocation].applications =
            relevantApplications;
        } else if (applicationStatus === 'Not Eligible') {
          centersData[application.studyLocation].notEligible =
            centersData[application.studyLocation].notEligible + 1;
          let relevantApplications =
            centersData[application.studyLocation].applications;
          relevantApplications.push(application);
          centersData[application.studyLocation].applications =
            relevantApplications;
        } else {
          centersData[application.studyLocation].scheduled =
            centersData[application.studyLocation].scheduled + 1;
          let relevantApplications =
            centersData[application.studyLocation].applications;
          relevantApplications.push(application);
          centersData[application.studyLocation].applications =
            relevantApplications;
        }
      }
    }
    for (let index = 0; index < Object.keys(centersData).length; index++) {
      const centerObject = centersData[Object.keys(centersData)[index]];
      if (
        centerObject.studyCenter
          .replace(/-/g, ' ')
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        centerObject.location.toLowerCase().includes(search.toLowerCase())
      ) {
        data.push({
          key: index + 1,
          studyCenter: {
            studyCenterData: centerObject.studyCenterObject.data(),
            studyCenterRef: centerObject.studyCenterObject.ref,
            name: centerObject.studyCenter.replace(/-/g, ' '),
          },
          status: {
            studyCenterData: centerObject.studyCenterObject.data(),
            studyCenterRef: centerObject.studyCenterObject.ref,
          },
          applied: centerObject.applied,
          scheduled: centerObject.scheduled,
          completed: centerObject.completed,
          notEligible: centerObject.notEligible,
          location: centerObject.location,
          applications: centerObject.applications.length,
          comments: {
            studyCenterData: centerObject.studyCenterObject.data(),
            studyCenterRef: centerObject.studyCenterObject.ref,
          },
          notes: {
            studyCenterData: centerObject.studyCenterObject.data(),
            studyCenterRef: centerObject.studyCenterObject.ref,
          },
        });
      }
    }
    setData(data);
    setFilteredData(data);
    setLoadingTable(false);
  }, [applicationsToShow, centers]);

  const onChange = (pagination, filters, sorter, extra) => { };

  useEffect(() => {
    handleChangeSearch(search);
  }, [search]);

  const timer = React.useRef(null);

  const handleChangeSearchInput = (value) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      // your logic
      setLoadingTable(true);
      setSearch(value);
    }, 500);
  };

  const handleChangeSearch = (value) => {
    let study = props.study;
    if (value === '') {
      setApplicationsToShow(study.applications);
      return;
    }
    let matchedApplications = [];
    for (let index = 0; index < study.applications.length; index++) {
      const application = study.applications[index];
      let studyAddress = application.studyAddress.toLowerCase();
      let studyName = application.studyLocation.toLowerCase();
      if (
        studyAddress.includes(value.toLowerCase()) ||
        studyName.includes(value.toLowerCase())
      ) {
        matchedApplications.push(application);
      }
    }
    setApplicationsToShow(matchedApplications);
  };

  return (
    <div style={{ fontFamily: "'Poppins', sans-serif", color: '#4fb4e8' }}>
      {/* <Typography className={classes.mobileTitle} variant="h2">Locations</Typography> */}
      <ChangeLocationNotesDialog
        open={openLocationNodeDialog}
        setOpen={setOpenLocationNodeDialog}
        currentLocationInEdit={currentLocationInEdit}
      />
      <LocationsFilterDrawer
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        data={data ? data : []}
        setFilteredData={setFilteredData}
      />
      <div style={{ flexGrow: 1, display: 'flex' }}>
        <input
          placeholder="Search"
          className={classes.searchInput}
          onChange={(e) => handleChangeSearchInput(e.target.value)}
        />
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            paddingLeft: 12,
          }}
        >
          <Button
            className={classes.filterButton}
            onClick={() => setOpenFilter(true)}
          >
            Filter
          </Button>
        </div>
      </div>
      <Table
        pagination={false}
        scroll={{ x: mobile ? '100%' : 1545 }}
        columns={columns}
        dataSource={filteredData}
        onChange={onChange}
        style={{ marginTop: mobile ? 0 : 12, color: '#4fb4e8' }}
      />
    </div>
  );
};

export default StudyTable;
