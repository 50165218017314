import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Chart from '../Chart';
import ApplicationDialog from '../ApplicationDialog';
import { PieChart } from 'react-minimal-pie-chart';
import Tabs from '../Tabs.js';
import StudyOverviewSkeleton from './StudyOverviewSkeleton';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiStyleFunction from './StudyOverview.style';
import Inquiries from './Inquiries';
import Appointments from './Appointments';
import Leads from './Leads';
import moment from 'moment';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles(MuiStyleFunction);

const StudyOverview = ({ study }) => {
  const classes = useStyles();

  const getAgeFromDate = (date) => {
    let splitedDate = date.split('-');
    let formattedDate =
      splitedDate[0] + '-' + splitedDate[2] + '-' + splitedDate[1];
    return moment().diff(formattedDate, 'years', false);
  };

  const get10Minus = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age10minus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge11to18 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age11to18 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge19to29 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age19to29 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge30to55 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age30to55 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge56to74 = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age56to74 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge75plus = () => {
    let applications = study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age75plus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getFemale = () => {
    let applications = study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (female / (male + female)) * 100;
  };

  const getMale = () => {
    let applications = study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (male / (male + female)) * 100;
  };

  const getDiagnosed = () => {
    let applications = study.applications;
    let yes = 0;
    let no = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (!application.userData.diagnosedByDoctor) {
        no += 1;
      } else {
        yes += 1;
      }
    }
    return (yes / (yes + no)) * 100;
  };

  const getNotDiagnosed = () => {
    let applications = study.applications;
    let yes = 0;
    let no = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (!application.userData.diagnosedByDoctor) {
        no += 1;
      } else {
        yes += 1;
      }
    }
    return (no / (yes + no)) * 100;
  };

  return (
    <div>
      <ApplicationDialog main={this} />
      {study !== null ? (
        <div>
          {/* {mobile && (
          <Typography className={classes.mobileTitle} variant="h2">Overview</Typography>
        )} */}
          <div
            style={{
              paddingTop: 0,
              paddingBottom: mobile ? 80 : 40,
              width: '100%',
              marginTop: mobile ? 0 : 24,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                xl={2}
                style={{ alignItems: 'center' }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        flexFlow: 'column',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 28,
                        paddingTop: 28,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: '#4fb4e8',
                        borderRadius: 8,
                      }}
                    >
                      <Typography variant="h4" className={classes.colorNumber}>
                        {study.applied}
                      </Typography>
                      <Typography
                        variant="h4"
                        className={classes.numberDescription}
                      >
                        Applied
                      </Typography>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} xl={12} style={{alignItems: 'center'}}>
                        <div style={{width: '100%', height: 195, boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)', backgroundColor: 'white', borderRadius: 8, paddingTop: 28}}>
                          <Typography variant="h4" className={classes.colorNumber}>{study.scheduled}</Typography>
                          <Typography variant="h4" className={classes.numberDescription}>Scheduled</Typography>
                        </div>
                      </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        flexFlow: 'column',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 28,
                        paddingTop: 28,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: '#4fb4e8',
                        borderRadius: 8,
                      }}
                    >
                      <Typography variant="h4" className={classes.whiteNumber}>
                        {study.completed}
                      </Typography>
                      <Typography
                        variant="h4"
                        className={classes.whiteNumberDescription}
                      >
                        Completed
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                xl={7}
                style={{ alignItems: 'center' }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 324,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant="h2">
                        Applications
                      </Typography>
                      <Chart study={study} />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 283,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant="h2">
                        Gender
                      </Typography>
                      <PieChart
                        animate={false}
                        label={({ dataEntry }) => dataEntry.value + '%'}
                        labelStyle={{
                          fontSize: 11,
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: 600,
                          color: '#FFFFFF',
                        }}
                        style={{
                          color: 'white',
                          color: '#FFFFFF',
                          height: 158,
                          marginTop: 10,
                        }}
                        data={[
                          {
                            title: 'Male',
                            value: parseFloat(getMale().toFixed(2)),
                            color: '#4fb4e8',
                          },
                          {
                            title: 'Female',
                            value: parseFloat(getFemale().toFixed(2)),
                            color: '#4fb4e830',
                          },
                        ]}
                      />
                      <div style={{ width: '100%', marginTop: 12 }}>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e8',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Male
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#4fb4e830',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Female
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={4} xl={4} style={{alignItems: 'center'}}>
                        <div style={{width: '100%', height: 283, boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)', backgroundColor: 'white', borderRadius: 8, padding: 24}}>
                          <Typography className={classes.boxTitle} variant="h2">Diagnosed</Typography>
                          <PieChart 
                            // style={{boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)'}}
                            animate={false}
                            labelStyle={{
                              fontSize: 14,
                              // boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.2)',
                              fontFamily: "'Poppins', sans-serif",
                              fontWeight: 600,
                              fontColor: '#FFFFF',
                              color: 'white',
                              background: 'white',
                              backgroundColor: 'white'
                            }}
                            label={({ dataEntry }) => dataEntry.value + '%'}
                            style={{color: 'white', height: 158, marginTop: 10}}
                            data={[
                              { title: 'Yes', value: getDiagnosed(), color: '#4fb4e8' },
                              { title: 'No', value: getNotDiagnosed(), color: '#4fb4e830' }
                            ]}
                          />
                          <div style={{width: '100%', marginTop: 12}}>
                            <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 12}}>
                              <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#4fb4e8'}}></div>
                              <div style={{fontSize: 12, fontWeight: 500, letterSpacing: '0.451506', fontFamily: "'Poppins', sans-serif", marginTop: 8, color: '#263238', opacity: 0.5}}>Yes</div>
                            </div>
                            <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 12}}>
                              <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#4fb4e830'}}></div>
                              <div style={{fontSize: 12, fontWeight: 500, letterSpacing: '0.451506', fontFamily: "'Poppins', sans-serif", marginTop: 8, color: '#263238', opacity: 0.5}}>No</div>
                            </div>
                          </div>
                        </div>
                      </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={6}
                    style={{ alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 283,
                        boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 24,
                      }}
                    >
                      <Typography className={classes.boxTitle} variant="h2">
                        Age
                      </Typography>
                      <PieChart
                        // style={{boxShadow: '0px 10px 34px rgba(0, 0, 0, 0.12)'}}
                        animate={false}
                        style={{
                          color: 'white',
                          color: '#FFFFFF',
                          height: 158,
                          marginTop: 10,
                        }}
                        label={({ dataEntry }) => dataEntry.value + '%'}
                        labelStyle={{
                          fontSize: 11,
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: 600,
                          color: '#FFFFFF',
                        }}
                        data={[
                          {
                            title: '10+',
                            value: parseFloat(get10Minus().toFixed(2)),
                            color: '#FDE6E6',
                          },
                          {
                            title: '11-18',
                            value: parseFloat(getAge11to18().toFixed(2)),
                            color: '#F7B1B1',
                          },
                          {
                            title: '19-29',
                            value: parseFloat(getAge19to29().toFixed(2)),
                            color: '#F38382',
                          },
                          {
                            title: '30-55',
                            value: parseFloat(getAge30to55().toFixed(2)),
                            color: '#D87877',
                          },
                          {
                            title: '56-74',
                            value: parseFloat(getAge56to74().toFixed(2)),
                            color: '#C0504F',
                          },
                          {
                            title: '75+',
                            value: parseFloat(getAge75plus().toFixed(2)),
                            color: '#A66262',
                          },
                        ]}
                      />
                      <div style={{ width: '100%', marginTop: 12 }}>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#FDE6E6',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            Under 10
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#F7B1B1',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            11-18
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#F38382',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            19-29
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#D87877',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            30-55
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#C0504F',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            56-74
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginLeft: 12,
                            marginTop: 0,
                          }}
                        >
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: 7,
                              backgroundColor: '#A66262',
                            }}
                          ></div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              letterSpacing: '0.451506',
                              fontFamily: "'Poppins', sans-serif",
                              marginTop: 8,
                              color: '#263238',
                              opacity: 0.5,
                            }}
                          >
                            75+
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {!mobile && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  xl={3}
                  style={{ alignItems: 'center' }}
                >
                  <Leads study={study} dashboardType={'center'} />
                  {/* <Inquiries study={study} dashboardType={'center'} />
                      <Appointments study={study} /> */}
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      ) : (
        <StudyOverviewSkeleton />
      )}
    </div>
  );
};

export default StudyOverview;
