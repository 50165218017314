import React, { useContext, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/Auth';

const AntTabs = withStyles((theme) => ({
  root: {
    width: 200,
    // backgroundColor: 'gray'
  },
  indicator: {
    backgroundColor: '#4fb4e8',
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    'textTransform': 'none',
    'minWidth': 0,
    'marginRight': theme.spacing(3),
    'color': 'rgba(74, 74, 74, 0.5)',
    'marginTop': 15,
    'fontWeight': 'bold',
    'fontFamily': [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#4A4A4A',
      fontWeight: 'bold',
      opacity: 1,
    },
    '&$selected': {
      color: '#4A4A4A',
      fontWeight: 'bold',
    },
    '&:focus': {
      fontWeight: 'bold',
      color: '#4A4A4A',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#4fb4e830',
    color: '#4fb4e8',
    marginTop: 12,
    fontSize: 16,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    marginLeft: 0,
  },
  menuItemLabel: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    color: '#4A4A4A',
  },
  menuItemRedLabel: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    color: '#4fb4e8',
  },
}));

function Avatar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className={classes.avatar} onClick={handleClick}>
        {props.name}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}><div className={classes.menuItemLabel}>Acount</div></MenuItem> */}
        {/* <MenuItem onClick={handleClose}><div className={classes.menuItemLabel}>Help</div></MenuItem> */}
        <MenuItem onClick={props.logout}>
          <div className={classes.menuItemRedLabel}>Log Out</div>
        </MenuItem>
        <MenuItem>
          <div className={classes.menuItemLabel}>v1.2.2</div>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default function CustomizedTabs(props) {
  const classes = useStyles();
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.type === 'master') {
      if (window.location.pathname === '/home') {
        navigate('/home/sponsored');
      }
    } else {
      if (window.location.pathname === '/home') {
        navigate('/home/sponsored');
      }
    }
  }, []);

  const handleChange = (event, newValue) => {
    props.setCurrentPage(parseInt(newValue));
    if (props.type === 'master') {
      if (parseInt(newValue) === 0) {
        navigate('/home/sponsored');
      } else if (parseInt(newValue) === 1) {
        navigate('/home/basic');
      }
    } else {
      navigate('/home/sponsored');
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        {props.type === 'master' ? (
          <AntTabs
            value={props.currentPage}
            onChange={handleChange}
            aria-label="ant example"
          >
            <AntTab label="Sponsored" />
            <AntTab label="Basic" />
          </AntTabs>
        ) : (
          <div>
            {/* <AntTabs value={props.currentPage} onChange={handleChange} aria-label="ant example">
              <AntTab label="My Studies" />
            </AntTabs> */}
          </div>
        )}
      </div>
      <Avatar
        name={userData?.firstName[0] + userData?.lastName[0]}
        logout={props.logout}
      />
    </div>
  );
}
