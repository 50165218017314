import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MuiStyleFunction from './Login.style';

import firebase from '../../../../Firebase';
// import UserService from '../../Services/UserService/UserService'
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
// import ProgressBar from './ProgressBar';
// import Diseases from './DiseasesTextField';
import Location from './LocationTextField';
// import ReactPhoneInput from 'react-phone-input-material-ui';
import DateFnsUtils from '@date-io/date-fns';
import DatePicker from './DatePicker';
import Procedures from './Procedures';
import moment from 'moment';
import SymptomsAutocomplete from './NewSymptoms';
import MedicationsAutocomplete from './NewMedications';
import MedicationsForClient from './MedicationsForClient';
import SymptomsForClient from './SymptomsForClient';
import MuiPhoneNumber from 'material-ui-phone-number';
import clsx from 'clsx';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Divider } from '@material-ui/core';
// import AboutTheStudyAccordion from './AboutTheStudyAccordion';
import Day from './Day';
import axios from 'axios';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const styles = makeStyles(MuiStyleFunction);

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     fontFamily: "'Poppins', sans-serif"
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
//   movePatientButton: {
//       width: '100%', height: 44, borderRadius: 10, background: '#4fb4e8', color: 'white', fontWeight: 'bold', textTransform: 'none'
//   }
// });

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        style={{
          fontFamily: "'Poppins', sans-serif",
          color: '#4A4A4A',
          fontWeight: 'bold',
          fontSize: 24,
        }}
        variant="h6"
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({
  open,
  setOpen,
  status,
  update,
  noteType,
  edit,
  currentApplication,
}) {
  const classes = styles();
  const [d, setD] = useState(null);
  const [generalError, setGeneralError] = useState('');
  const [errorGender, setErrorGender] = useState('');
  const [errorEthnicity, setErrorEthnicity] = useState('');
  const [errorFirstDiagnosed, setErrorFirstDiagnosed] = useState('');
  const [errorSpecialist, setErrorSpecialist] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [errorLastName, setErrorLastName] = useState('');
  const [errorDate, setErrorDate] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorLocation, setErrorLocation] = useState('');
  const [errorDisease, setErrorDisease] = useState('');
  const [errorCheckbox, setErrorCheckbox] = useState('');

  const [loading, setIsLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [location, setLocation] = useState(null);
  const [specialist, setSpecialist] = useState(null);
  const [date, setDate] = useState(null);
  const [firstDiagnosed, setFirstDiagnosed] = useState('');
  const [disease, setDisease] = useState(null);
  const [termsAndPrivacy, setTermsAndPrivacy] = useState(false);
  const [hippa, setHippa] = useState(false);
  const [diagnosedAge, setDiagnosedAge] = useState(null);
  const [
    startNewMedicationInTheLast6Month,
    setStartNewMedicationInTheLast6Month,
  ] = useState(false);
  const [
    participatedInAnyOtherStudiesInTheLast6Months,
    setParticipatedInAnyOtherStudiesInTheLast6Months,
  ] = useState(false);
  const [willingToTravel, setWillingToTravel] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState({ NCTId: ['sad'] });
  const [diagnosedByDoctor, setDiagnosedByDoctor] = useState(null);
  const [proceduresArray, setProceduresArray] = useState([]);
  const [symptomsData, setSymptomsData] = useState({
    None: false,
    ImpairedSpeech: false,
    DifficultyChewing: false,
    TroubleSwallowing: false,
    ShortnessOfBreath: false,
    WeaknessInArmsMuscles: false,
    HardToRisefromChair: false,
    DoubleVision: false,
    EyelidDroop: false,
  });
  const [symptomsArray, setSymptomsArray] = useState([]);
  const [medicationsArray, setMedicationsArray] = useState([]);
  const [procedures, setProcedures] = useState({
    Surgery: false,
    Biopsy: false,
    IVIg: false,
    Plasmapheresis: false,
    GeneticTesting: false,
    AntibodyTesting: false,
  });
  const [symptoms, setSymptoms] = React.useState([]);
  const [medications, setMedications] = React.useState([]);
  const [conditions, setConditions] = React.useState([]);
  const [from, setFrom] = React.useState('09:00 AM');
  const [to, setTo] = React.useState('06:00 PM');
  const [day1, set1] = React.useState(true);
  const [day2, set2] = React.useState(true);
  const [day3, set3] = React.useState(true);
  const [day4, set4] = React.useState(true);
  const [day5, set5] = React.useState(true);
  const [day6, set6] = React.useState(true);

  const [loadingStudies, setLoadingStudies] = React.useState(true);

  const [userLat, setUserLat] = React.useState(null);
  const [userLon, setUserLon] = React.useState(null);

  const [showHippaDialog, setShowHippaDialog] = React.useState(false);

  const [isWaiting, setIsWaiting] = React.useState(false);

  const [studies, setStudies] = useState([]);
  const [studiesInTheSameCountry, setStudiesInTheSameCountry] = useState([]);

  const [userAddress, setUserAddress] = useState(null);

  const [sponsoredStudies, setSponsoredStudies] = useState([]);

  const [otherSymptoms, setOtherSymptoms] = useState('');
  const [otherMedications, setOtherMedications] = useState('');

  const [openNotEligiblePopup, setOpenNotEligiblePopup] = useState(false);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [availability, setAvailability] = useState(null);

  const [note, setNote] = React.useState('');

  const [loginWithEmailSelected, setLoginWithEmailSelected] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [selectDateTime, setSelectDateTime] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const next = () => { };

  const stringToTrueVaultDate = (str) => {
    let splited = str.split('/');
    return splited[2] + '-' + splited[1] + '-' + splited[0];
  };

  const validateEmailPassword = () => {
    setErrorEmail('');
    setErrorPassword('');
    if (validateEmail(email)) {
      if (password.length >= 6) {
        // applyWithEmailAndPassword(email, password)
        if (!checkIfCanRegister()) return;
        setSelectDateTime(true);
      } else {
        setErrorPassword('The password is to short (minimum 6 characters)');
      }
    } else {
      setErrorEmail('Email is badly formatted');
    }
  };

  useEffect(() => {
    if (location) {
      getUserCoordinates();
    }
  }, [location]);

  const getUserCoordinates = async () => {
    const response = await getLocationData();
    if (response.data.results.length > 0) {
      // console.log(response.data.results[0])
      // console.log(response.data.results[0])
      setUserAddress(response.data.results[0]);
      setUserLat(response.data.results[0].geometry.location.lat);
      setUserLon(response.data.results[0].geometry.location.lng);
    }
  };

  useEffect(() => {
    if (userLat && userLon) {
      getTheNearestLocation();
    }
  }, [userLat, userLon]);

  useEffect(() => {
    if (firstName && firstName !== '') {
      setErrorFirstName('');
    }
  }, [firstName]);

  useEffect(() => {
    if (lastName && lastName !== '') {
      setErrorLastName('');
    }
  }, [lastName]);

  useEffect(() => {
    if (gender && gender !== '') {
      setErrorGender('');
    }
  }, [gender]);

  // useEffect(() => {
  //     console.log('date', date)
  // }, [date])

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const checkIfBirthDateIsValid = () => {
    if (!date) return false;
    let splitedDate = date.split('/');
    let month = splitedDate[0];
    if (!date.includes('_')) {
      if (parseInt(month) > 12) {
        setGeneralError("Month can't be higher than 12");
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (!date) return;
    let splitedDate = date.split('/');
    let month = splitedDate[0];
    let day = splitedDate[0];
    let year = splitedDate[0];
    if (!date.includes('_')) {
      if (parseInt(month) > 12) {
        setGeneralError("Month can't be higher than 12");
        alert("Month can't be higher than 12");
      } else {
        setGeneralError('');
        if (selectedStudy.NCTId[0] === 'NCT04115293') {
          if (getAge(date) > 75) {
            setOpenNotEligiblePopup(true);
          }
        }
      }
    }
  }, [date]);

  useEffect(() => {
    if (location && location !== '') {
      setErrorLocation('');
    }
  }, [location]);

  useEffect(() => {
    if (termsAndPrivacy === true) {
      setErrorCheckbox('');
    }
  }, [termsAndPrivacy]);

  useEffect(() => {
    if (diagnosedByDoctor !== null) {
      setErrorFirstDiagnosed('');
    }
  }, [diagnosedByDoctor]);

  const checkIfDateIsOk = () => {
    if (date !== null) {
      if (!date.includes('_')) {
        if (parseInt(date.split('/')[0]) <= 12) {
          return true;
        }
      }
    }
    return false;
  };

  const checkIfCanRegister = () => {
    setErrorFirstName('');
    setErrorLastName('');
    setErrorGender('');
    setErrorFirstDiagnosed('');
    setErrorDate('');
    setErrorLocation('');
    setErrorCheckbox('');
    setGeneralError('');
    let canRegister = true;
    // if (!checkIfDateIsOk()) {
    //     setGeneralError('Please fill your first name. Make sure the month is first and not bigger then 12.')
    // }
    if (!firstName || firstName === '') {
      canRegister = false;
      setErrorFirstName('Please fill your first name');
    }
    if (!lastName || lastName === '') {
      canRegister = false;
      setErrorLastName('Please fill your last name');
    }
    if (!gender || gender === '') {
      canRegister = false;
      setErrorGender('Please fill your gender');
    }
    if (diagnosedByDoctor === null) {
      canRegister = false;
      setErrorFirstDiagnosed('Please fill your status');
    }
    if (date === null) {
      canRegister = false;
      setErrorDate('Please fill your birth date');
    }
    if (!checkIfBirthDateIsValid()) {
      canRegister = false;
    }
    if (location === null) {
      canRegister = false;
      setErrorLocation('Please fill your location');
    }
    if (termsAndPrivacy !== true) {
      canRegister = false;
      setErrorCheckbox(
        'Please accept our Terms & Conditions, Privacy Policy and the HIPPA aggrement',
      );
    }
    if (phone.length < 5) {
      canRegister = false;
      setGeneralError('Error phone number');
    }
    return canRegister;
  };

  const getSymptoms = () => {
    if (selectedStudy.NCTId[0] === 'NCT04115293') {
      if (symptomsArray.includes('None')) {
        if (otherSymptoms !== '') {
          return [otherSymptoms];
        } else {
          return symptomsArray;
        }
      } else {
        return symptomsArray;
      }
    } else {
      return symptoms;
    }
  };

  const getMedications = () => {
    if (selectedStudy.NCTId[0] === 'NCT04115293') {
      if (medicationsArray.includes('None')) {
        if (otherMedications !== '') {
          return [otherMedications];
        } else {
          return medicationsArray;
        }
      } else {
        return medicationsArray;
      }
    } else {
      return medications;
    }
  };

  const handleChangeText = (name) => (event) => {
    if (name === 'firstName') {
      setFirstName(event.target.value);
    } else if (name === 'lastName') {
      setLastName(event.target.value);
    } else if (name === 'date') {
      setDate(event.target.value);
    } else if (name === 'phone') {
      setPhone(event.target.value);
    } else if (name === 'otherSymptoms') {
      setOtherSymptoms(event.target.value);
    } else if (name === 'otherMedications') {
      setOtherMedications(event.target.value);
    }
  };

  const handleChangeSelect = (name) => (event) => {
    if (name === 'ethnicity') {
      setEthnicity(event.target.value);
    } else if (name === 'gender') {
      setGender(event.target.value);
    } else if (name === 'specialist') {
      setSpecialist(event.target.value);
    } else if (name === 'firstDiagnosed') {
      setFirstDiagnosed(event.target.value);
    } else if (name === 'diagnosed') {
      setDiagnosedByDoctor(event.target.value);
    }
  };

  const validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getLocationData = async (LocationCity, locationZip) => {
    var link =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      encodeURI(location.description) +
      '&key=AIzaSyBU_iDguwAqFTyYxbKRNR87Xn4SuHYGWH4';
    const response = await axios.get(link);
    return response;
  };

  const calcDistance = (lat, lng) => {
    var lat1 = lat;
    var lon1 = lng;
    var lat2 = userLat;
    var lon2 = userLon;

    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    // console.log('distance', (d * 0.621371192).toFixed(1))
    return (d * 0.621371192).toFixed(1);
  };

  const toRad = (Value) => {
    return (Value * Math.PI) / 180;
  };

  const getCoordinates = async (
    LocationFacility,
    LocationCity,
    locationZip,
  ) => {
    const address =
      LocationFacility +
      ', ' +
      LocationCity +
      ', United States, ' +
      locationZip;
    var link =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      address +
      ':US&key=AIzaSyBU_iDguwAqFTyYxbKRNR87Xn4SuHYGWH4';
    const response = await axios.get(link);
    if (response.data.results.length > 0) {
      var coordinates = {
        lat: response.data.results[0].geometry.location.lat,
        lng: response.data.results[0].geometry.location.lng,
        formattedAddress: response.data.results[0].formatted_address,
        locationData: response.data.results[0],
      };
      return coordinates;
    } else {
      return {
        lat: '0',
        lng: '0',
        formattedAddress: 'location not found',
        locationData: 'location not found',
      };
    }
  };

  const getCoordinatesForSponsored = async (LocationAddress) => {
    const address = LocationAddress;
    var link =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      address +
      ':US&key=AIzaSyBU_iDguwAqFTyYxbKRNR87Xn4SuHYGWH4';
    const response = await axios.get(link);
    if (response.data.results.length > 0) {
      var coordinates = {
        lat: response.data.results[0].geometry.location.lat,
        lng: response.data.results[0].geometry.location.lng,
        formattedAddress: response.data.results[0].formatted_address,
        locationData: response.data.results[0],
      };
      return coordinates;
    } else {
      return {
        lat: '0',
        lng: '0',
        formattedAddress: 'location not found',
        locationData: 'location not found',
      };
    }
  };

  const getZip = (locationData) => {
    for (
      let index = 0;
      index < locationData.locationData.address_components.length;
      index++
    ) {
      const addressComponent =
        locationData.locationData.address_components[index];
      if (addressComponent.types.includes('postal_code')) {
        return addressComponent.long_name;
      }
    }
    return '0000000';
  };

  const getCity = (locationData) => {
    for (
      let index = 0;
      index < locationData.locationData.address_components.length;
      index++
    ) {
      const addressComponent =
        locationData.locationData.address_components[index];
      if (addressComponent.types.includes('locality')) {
        return addressComponent.long_name;
      }
    }
    return 'city name';
  };

  const checkIfThereIsCenterInURL = (activeCenters) => {
    let params = new URLSearchParams(window.location.search);
    if (params.get('center') !== null) {
      let center = params.get('center').replace(/\+/g, ' ');
      for (let index = 0; index < activeCenters.length; index++) {
        const activeCenter = activeCenters[index];
        if (activeCenter.LocationFacility === center) {
          return activeCenter;
        }
      }
    }
    return null;
  };

  const getTheNearestLocation = async () => {
    var study = selectedStudy;
    // console.log('getTheNearestLocation', study)
    if (study.hasOwnProperty('sponsoredData')) {
      let activeCenters = [];
      study.sponsoredData.centers.forEach((center) => {
        if (center.data().status === 'Live') {
          activeCenters.push(center.data());
        }
      });
      console.log('activeCenters', activeCenters);
      // Check if there is center in the url
      let center = checkIfThereIsCenterInURL(activeCenters);
      if (center) {
        var nearestLocation = null;
        var nearestLocationZip = null;
        var nearestLocationCoordinates = null;
        var nearestLocationFacility = null;
        var smallestKm = 100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
        let coordinatesCalls = [];
        for (let index = 0; index < [center].length; index++) {
          coordinatesCalls.push(
            getCoordinatesForSponsored([center][index].LocationAddress),
          );
        }
        let coordinatesOfAllLocations = await Promise.all(coordinatesCalls);
        // console.log('coordinatesOfAllLocations', coordinatesOfAllLocations)
        for (let index = 0; index < [center].length; index++) {
          var coordinates = coordinatesOfAllLocations[index];
          var distance = calcDistance(coordinates.lat, coordinates.lng);
          // console.log('distance', distance)
          if (parseFloat(distance) < parseFloat(smallestKm)) {
            smallestKm = distance;
            nearestLocation = getCity(coordinates);
            nearestLocationZip = getZip(coordinates);
            nearestLocationFacility = [center][index].LocationFacility;
            nearestLocationCoordinates = coordinates;
          }
        }
        study['nearestLocationCity'] = nearestLocation;
        study['locationCoordinates'] = nearestLocationCoordinates;
        study['nearestLocationZip'] = nearestLocationZip;
        study['nearestLocationFacility'] = nearestLocationFacility;
        study['distance'] = smallestKm;
        if (center.availability) {
          setAvailability(center.availability);
        }
      } else if (activeCenters.length > 1) {
        var nearestLocation = null;
        var nearestLocationZip = null;
        var nearestLocationCoordinates = null;
        var nearestLocationFacility = null;
        var smallestKm = 100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
        let coordinatesCalls = [];
        for (let index = 0; index < activeCenters.length; index++) {
          coordinatesCalls.push(
            getCoordinatesForSponsored(activeCenters[index].LocationAddress),
          );
        }
        let coordinatesOfAllLocations = await Promise.all(coordinatesCalls);
        // console.log('coordinatesOfAllLocations', coordinatesOfAllLocations)
        for (let index = 0; index < activeCenters.length; index++) {
          var coordinates = coordinatesOfAllLocations[index];
          var distance = calcDistance(coordinates.lat, coordinates.lng);
          // console.log('center', activeCenters[index].LocationFacility)
          // console.log('distance', distance)
          if (parseFloat(distance) < parseFloat(smallestKm)) {
            smallestKm = distance;
            nearestLocation = getCity(coordinates);
            nearestLocationZip = getZip(coordinates);
            nearestLocationFacility = activeCenters[index].LocationFacility;
            nearestLocationCoordinates = coordinates;
            if (activeCenters[index].availability) {
              setAvailability(activeCenters[index].availability);
            }
          }
        }
        study['nearestLocationCity'] = nearestLocation;
        study['locationCoordinates'] = nearestLocationCoordinates;
        study['nearestLocationZip'] = nearestLocationZip;
        study['nearestLocationFacility'] = nearestLocationFacility;
        study['distance'] = smallestKm;
      } else {
        var coordinates = await getCoordinatesForSponsored(
          activeCenters[0].LocationAddress,
        );
        var distance = calcDistance(coordinates.lat, coordinates.lng);
        nearestLocation = getCity(coordinates);
        nearestLocationZip = getZip(coordinates);
        nearestLocationFacility = activeCenters[0].LocationFacility;
        if (activeCenters[0].availability) {
          setAvailability(activeCenters[0].availability);
        }
        study['locationCoordinates'] = distance;
      }
    } else {
      if (study.LocationZip.length === 1) {
        var coordinates = await getCoordinates(
          study.LocationCity[0],
          study.LocationZip[0],
        );
        var distance = calcDistance(coordinates.lat, coordinates.lng);
        study['nearestLocationCity'] = study.LocationCity[0];
        study['nearestLocationZip'] = study.LocationZip[0];
        study['nearestLocationFacility'] = study.LocationFacility[0];
        study['locationCoordinates'] = coordinates;
        study['distance'] = distance;
      } else {
        var nearestLocation = null;
        var nearestLocationZip = null;
        var nearestLocationCoordinates = null;
        var nearestLocationFacility = null;
        var smallestKm = 100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;
        let coordinatesCalls = [];
        for (let index = 0; index < study.LocationZip.length; index++) {
          coordinatesCalls.push(
            getCoordinates(study.LocationCity[index], study.LocationZip[index]),
          );
        }
        let coordinatesOfAllLocations = await Promise.all(coordinatesCalls);
        for (let index = 0; index < study.LocationZip.length; index++) {
          var coordinates = coordinatesOfAllLocations[index];
          var distance = calcDistance(coordinates.lat, coordinates.lng);
          if (parseFloat(distance) < parseFloat(smallestKm)) {
            smallestKm = distance;
            nearestLocation = study.LocationCity[index];
            nearestLocationZip = study.LocationZip[index];
            nearestLocationFacility = study.LocationFacility[index];
            nearestLocationCoordinates = coordinates;
          }
        }
        study['nearestLocationCity'] = nearestLocation;
        study['locationCoordinates'] = nearestLocationCoordinates;
        study['nearestLocationZip'] = nearestLocationZip;
        study['nearestLocationFacility'] = nearestLocationFacility;
        study['distance'] = smallestKm;
      }
    }
    let nearestStudy = study;
    // console.log('studyData', nearestStudy)
    // setLocationData(nearestStudy)
    setSelectedStudy(nearestStudy);
  };

  const handleChangeTextForSignup = (e, type) => {
    if (type === 'email') {
      setEmail(e.target.value);
    } else if (type === 'password') {
      // let p = e.target.value.toString().replace(/[^0-9]/g, '').slice(0,6)
      let p = e.target.value;
      setPassword(p);
    } else if (type === 'secondPassword') {
      setSecondPassword(e.target.value);
    }
  };

  const onChangePhone = (e) => {
    setPhone(
      e.replace(/-/g, '').replace('(', '').replace(')', '').replace(/ /g, ''),
    );
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <MuiDialogTitle
          disableTypography
          className={classes.root}
          style={{ alignItems: 'center' }}
        >
          <Typography
            style={{
              fontFamily: "'Poppins', sans-serif",
              color: '#4A4A4A',
              fontWeight: 'bold',
              fontSize: 24,
            }}
            variant="h6"
          >
            Schedule Offline Visit
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            style={{ marginTop: 6 }}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent>
          <iframe
            src="https://app.knowrare.com/signup?offlineVisit=true"
            width="100%"
            height="820px"
            frameBorder="0"
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
}
