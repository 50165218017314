import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import ReminderDialog from './ReminderDialog';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    marginTop: -12,
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#4fb4e8',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  appointmentsBox: {
    width: '100%',
    // height: 64,
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    borderRadius: 10,
    padding: 8,
    paddingTop: 12,
    paddingBottom: 12,
    marginTop: 12,
    cursor: 'pointer',
  },
  timeBox: {
    backgroundColor: '#4fb4e830',
    borderRadius: 8,
    textAlign: 'center',
    color: '#4fb4e8',
    width: 86,
    marginTop: 9,
    float: 'right',
    padding: 8,
    fontSize: 14,
  },
  timeBoxPending: {
    backgroundColor: 'rgba(60, 60, 60, 0.2)',
    borderRadius: 8,
    textAlign: 'center',
    color: '#3C3C3C',
    width: 86,
    marginTop: 9,
    float: 'right',
    padding: 8,
    fontSize: 14,
  },
  appointmentTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 4,
  },
  appointmentSubTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    opacity: 0.5,
    marginTop: 4,
  },
  summaryRoot: {
    borderBottom: '1px solid rgb(200,200,200)',
    padding: 0,
    background: 'transparent',
  },
  accordionRoot: {
    padding: 0,
    background: 'transparent',
  },
  detailsRoot: {
    padding: 0,
    paddingTop: 12,
    background: 'transparent',
  },
}));

export default function SimpleAccordion({ study, dashboardType }) {
  const navigate = useNavigate();

  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [openReminderDialog, setOpenReminderDialog] = useState(false);
  const [remindApplication, setRemindApplication] = useState(null);

  useEffect(() => {
    if (study) {
      let applications = study.applications;
      let itemsData = [];
      for (let index = 0; index < applications.length; index++) {
        const application = applications[index];
        const status = application.status[application.status.length - 1].status;
        if (
          status === 'Applied' ||
          status === 'Different Date Needed' ||
          status === 'Schedule Appointment'
        ) {
          itemsData.push(application);
          // if (status === 'Schedule Appointment') {
          //   // if (new Date(application.selectedDate.seconds * 1000).valueOf() )
          //   setRemindApplication(application)
          //   setOpenReminderDialog(true)
          // }
        }
      }
      const sortedItems = itemsData.sort(
        (a, b) => b.appliedAt.seconds - a.appliedAt.seconds,
      );
      setItems(sortedItems);
    }
  }, []);

  return (
    <div className={classes.root}>
      {remindApplication && openReminderDialog && (
        <ReminderDialog
          open={openReminderDialog}
          setOpen={setOpenReminderDialog}
          applicationData={remindApplication}
        />
      )}
      <Accordion
        elevation={0}
        defaultExpanded={true}
        classes={{ root: classes.accordionRoot }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: classes.summaryRoot }}
        >
          <Typography variant="h2" className={classes.heading}>
            INQUIRIES
          </Typography>
          {items.length > 0 && (
            <img
              alt=""
              src={require('../../../../Assets/Images/inquiriesIcon.png')}
              style={{
                height: 16,
                width: 16,
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: 8,
                marginTop: 1,
              }}
            />
          )}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detailsRoot }}>
          {/* <div style={{height: dashboardType === 'admin' ? ((items.length + 2) * (192 / 3)) : ((items.length + 2) * (192 / 3)), width: '100%', marginTop: -12}}> */}
          <div style={{ width: '100%', marginTop: -12 }}>
            {items.map((item, index) => (
              <div
                key={index}
                className={classes.appointmentsBox}
                onClick={() => navigate('/home/schedule/' + item.doc.id)}
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: 'left',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}
                  >
                    <Typography
                      className={classes.appointmentTitle}
                      variant="h2"
                    >
                      {item.userData.firstName}
                    </Typography>
                    {/* {console.log(item.appliedAt)}                     */}
                    {/* <Typography className={classes.appointmentSubTitle} variant="h2">Applied {moment(item.appliedAt.seconds * 1000).startOf('day').fromNow()}</Typography> */}
                    <Typography
                      className={classes.appointmentSubTitle}
                      variant="h2"
                    >
                      Applied at{' '}
                      {moment(item.appliedAt.seconds * 1000).format('ll')}
                    </Typography>
                  </div>
                  <div
                    style={{
                      textAlign: 'right',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}
                  >
                    {item.status[item.status.length - 1].status ===
                      'Schedule Appointment' ? (
                      <Typography
                        className={classes.timeBoxPending}
                        variant="h2"
                      >
                        Pending
                      </Typography>
                    ) : (
                      <Typography className={classes.timeBox} variant="h2">
                        Review
                      </Typography>
                    )}
                  </div>
                </div>
                {dashboardType === 'admin' && (
                  <div style={{ width: '100%', marginTop: 8 }}>
                    <Typography
                      className={classes.appointmentSubTitle}
                      variant="h2"
                    >
                      {item.studyLocation}
                    </Typography>
                  </div>
                )}
              </div>
            ))}
            {items.length === 0 && (
              <div
                style={{
                  width: '100%',
                  borderRadius: 12,
                  marginTop: 24,
                  padding: 24,
                  backgroundColor: '#F6F6F6',
                  color: '#B1B1B1',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h2"
                  style={{ color: '#B1B1B1', fontSize: 18 }}
                >
                  No Inquiries
                </Typography>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
