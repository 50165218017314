import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, Divider, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Popover } from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../../contexts/Auth';

const Comments = ({ commentsList, updateComments }) => {
    const { authUser, userData, userType } = useContext(AuthContext);

    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [currentComment, setCurrentComment] = useState(null);
    const [editedCommentText, setEditedCommentText] = useState('');

    useEffect(() => {
        if (commentsList && commentsList.length > 0) {
            const sortedComments = [...commentsList].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            setComments(sortedComments);
        } else {
            setComments([]);
        }
    }, [commentsList])

    const handleAddComment = () => {
        if (newComment.trim()) {
            const newCommentObj = {
                text: newComment,
                createdBy: userData ? userData.firstName : 'Unknown',
                createdByRole: userType ? userType : 'Unknown',
                timestamp: new Date().toISOString(),
                createdById: authUser.uid,
            };

            const updatedComments = [newCommentObj, ...comments];
            setComments(updatedComments);

            updateComments(updatedComments);
            setNewComment('');
        }
    };

    const handleEditClick = (comment) => {
        setCurrentComment(comment);
        setEditedCommentText(comment.text);
        setEditDialogOpen(true);
    };

    const handleDeleteClick = (comment) => {
        setCurrentComment(comment);
        setDeleteDialogOpen(true);
    };

    const handleEditComment = () => {
        if (editedCommentText.trim()) {
            const updatedComments = comments.map(comment => {
                if (comment === currentComment) {
                    return {
                        ...comment,
                        text: editedCommentText,
                        edited: true,
                        editTimestamp: new Date().toISOString()
                    };
                }
                return comment;
            });

            setComments(updatedComments);
            updateComments(updatedComments, 'Comment updated successfully');
            setEditDialogOpen(false);
        }
    };

    const handleDeleteComment = () => {
        const updatedComments = comments.filter(comment => comment !== currentComment);
        setComments(updatedComments);
        updateComments(updatedComments, 'Comment deleted successfully');
        setDeleteDialogOpen(false);
    };

    // Check if the current user is the creator of a comment
    const isCommentCreator = (comment) => {
        return comment.createdById === authUser.uid;
    };

    return (
        <>
            <Popover
                placement="bottomLeft"
                style={{ borderRadius: 10 }}
                title={() => (
                    <div
                        style={{
                            color: '#4fb4e8',
                            fontSize: 11,
                            fontWeight: 900,
                            letterSpacing: 1,
                            fontFamily: "'Poppins', sans-serif",
                        }}
                    >
                        COMMENTS
                    </div>
                )}
                content={() => (
                    <Box sx={{ p: 0, maxWidth: 400 }}>
                        {comments.length === 0 && (
                            <Box sx={{ p: 0 }}>
                                <Typography variant="body2" color="textSecondary">
                                    No comments yet.
                                </Typography>
                            </Box>
                        )}
                        <List>
                            {comments.map((comment, index) => (
                                <React.Fragment key={index}>
                                    <ListItem
                                        sx={{ px: 0 }}
                                        alignItems="flex-start"
                                        secondaryAction={
                                            isCommentCreator(comment) && (
                                                <Box sx={{ mb: 1 }}>
                                                    <IconButton
                                                        edge="end"
                                                        size="small"
                                                        onClick={() => handleEditClick(comment)}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton
                                                        edge="end"
                                                        size="small"
                                                        onClick={() => handleDeleteClick(comment)}
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            )
                                        }
                                    >
                                        <ListItemText
                                            sx={{ paddingInlineEnd: 10 }}
                                            primary={comment.text}
                                            secondary={
                                                <Box sx={{ fontSize: '0.8rem', color: '#4A4A4A' }}>
                                                    By: {comment.createdBy} | Role: {comment?.createdByRole} | At: {new Date(comment.timestamp).toLocaleString()}
                                                </Box>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Add a comment"
                                variant="outlined"
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                            />
                            <Button
                                sx={{ mt: 1 }}
                                variant="contained"
                                color="primary"
                                onClick={handleAddComment}
                            >
                                Add Comment
                            </Button>
                        </Box>
                    </Box>
                )}
                trigger="hover"
            >
                <img
                    alt=""
                    src={require('../../Assets/Images/applicationNoteIcon.png')}
                    style={{
                        cursor: 'pointer',
                        width: 44,
                        height: 44,
                    }}
                />
            </Popover>

            {/* Edit Comment Dialog */}
            <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Edit Comment</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        label="Edit your comment"
                        value={editedCommentText}
                        onChange={(e) => setEditedCommentText(e.target.value)}
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleEditComment} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Comment Dialog */}
            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} maxWidth="xs" fullWidth>
                <DialogTitle>Delete Comment</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this comment?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteComment} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Comments;