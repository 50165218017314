import moment from 'moment';
import firebase, { storage, analytics } from '../../Firebase';
import { post } from '../BaseService';
import axios from 'axios';

export default {
  uploadRecords,
};

const db = firebase.firestore();
let storageRef = firebase.storage().ref();

async function uploadRecords(applicationId, file) {
  var recordRef = storageRef.child(
    `applications/${applicationId}/records/${file.name}`
  );
  let snapshot = await recordRef.put(file);
  return await snapshot.ref.getDownloadURL();
}

const getExpirationDate = (exp) => {
  switch (exp) {
    case '1day':
      return firebase.firestore.Timestamp.fromMillis(
        moment().add(1, 'day').valueOf()
      );
    case '1week':
      return firebase.firestore.Timestamp.fromMillis(
        moment().add(7, 'day').valueOf()
      );
    default:
      return firebase.firestore.Timestamp.now();
  }
};

export async function deleteApplication({ applicationId, reason }) {
  try {
    await db.collection('applications').doc(applicationId).update({
      deleted: true,
      reason,
    });
    return true;
  } catch (error) {
    console.log('error', error);
    return false;
  }
}

export async function shareApplications({
  data,
  email,
  expiration,
  audience = 'all',
}) {
  try {
    const res = await db.collection('sharableLinks').add({
      email: email,
      data: data,
      type: 'applications',
      expirationTimestamp: getExpirationDate(expiration),
      expiration: expiration,
      opened: false,
      audience,
    });
    return res.id;
  } catch (error) {
    console.log('error', error);
    return false;
  }
}

export async function shareApplication({ data, email, expiration }) {
  delete data.doc;
  console.log('data', data);
  try {
    const res = await db.collection('sharableLinks').add({
      email: email,
      data: data,
      type: 'application',
      expirationTimestamp: getExpirationDate(expiration),
      expiration: expiration,
      opened: false,
    });
    return res.id;
  } catch (error) {
    console.log('error', error);
    return false;
  }
}

export async function getSharableData(id) {
  var response = await axios.post(
    `https://us-central1-knowrare-platform-e2cc9.cloudfunctions.net/getSharableLinkData`,
    { id },
    { headers: { 'Content-Type': 'application/json' } }
  );
  return response;
}
