import React, { useContext } from 'react';
import StudyService from '../../Services/StudyService/StudyService';
import { StudiesContext } from '../../contexts/Studies';
import { SnackbarContext } from '../../contexts/Snackbar';
import Comments from './Comments';

const ApplicationComments = ({ study, application }) => {
    const { studies, setStudies } = useContext(StudiesContext);
    const { show } = useContext(SnackbarContext);

    const updateApplication = async (comments, message = 'Comment added successfully') => {
        let updatedStudiesArray = studies;
        const currentApplication = application;
        for (let index = 0; index < updatedStudiesArray.length; index++) {
            let currentStudy = updatedStudiesArray[index];
            if (currentStudy.document.id === study.document.id) {
                for (
                    let applicationIndex = 0;
                    applicationIndex < currentStudy.applications.length;
                    applicationIndex++
                ) {
                    let application = currentStudy.applications[applicationIndex];
                    if (application.id === currentApplication.id) {
                        application.comments = comments;
                        currentStudy.applications[applicationIndex] = application;
                        updatedStudiesArray[index] = currentStudy;
                        setStudies([...updatedStudiesArray]);
                        show('success', message);
                    }
                }
            }
        }
        StudyService.updateApplicationByDoc(currentApplication.doc.ref, {
            comments: comments,
        });
    };

    return (
        <Comments commentsList={application?.comments || []} updateComments={updateApplication} />
    );
};

export default ApplicationComments;