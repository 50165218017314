import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles((theme) => ({
  iconBackground: {
    display: 'inline-block',
    width: 40,
    height: 40,
    borderRadius: '50%',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #4fb4e8',
  },
  icon: {
    color: '#4fb4e8',
    height: 20,
    width: 20,
    marginLeft: 10,
    marginTop: 10,
  },
  nctIcon: {
    color: '#4fb4e8',
    height: 'auto',
    width: 30,
    marginLeft: 5,
    marginTop: 14,
  },
  iconTitle: {
    fontSize: 12,
    color: '#4fb4e8',
    // fontFamily: 'arial',
    textAlign: 'left',
  },
  iconSubTitle: {
    fontSize: mobile ? 15 : 18,
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: 2,
    textAlign: 'left',
  },
}));

export default function DatePickers(props) {
  const classes = useStyles();

  return (
    <div style={{ marginTop: 32 }}>
      <div
        className={classes.iconBackground}
        style={{ verticalAlign: 'middle' }}
      >
        <img
          alt=''
          src={require('../../../Assets/Images/' + props.icon)}
          className={classes.icon}
        />
      </div>
      <div
        style={{
          display: 'inline-block',
          marginLeft: 12,
          verticalAlign: 'middle',
        }}
      >
        <Typography variant='h4' className={classes.iconTitle}>
          {props.title}
        </Typography>
        <div className={classes.iconSubTitle}>{props.text}</div>
      </div>
    </div>
  );
}
