import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import MuiTheme from './MuiTheme';
import './polyfills';

import AuthContextProvider from './contexts/Auth.js';
import SnackbarContextProvider from './contexts/Snackbar.js';
import FilterContextProvider from './contexts/Filter';
import StudiesContextProvider from './contexts/Studies';
import StudyContextProvider from './contexts/StudyContext';
import { createTheme, ThemeProvider } from '@mui/material';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';


const themeData =
{
  typography: {
    fontFamily: ["'Poppins', sans-serif"].join(','),
    main: "'Poppins', sans-serif",
    h2: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 600,
      fontSize: 48,
    },
    button: {
      fontFamily: "'Poppins', sans-serif",
    },
    outlined: {
      fontFamily: "'Poppins', sans-serif",
    },
    // h4: {
    //   fontFamily: font3,
    //   fontWeight: 'bold',
    // },
    // input: {
    //   fontFamily: "'Poppins', sans-serif",
    // },
  },

  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 'bold',
          fontSize: 16,
          borderRadius: 10,
          padding: '10px 20px',
          backgroundColor: '#4fb4e8',
          color: 'white',
          '&:hover': {
            backgroundColor: '#4fb4e8',
          },
          '&:active': {
            backgroundColor: '#4fb4e8',
          },
          '&:disabled': {
            backgroundColor: '#4fb4e8',
            color: 'white',
          },
        },
      },
    },
    MuiInput: {
      root: {
        fontFamily: "'Poppins', sans-serif",
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: "'Poppins', sans-serif",
      },
      label: {
        fontFamily: "'Poppins', sans-serif",
      },
    },
  },
  direction: 'ltr',
  palette: {
    background: {
      paper: 'white',
      default: 'white',
    },
    primary: {
      main: '#4fb4e8',
      secondary: '#ed3636',
      white: '#ed3636',
      dark: '#4A4A4A',
      gray: 'rgba(0, 0, 0, 0.6)',
    },
    secondary: {
      main: '#ed3636',
    },
  },
}

const theme = createTheme(themeData);
const muiTheme = createMuiTheme(themeData);

ReactDOM.render(
  <AuthContextProvider>
    <StudiesContextProvider>
      <StudyContextProvider>
        <SnackbarContextProvider>
          <FilterContextProvider>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <CssBaseline>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </CssBaseline>
              </ThemeProvider>
            </MuiThemeProvider>
          </FilterContextProvider>
        </SnackbarContextProvider>
      </StudyContextProvider>
    </StudiesContextProvider>
  </AuthContextProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
