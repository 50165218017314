import React, { useContext } from 'react';
import { SnackbarContext } from '../../contexts/Snackbar';
import Comments from './Comments';
import { StudyContext } from '../../contexts/StudyContext';

const StudyCenterComments = ({ studyCenter, studyCenterRef }) => {
    const { getCenters } = useContext(StudyContext);
    const { show } = useContext(SnackbarContext);

    const updateStudyCenter = async (comments, message = 'Comment added successfully') => {
        await studyCenterRef.set(
            { comments: comments },
            { merge: true }
        );
        show('success', message);
        getCenters();
    };

    return (
        <Comments commentsList={studyCenter?.comments || []} updateComments={updateStudyCenter} />
    );
};

export default StudyCenterComments;