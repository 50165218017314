import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { AuthContext } from '../contexts/Auth.js'
import history from '../utils/history'

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    alignItems: 'center',
    padding: 16,
    textAlign: 'center'
  },
  title: {
    fontSize: mobile ? 28 : 32,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    color: '#4A4A4A',
    marginTop: 12,
    maxWidth: 525,
  },
  description: {
    fontSize: 16,
    fontFamily: "'Poppins', sans-serif",
    color: '#4A4A4A',
    marginTop: 12,
    maxWidth: 552
  },
  ctaButton: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
    color: 'white',
    paddingLeft: 32, paddingRight: 32,
    height: 56,
    borderRadius: 16,
    textTransform: 'none',
    marginTop: 32,
    fontSize: 16,
    backgroundColor: '#4fb4e8',
    '&:hover': {
      backgroundColor: '#4fb4e8'
    }
  }
}));

function Page404() {

  const classes = useStyles();

  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
  }, [])

  const openChromeDownloadPageInNewTab = () => {
    let url = 'https://www.google.com/chrome/?brand=CHBD&brand=BNSD'
    window.open(url, '_blank').focus();
  }

  return (
    <div className={classes.root}>
      {mobile ? (
        <div>
          <img alt='' src={require('../Assets/Images/mobileTopLeft.png')} style={{ width: 'auto', height: '25vh', position: 'absolute', top: 0, left: 0 }} />
          <img alt='' src={require('../Assets/Images/mobileTopRight.png')} style={{ width: 'auto', height: '25vh', position: 'absolute', top: 0, right: 0 }} />
          <img alt='' src={require('../Assets/Images/mobileBottomRight.png')} style={{ width: 'auto', height: '25vh', position: 'absolute', bottom: 0, right: 0 }} />
        </div>
      ) : (
        <div>
          <img alt='' src={require('../Assets/Images/desktopTopLeft.png')} style={{ width: 'auto', height: '35vh', position: 'absolute', top: 0, left: 0 }} />
          <img alt='' src={require('../Assets/Images/desktopTopRight.png')} style={{ width: 'auto', height: '35vh', position: 'absolute', top: 0, right: 0 }} />
          <img alt='' src={require('../Assets/Images/desktopBottomRight.png')} style={{ width: 'auto', height: '35vh', position: 'absolute', bottom: 0, right: 0 }} />
        </div>
      )}
      <img alt='' src={require('../Assets/Images/browser-error.png')} style={{ width: '80%', maxWidth: '350px', height: 'auto', marginTop: mobile ? 100 : 128 }} />
      <Typography className={classes.title}>Uh Oh... Looks Like Your Browser Isn’t Compatible</Typography>
      <Typography className={classes.description}>To continue using Know Rare, please download Google Chrome so we can connect you to researchers and potential clinical studies.</Typography>
      <Button className={classes.ctaButton} onClick={openChromeDownloadPageInNewTab}>Download Chrome</Button>
    </div>
  );
}

export default Page404;