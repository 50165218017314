import React, { useContext, useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import '../../../../node_modules/antd/dist/antd.css';
import { Table, Tag, Space } from 'antd';
import { Popover, Button } from 'antd';
import { IconButton } from '@material-ui/core';
import moment from 'moment';
import Status from './Status';
import Centers from './Centers';
import FilterDrawer from './FilterDrawer';
import { FilterContext } from '../../../contexts/Filter';
import { Typography } from '@material-ui/core';
import StudyService from '../../../Services/StudyService/StudyService';

const { Column, ColumnGroup } = Table;

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "'Poppins', sans-serif",
  },
  mobileTitle: {
    fontSize: 22,
    color: '#4A4A4A',
    paddingBottom: 24,
  },
  searchInput: {
    backgroundColor: '#F4F4F4',
    width: 350,
    height: 38,
    borderRadius: 10,
    color: '#4A4A4A',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    border: 'none',
    paddingRight: 8,
    paddingLeft: 8,
    // marginTop: 6,
    paddingRight: 32,
  },
}));

const StudyTable = (props) => {
  const classes = useStyle();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [applicationsToShow, setApplicationsToShow] = useState([]);
  const [search, setSearch] = useState('');

  const [loadingTable, setLoadingTable] = useState(false);

  const { openFilter, setOpenFilter } = useContext(FilterContext);

  const renameStatus = (status) => {
    if (status === 'Applied') {
      return 'Applied';
    } else if (status === 'Schedule Appointment') {
      return 'Applied';
    } else if (status === 'Confirmed Appointment') {
      return 'Scheduled';
    } else if (status === 'Completed') {
      return 'Completed';
    } else if (status === 'Not Eligible') {
      return 'Failed';
    } else if (status === 'Pending') {
      return 'Pending';
    } else if (status === 'TBD') {
      return 'Post Call';
    } else {
      return 'Applied';
    }
  };

  const getAgeFromDate = (date) => {
    let splitedDate = date.split('-');
    let formattedDate =
      splitedDate[0] + '-' + splitedDate[2] + '-' + splitedDate[1];
    return moment().diff(formattedDate, 'years', false);
  };

  const [columns, setColumns] = useState([
    {
      title: '#',
      dataIndex: 'key',
      fixed: mobile ? 'null' : 'left',
      width: 60,
    },
    {
      title: 'Applied At',
      dataIndex: 'appliedAt',
      width: 130,
      fixed: mobile ? 'null' : 'left',
      sorter: {
        compare: (a, b) => a.appliedAt.seconds - b.appliedAt.seconds,
      },
      render: (result) => (
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            color: '#4A4A4A',
          }}
        >
          {result.format}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: mobile ? 'null' : 'left',
      width: 170,
      sorter: {
        compare: (a, b) => a.status.status.length - b.status.status.length,
      },
      render: (status) => (
        <>
          <div style={getStyleForStatus(renameStatus(status))}>
            {renameStatus(status)}
          </div>
        </>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 160,
    },
    {
      title: 'Study Center',
      dataIndex: 'studyCenter',
      width: 160,
    },
    // {
    //     title: 'Study Center',
    //     dataIndex: 'studyCenter',
    //     width: 250,
    //     render: center => (
    //         <>
    //             <Centers study={props.study} center={center.center} application={center.application} renameStatus={renameStatus} />
    //         </>
    //     ),
    // },
    {
      title: 'Notes',
      dataIndex: 'note',
      width: 170,
      render: (result) => (
        <>
          {!result.status.note ? (
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              {'Not Added'}
            </div>
          ) : (
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  marginRight: 12,
                }}
              >
                {result.selectedOption}
              </div>
              <Popover
                placement='top'
                style={{ borderRadius: 10 }}
                title={() => (
                  <div
                    style={{
                      color: '#4fb4e8',
                      fontSize: 11,
                      fontWeight: 900,
                      letterSpacing: 1,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    Note
                  </div>
                )}
                content={() => (
                  <div
                    style={{
                      color: '#4A4A4A',
                      fontSize: 13,
                      fontWeight: 'normal',
                      letterSpacing: 0.24,
                      fontFamily: "'Poppins', sans-serif",
                      maxWidth: 340,
                    }}
                  >
                    {result.status.note}
                  </div>
                )}
                trigger='click'
              >
                <Button
                  style={{
                    border: 'none',
                    padding: 0,
                    backgroundColor: 'none',
                    background: 'none',
                    width: 24,
                    height: 24,
                    marginTop: 0,
                  }}
                >
                  {result.status.status === 'Completed' ||
                    result.status.status === 'Not Eligible' ? (
                    <img
                      alt=''
                      src={require('../../../Assets/Images/reNoteIcon.png')}
                      width={24}
                      height={24}
                    />
                  ) : (
                    <img
                      alt=''
                      src={require('../../../Assets/Images/blackNoteIcon.png')}
                      width={24}
                      height={24}
                    />
                  )}
                </Button>
              </Popover>
            </div>
          )}
        </>
      ),
    },
  ]);

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return str;
  };

  useEffect(() => {
    let study = props.study;
    let applications = study.applications;
    setApplicationsToShow(applications);
    return;
    let data = [];
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      data.push({
        key: index + 1,
        appliedAt: {
          format: moment(application.appliedAt.seconds * 1000).format('ll'),
          seconds: application.appliedAt.seconds,
        },
        patientId: application.userId,
        age: getAgeFromDate(application.userData.birthDate),
        ethnicity: application.userData.ethnicity,
        name: {
          name:
            application.userData.firstName +
            ' ' +
            application.userData.lastName,
          id: application.userData.firebaseId.substring(0, 9),
        },
        email: application.userData.email,
        phone: formatPhoneNumber(application.userData.phone),
        gender: application.userData.gender,
        location: application.userData.locationData.description,
        // location: application.nearestLocationData.locationData.address_components[0].long_name + ', ' +  application.nearestLocationData.locationData.address_components[0].short_name,
        diagnosed: {
          diagnosed: application.userData.diagnosedByDoctor,
          diagnosedAge: application.userData.diagnosedAge,
        },
        diagnosedAge: application.userData.diagnosedAge,
        studyCenter: application.studyLocation,
        status: application.status[application.status.length - 1].status,
        visitResults: application.exitSurvey
          ? application.exitSurvey
          : 'Not Accepted',
        icfReviewed: application.icfReviewed === true ? 'Yes' : 'No',
        symptoms: application.symptoms,
        conditions: application.userData.otherConditions,
        procedures: getProceduresAsArray(application.userData.procedures),
        comments: 'icon',
        medications: application.medications,
        travelAssist: application.userData.willingToTravel ? 'Yes' : 'No',
        otherTrials: application.userData.startNewMedicationInTheLast6Month
          ? 'Yes'
          : 'No',
      });
    }
    const sortedData = data.sort(
      (a, b) => b.appliedAt.seconds - a.appliedAt.seconds
    );
    setData(sortedData);
    setFilteredData(sortedData);
  }, []);

  useEffect(() => {
    let data = [];
    for (let index = 0; index < applicationsToShow.length; index++) {
      const application = applicationsToShow[index];
      data.push({
        key: index + 1,
        appliedAt: {
          format: moment(application.appliedAt.seconds * 1000).format('ll'),
          seconds: application.appliedAt.seconds,
        },
        patientId: application.userId,
        age: getAgeFromDate(application.userData.birthDate),
        ethnicity: application.userData.ethnicity,
        name: {
          name:
            application.userData.firstName +
            ' ' +
            application.userData.lastName,
          id: application.userData.firebaseId.substring(0, 9),
        },
        email: application.userData.email,
        phone: formatPhoneNumber(application.userData.phone),
        gender: application.userData.gender,
        location: application.userData.locationData.description,
        // location: application.nearestLocationData.locationData.address_components[0].long_name + ', ' +  application.nearestLocationData.locationData.address_components[0].short_name,
        diagnosed: {
          diagnosed: application.userData.diagnosedByDoctor,
          diagnosedAge: application.userData.diagnosedAge,
        },
        diagnosedAge: application.userData.diagnosedAge,
        studyCenter: application.studyLocation,
        status: application.status[application.status.length - 1].status,
        note: { status: application.status[application.status.length - 1] },
        // note: application.status[application.status.length - 1].note,
        visitResults: application.exitSurvey
          ? application.exitSurvey
          : 'Not Accepted',
        icfReviewed: application.icfReviewed === true ? 'Yes' : 'No',
        symptoms: application.symptoms,
        conditions: application.userData.otherConditions,
        procedures: getProceduresAsArray(application.userData.procedures),
        comments: 'icon',
        medications: application.medications,
        travelAssist: application.userData.willingToTravel ? 'Yes' : 'No',
        otherTrials: application.userData.startNewMedicationInTheLast6Month
          ? 'Yes'
          : 'No',
      });
    }
    const sortedData = data.sort(
      (a, b) => b.appliedAt.seconds - a.appliedAt.seconds
    );
    setData(sortedData);
    setFilteredData(sortedData);
    setLoadingTable(false);
  }, [applicationsToShow]);

  const getProceduresAsArray = (procedures) => {
    var objectConstructor = {}.constructor;
    if (procedures.constructor === objectConstructor) {
      let proceduresArray = [];
      for (let index = 0; index < Object.keys(procedures).length; index++) {
        const procedure = Object.keys(procedures)[index];
        if (procedures[procedure]) {
          proceduresArray.push(procedure);
        }
      }
      return proceduresArray;
    } else {
      return procedures;
    }
  };

  // const getStyleForStatus = (status) => {
  //     if (status === 'Applied') {
  //         return {cursor: 'pointer', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', width: 141, height: 30, borderRadius: 8, backgroundColor: '#DBDBDB', color: '#4A4A4A', textAlign: 'center', paddingTop: 2}
  //     } else if (status === 'Completed') {
  //         return {cursor: 'pointer', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', width: 141, height: 30, borderRadius: 8, backgroundColor: '#DDF5DD', color: '#57CE54', textAlign: 'center', paddingTop: 2}
  //     } else if (status === 'Failed') {
  //         return {cursor: 'pointer', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', width: 141, height: 30, borderRadius: 8, backgroundColor: '#4fb4e830', color: '#4fb4e8', textAlign: 'center', paddingTop: 2}
  //     } else {
  //         return {cursor: 'pointer', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', width: 141, height: 30, borderRadius: 8, backgroundColor: '#FDFEDA', color: '#C6C93A', textAlign: 'center', paddingTop: 2}
  //     }
  // }

  const getStyleForStatus = (status) => {
    if (status === 'Applied') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        maxWidth: 160,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4fb4e830',
        color: '#4fb4e8',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Completed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        maxWidth: 160,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DDF5DD',
        color: '#57CE54',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Failed') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        maxWidth: 160,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#4A4A4A',
        color: 'white',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Confirmed Appointment') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        maxWidth: 160,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Schedule Appointment') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        maxWidth: 160,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else if (status === 'Pending') {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        maxWidth: 160,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#DBDBDB',
        color: '#4A4A4A',
        textAlign: 'center',
        paddingTop: 2,
      };
    } else {
      return {
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'bold',
        minWidth: 141,
        maxWidth: 160,
        height: 30,
        borderRadius: 8,
        backgroundColor: '#FDFEDA',
        color: '#C6C93A',
        textAlign: 'center',
        paddingTop: 2,
      };
    }
  };

  const onChange = (pagination, filters, sorter, extra) => { };

  useEffect(() => {
    handleChangeSearch(search);
  }, [search]);

  const timer = React.useRef(null);

  const handleChangeSearchInput = (value) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      // your logic
      setLoadingTable(true);
      setSearch(value);
    }, 500);
  };

  const handleChangeSearch = (value) => {
    let study = props.study;
    if (value === '') {
      setApplicationsToShow(study.applications);
      return;
    }
    let matchedApplications = [];
    for (let index = 0; index < study.applications.length; index++) {
      const application = study.applications[index];
      let patientName =
        application.userData.firstName.toLowerCase() +
        ' ' +
        application.userData.lastName.toLowerCase();
      let studyAddress = application.studyAddress.toLowerCase();
      let studyName = application.studyLocation.toLowerCase();
      let email = application.userData.email.toLowerCase();
      if (
        patientName.includes(value.toLowerCase()) ||
        studyAddress.includes(value.toLowerCase()) ||
        studyName.includes(value.toLowerCase()) ||
        email.includes(value.toLowerCase())
      ) {
        matchedApplications.push(application);
      }
    }
    setApplicationsToShow(matchedApplications);
  };

  return (
    <div style={{ fontFamily: "'Poppins', sans-serif", color: '#4fb4e8' }}>
      {/* <Typography className={classes.mobileTitle} variant="h2">Leads</Typography> */}
      <FilterDrawer
        includeStudyCenter={true}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        data={data ? data : []}
        setFilteredData={setFilteredData}
      />
      <div style={{ flexGrow: 1 }}>
        <input
          placeholder='Search'
          className={classes.searchInput}
          onChange={(e) => handleChangeSearchInput(e.target.value)}
        />
      </div>
      <Table
        loading={loadingTable}
        pagination={['bottomLeft', 'bottomRight']}
        scroll={{ x: mobile ? '100%' : 1545 }}
        columns={columns}
        dataSource={filteredData}
        onChange={onChange}
        style={{ marginTop: mobile ? 0 : 12, color: '#4fb4e8' }}
      />
    </div>
  );
};

export default StudyTable;
