import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import ReminderDialog from './ReminderDialog';
import Badge from '@material-ui/core/Badge';
import UpcomingVisitDialog from './UpcomingVisitDialog';
import { useNavigate } from 'react-router-dom';
var momentTZ = require('moment-timezone');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    marginTop: -12,
  },
  tab: {
    fontWeight: 'bold',
    fontFamily: "'Poppins', sans-serif",
    display: 'flex',
  },
  badge: {
    fontWeight: 'bold',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 12,
    width: 20,
    height: 20,
    marginLeft: 4,
    marginTop: 3,
    background: '#4fb4e8',
    color: 'white',
    textAlign: 'center',
    borderRadius: 4,
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#4fb4e8',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  appointmentsBox: {
    width: '100%',
    // height: 64,
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 12px rgba(51, 51, 51, 0.12)',
    borderRadius: 10,
    padding: 8,
    paddingTop: 12,
    paddingBottom: 12,
    marginTop: 12,
    cursor: 'pointer',
  },
  timeBox: {
    backgroundColor: '#4fb4e830',
    borderRadius: 8,
    textAlign: 'center',
    color: '#4fb4e8',
    paddingLeft: 12,
    paddingRight: 12,
    float: 'right',
    padding: 8,
    fontSize: 14,
  },
  timeBoxPending: {
    backgroundColor: 'rgba(60, 60, 60, 0.2)',
    borderRadius: 8,
    textAlign: 'center',
    color: '#3C3C3C',
    paddingLeft: 12,
    paddingRight: 12,
    float: 'right',
    padding: 8,
    fontSize: 14,
  },
  appointmentTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 4,
  },
  appointmentSubTitle: {
    color: '#4A4A4A',
    letterSpacing: 0.4,
    fontSize: 14,
    fontWeight: 500,
    opacity: 0.5,
    marginTop: 4,
  },
  summaryRoot: {
    borderBottom: '1px solid rgb(200,200,200)',
    padding: 0,
    background: 'transparent',
  },
  accordionRoot: {
    padding: 0,
    background: 'transparent',
  },
  detailsRoot: {
    padding: 0,
    paddingTop: 12,
    background: 'transparent',
  },
}));

export default function SimpleAccordion({ study, dashboardType }) {
  const navigate = useNavigate();

  const classes = useStyles();

  const [virtualVisits, setVirtualVisits] = useState([]);
  const [pending, setPending] = useState([]);
  const [inquiries, setInquiries] = useState([]);

  const [selectedTab, setSelectedTab] = useState('INQUIRIES');

  const [openReminderDialog, setOpenReminderDialog] = useState(false);
  const [remindApplication, setRemindApplication] = useState(null);

  const [openUpcomingVisitPopup, setOpenUpcomingVisitPopup] = useState(false);
  const [patientName, setPatientName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [application, setApplication] = useState(null);

  const openUpcomingVisit = () => {
    setOpenUpcomingVisitPopup(true);
  };

  useEffect(() => {
    if (study) {
      let applications = study.applications;
      let inquiriesArray = [];
      let pendingArray = [];
      let virtualVisitsArray = [];
      for (let index = 0; index < applications.length; index++) {
        const application = applications[index];
        const status = application.status[application.status.length - 1].status;
        if (status === 'Applied' || status === 'Different Date Needed') {
          inquiriesArray.push(application);
        } else if (status === 'Schedule Appointment') {
          pendingArray.push(application);
        } else if (status === 'Confirmed Appointment') {
          virtualVisitsArray.push(application);
        }
      }
      setInquiries(
        inquiriesArray.sort(
          (a, b) => b.appliedAt.seconds - a.appliedAt.seconds,
        ),
      );
      setPending(
        pendingArray.sort((a, b) => b.appliedAt.seconds - a.appliedAt.seconds),
      );
      setVirtualVisits(
        virtualVisitsArray.sort(
          (a, b) => b.selectedDate.seconds - a.selectedDate.seconds,
        ),
      );
    }
  }, []);

  const openMeeting = (item) => {
    setApplication(item);
    let doctorTimezone = moment.tz.guess();
    // console.log('doctorTimezone', doctorTimezone)
    // doctorTimezone = 'America/Los_Angeles'
    let meetingDateTime = `${moment(item.selectedDate.seconds * 1000).format(
      'YYYY-MM-DD',
    )} ${item.selectedTime}`;
    // console.log('meetingDateTime', meetingDateTime)
    var meetingDataAndTimeInUserTimezone = momentTZ.tz(
      meetingDateTime,
      'YYYY-MM-DD h:mm A',
      item.availability.tz,
    );
    let doctorDataAndTime = meetingDataAndTimeInUserTimezone
      .clone()
      .tz(doctorTimezone);
    let tempDoctorTime = doctorDataAndTime;
    if (
      moment(new Date()) > tempDoctorTime.subtract(10, 'minutes') &&
      moment(new Date()) < tempDoctorTime.add(1, 'hour')
    ) {
      navigate('/home/meeting/' + item.doc.id);
    } else {
      setPatientName(item.userData.firstName);
      setTime(getTimeInLocalTimezone(item));
      setDate(doctorDataAndTime.format('ll'));
      openUpcomingVisit();
    }
  };

  const getTimeInLocalTimezone = (item) => {
    let meetingDateTime = `${moment(item.selectedDate.seconds * 1000).format(
      'YYYY-MM-DD',
    )} ${item.selectedTime}`;
    var meetingDataAndTimeInUserTimezone = momentTZ
      .tz(meetingDateTime, 'YYYY-MM-DD h:mm A', item.availability.tz)
      .format('h:mm a z');
    return meetingDataAndTimeInUserTimezone;
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: 12,
        }}
      >
        <div
          className={classes.tab}
          variant="h2"
          style={{
            cursor: 'pointer',
            fontSize: 16,
            color:
              selectedTab === 'INQUIRIES' ? '#4fb4e8' : 'rgba(74,74,74,0.6)',
          }}
          onClick={() => setSelectedTab('INQUIRIES')}
        >
          Inquiries{' '}
          {inquiries.length > 0 && (
            <div className={classes.badge}>{inquiries.length}</div>
          )}
        </div>
        <div
          className={classes.tab}
          variant="h2"
          style={{
            cursor: 'pointer',
            fontSize: 16,
            color: selectedTab === 'PENDING' ? '#4fb4e8' : 'rgba(74,74,74,0.6)',
          }}
          onClick={() => setSelectedTab('PENDING')}
        >
          Pending{' '}
          {pending.length > 0 && (
            <div className={classes.badge}>{pending.length}</div>
          )}
        </div>
        <div
          className={classes.tab}
          variant="h2"
          style={{
            cursor: 'pointer',
            fontSize: 16,
            color:
              selectedTab === 'VIRTUAL VISITS'
                ? '#4fb4e8'
                : 'rgba(74,74,74,0.6)',
          }}
          onClick={() => setSelectedTab('VIRTUAL VISITS')}
        >
          Virtual Visits{' '}
          {virtualVisits.length > 0 && (
            <div className={classes.badge}>{virtualVisits.length}</div>
          )}
        </div>
      </div>
      {remindApplication && openReminderDialog && (
        <ReminderDialog
          open={openReminderDialog}
          setOpen={setOpenReminderDialog}
          applicationData={remindApplication}
        />
      )}
      <UpcomingVisitDialog
        application={application}
        open={openUpcomingVisitPopup}
        setOpen={setOpenUpcomingVisitPopup}
        patientName={patientName}
        date={date}
        time={time}
      />
      {selectedTab === 'INQUIRIES' && (
        <div style={{ width: '100%', marginTop: 24 }}>
          {inquiries.map((item, index) => (
            <div
              key={index}
              className={classes.appointmentsBox}
              onClick={() => navigate('/home/schedule/' + item.doc.id)}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'left',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                >
                  <Typography className={classes.appointmentTitle} variant="h2">
                    {item.userData.firstName}
                  </Typography>
                  <Typography
                    className={classes.appointmentSubTitle}
                    variant="h2"
                  >
                    Applied at{' '}
                    {moment(item.appliedAt.seconds * 1000).format('ll')}
                  </Typography>
                </div>
                <div
                  style={{
                    textAlign: 'right',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                >
                  {item.status[item.status.length - 1].status ===
                    'Schedule Appointment' ? (
                    <Typography className={classes.timeBoxPending} variant="h2">
                      Pending
                    </Typography>
                  ) : (
                    <Typography className={classes.timeBox} variant="h2">
                      Review
                    </Typography>
                  )}
                </div>
              </div>
              {dashboardType === 'admin' && (
                <div style={{ width: '100%', marginTop: 8 }}>
                  <Typography
                    className={classes.appointmentSubTitle}
                    variant="h2"
                  >
                    {item.studyLocation}
                  </Typography>
                </div>
              )}
            </div>
          ))}
          {inquiries.length === 0 && (
            <div
              style={{
                width: '100%',
                borderRadius: 12,
                marginTop: 24,
                padding: 24,
                backgroundColor: '#F6F6F6',
                color: '#B1B1B1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h2"
                style={{ color: '#B1B1B1', fontSize: 18 }}
              >
                No Inquiries
              </Typography>
            </div>
          )}
        </div>
      )}
      {selectedTab === 'PENDING' && (
        <div style={{ width: '100%', marginTop: 24 }}>
          {pending.map((item, index) => (
            <div
              key={index}
              className={classes.appointmentsBox}
              onClick={() => navigate('/home/schedule/' + item.doc.id)}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'left',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                >
                  <Typography className={classes.appointmentTitle} variant="h2">
                    {item.userData.firstName}
                  </Typography>
                  <Typography
                    className={classes.appointmentSubTitle}
                    variant="h2"
                  >
                    Applied at{' '}
                    {moment(item.appliedAt.seconds * 1000).format('ll')}
                  </Typography>
                </div>
                <div
                  style={{
                    textAlign: 'right',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                  }}
                >
                  {item.status[item.status.length - 1].status ===
                    'Schedule Appointment' ? (
                    <Typography className={classes.timeBoxPending} variant="h2">
                      Pending
                    </Typography>
                  ) : (
                    <Typography className={classes.timeBox} variant="h2">
                      Review
                    </Typography>
                  )}
                </div>
              </div>
              {dashboardType === 'admin' && (
                <div style={{ width: '100%', marginTop: 8 }}>
                  <Typography
                    className={classes.appointmentSubTitle}
                    variant="h2"
                  >
                    {item.studyLocation}
                  </Typography>
                </div>
              )}
            </div>
          ))}
          {pending.length === 0 && (
            <div
              style={{
                width: '100%',
                borderRadius: 12,
                marginTop: 24,
                padding: 24,
                backgroundColor: '#F6F6F6',
                color: '#B1B1B1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h2"
                style={{ color: '#B1B1B1', fontSize: 18 }}
              >
                No Pending
              </Typography>
            </div>
          )}
        </div>
      )}
      {selectedTab === 'VIRTUAL VISITS' && (
        <div style={{ width: '100%', marginTop: 24 }}>
          {virtualVisits.map((item, index) => (
            <div
              key={index}
              className={classes.appointmentsBox}
              onClick={() => openMeeting(item)}
            >
              <div
                style={{
                  width: '50%',
                  textAlign: 'left',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                <Typography className={classes.appointmentTitle} variant="h2">
                  {item.userData.firstName}
                </Typography>
                <Typography
                  className={classes.appointmentSubTitle}
                  variant="h2"
                >
                  {moment(item.selectedDate.seconds * 1000).format('ll')}
                </Typography>
              </div>
              <div
                style={{
                  width: '50%',
                  textAlign: 'right',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                <Typography className={classes.timeBox} variant="h2">
                  {getTimeInLocalTimezone(item)}
                </Typography>
              </div>
              {dashboardType === 'admin' && (
                <div style={{ width: '100%', marginTop: 8 }}>
                  <Typography
                    className={classes.appointmentSubTitle}
                    variant="h2"
                  >
                    {item.studyLocation}
                  </Typography>
                </div>
              )}
            </div>
          ))}
          {virtualVisits.length === 0 && (
            <div
              style={{
                width: '100%',
                borderRadius: 12,
                marginTop: 24,
                padding: 24,
                backgroundColor: '#F6F6F6',
                color: '#B1B1B1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h2"
                style={{ color: '#B1B1B1', fontSize: 18 }}
              >
                No Virtual Visits
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
