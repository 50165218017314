import firebase, { storage, analytics } from '../../Firebase';
import axios from 'axios';
import UserService from '../UserService/UserService';
import moment from 'moment';

export default {
  createApplication,
  getApplications,
  getUser,
  getUserPages,
  updateUser,
  getUserOptionalStudies,
  getApplicationById,
  updateApplicationById,
  updateStudies,
  getSponsoredByStudyId,
  getSponsoredAllStudies,
  updateStudyByRef,
  getTeamByStudyRef,
  getSupportTeamByStudyRef,
  updateTeamMemberByRef,
  addTeamMemberToStudyByRef,
  deleteByRef,
  getCentersByStudyRef,
  getStudyDataByNCTID,
  updateCenterByRef,
  addNewStudy,
  getCenterStudies,
  getStudyStudies,
  getStudiesByNCTID,
  addNewStudyCenterByRef,
  updateApplicationByDoc,
  getDocusignLinkByApplicationDoc,
  getAllAdminData,
  getTeamOfLocationFacility,
  getAllCentersUnderStudy,
  getStudyDataWithUsersData,
};

const db = firebase.firestore();

async function deleteByRef(ref) {
  await ref.delete();
}

async function getTeamOfLocationFacility(locationFacility) {
  let centers = await db
    .collectionGroup('centers')
    .where('LocationFacility', '==', locationFacility)
    .get();
  if (centers.docs.length === 0) return [];
  let centerTeam = await centers.docs[0].ref.collection('team').get();
  return centerTeam.docs;
}

async function getDocusignLinkByApplicationDoc(doc, studyAddress) {
  // console.log('doc', doc)
  // console.log('studyAddress', studyAddress)
  let study = await db
    .collection('companies')
    .doc(doc.ref.parent.parent.parent.parent.parent.parent.id)
    .collection('studies')
    .doc(doc.ref.parent.parent.parent.parent.id)
    .get();
  let studyData = study.data();
  let studyAddressLowercase = studyAddress.toLowerCase();
  if (studyAddressLowercase.includes('usa')) {
    return studyData.informedConsentUrlEnglish;
  } else if (studyAddressLowercase.includes('germany')) {
    return studyData.informedConsentUrlGerman;
  } else if (studyAddressLowercase.includes('france')) {
    return studyData.informedConsentUrlFrench;
  } else if (studyAddressLowercase.includes('italy')) {
    return studyData.informedConsentUrlItalian;
  } else if (studyAddressLowercase.includes('norway')) {
    return studyData.informedConsentUrlNorwegian;
  } else if (studyAddressLowercase.includes('spain')) {
    return studyData.informedConsentUrlSpanish;
  } else {
    return '';
  }
}

async function getAllCentersUnderStudy(study) {
  // console.log('study', study)
}

async function addNewStudy(companyName, NCTID, studyData) {
  console.log('companyName', companyName);
  console.log('NCTID', NCTID);
  console.log('studyData', studyData);
  let res = await db
    .collection('companies')
    .where('companyName', '==', companyName)
    .get();
  console.log('companies', res.docs);
  if (res.docs.length > 0) {
    // console.log('res.docs', res.docs)
    res.docs[0].ref.collection('studies').doc(NCTID).set(studyData);
    return true;
  } else {
    return db
      .collection('companies')
      .add({ companyName: companyName })
      .then(async (docRef) => {
        db.collection('companies')
          .doc(docRef.id)
          .collection('studies')
          .doc(NCTID)
          .set(studyData);
        return true;
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
        return false;
      });
  }
}

async function addNewStudyCenterByRef(ref, studyData) {
  await ref.collection('centers').add(studyData);
  return;
}

async function getStudyDataByNCTID(NCTID, locationFacilities) {
  let response = await axios.get(
    `https://clinicaltrials.gov/api/v2/studies?query.term=${NCTID}&format=json`,
  );
  let locationList =
    response.data.studies[0].protocolSection.contactsLocationsModule.locations;
  let relevantsLocations = [];
  for (let index = 0; index < locationList.length; index++) {
    const location = locationList[index];
    // if (location.LocationCountry === 'United States') {
    if (!locationFacilities.includes(location.facility)) {
      relevantsLocations.push(location);
    }
    // }
  }
  return relevantsLocations;
}

async function getStudiesByNCTID(NCTID) {
  let relevantStudies = [];
  let response = await axios.get(
    `https://clinicaltrials.gov/api/v2/studies?query.term=${NCTID}&format=json`,
  );
  let studies = response.data.FullStudiesResponse.FullStudies;
  for (let index = 0; index < studies.length; index++) {
    const study = studies[index].Study;
    let locationList =
      study.ProtocolSection.ContactsLocationsModule.LocationList.Location;
    for (let index = 0; index < locationList.length; index++) {
      const location = locationList[index];
      if (location.LocationCountry === 'United States') {
        relevantStudies.push(study);
        break;
      }
    }
  }
  return relevantStudies;
}

async function getAllStudies() {
  let studies = await db.collectionGroup('studies').get();
  return studies.docs;
}

async function getStudyDataWithUsersData(study) {
  let studyData = study;
  let applications = studyData.applications.filter(
    (application) => application.deleted !== true,
  );
  let usersDataRequests = [];
  let usersIds = [];
  for (let index = 0; index < applications.length; index++) {
    let application = applications[index];
    usersIds.push(application.userId);
    // usersDataRequests.push(UserService.getUserById(application.userId))
  }
  let usersData = await UserService.getUserByIds(usersIds);
  // let usersData = await Promise.all(usersDataRequests)
  let applicationsData = [];
  for (let index = 0; index < applications.length; index++) {
    let applicationData = applications[index];
    for (let index = 0; index < usersData.length; index++) {
      const userData = usersData[index];
      if (userData && userData?.firebaseId === applicationData.userId) {
        applicationData.userData = userData;
      }
    }
    if (!applicationData.userData) continue;
    // applicationData.userData = usersData[index] ? usersData[index].document : null
    // applicationData.doc = applications[index]
    applicationsData.push(applicationData);
  }
  studyData.applications = applicationsData;
  studyData.withUsersData = true;
  return studyData;
}

async function getAllAdminData() {
  let studiesDocuments = await getAllStudies();
  let studiesData = [];
  for (let index = 0; index < studiesDocuments.length; index++) {
    studiesData.push(getStudiesData(studiesDocuments[index]));
  }
  let studies = await Promise.all(studiesData);
  return studies;
}

async function getStudyStudies() {
  let studiesDocuments = await getAllStudiesTheUserIsIn();
  let studiesData = [];
  for (
    let index = 0;
    index < studiesDocuments.studiesDocuments.length;
    index++
  ) {
    studiesData.push(getStudiesData(studiesDocuments.studiesDocuments[index]));
  }
  let studies = await Promise.all(studiesData);
  return studies;
}

async function getCenterStudies() {
  let studiesDocumentsAndMemberInCenters = await getAllStudiesTheUserIsIn();
  let studiesData = [];
  for (
    let index = 0;
    index < studiesDocumentsAndMemberInCenters.studiesDocuments.length;
    index++
  ) {
    studiesData.push(
      getStudiesDataForSpecificCenters(
        studiesDocumentsAndMemberInCenters.studiesDocuments[index],
        studiesDocumentsAndMemberInCenters.memberInCenters,
      ),
    );
  }
  let studies = await Promise.all(studiesData);
  return studies;
}

async function getSponsoredAllStudies() {
  let sponsoredStudiesDocuments = (await db.collectionGroup('studies').get())
    .docs;
  let studies = [];
  for (let index = 0; index < sponsoredStudiesDocuments.length; index++) {
    const sponsoredStudyDocument = sponsoredStudiesDocuments[index];
    const studyData = sponsoredStudyDocument.data();
    let studyCenters = (
      await sponsoredStudyDocument.ref.collection('centers').get()
    ).docs;
    let data = {
      document: sponsoredStudyDocument,
      studyData: studyData,
      studyCenters: studyCenters,
    };
    let companyDocument = await db
      .collection('companies')
      .doc(sponsoredStudyDocument.ref.parent.parent.id)
      .get();
    data.companyName = companyDocument.data().companyName;
    // get study's applications
    let applications = (
      await db
        .collectionGroup('applications')
        .where('studyId', '==', sponsoredStudyDocument.id)
        .get()
    ).docs;
    data.applicants = applications.length;
    let completed = 0;
    let scheduled = 0;
    let applied = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index].data();
      const applicationStatus =
        application.status[application.status.length - 1];
      if (applicationStatus.status === 'Completed') {
        completed += 1;
      } else if (applicationStatus.status === 'Applied') {
        applied += 1;
      } else if (applicationStatus.status === 'Confirmed Appointment') {
        scheduled += 1;
      } else {
        applied += 1;
      }
    }
    let usersDataRequests = [];
    for (let index = 0; index < applications.length; index++) {
      let application = applications[index].data();
      usersDataRequests.push(UserService.getUserById(application.userId));
    }
    let usersData = await Promise.all(usersDataRequests);
    let applicationsData = [];
    for (let index = 0; index < applications.length; index++) {
      let applicationData = applications[index].data();
      applicationData.userData = usersData[index]
        ? usersData[index].document
        : null;
      applicationData.doc = applications[index];
      applicationsData.push(applicationData);
    }
    data.applications = applicationsData;
    data.completed = completed;
    data.applied = applications.length;
    data.scheduled = scheduled;

    if (applications.length === 0) {
      studies.push(data);
      continue;
    } else {
      studies.push(data);
    }
  }
  return studies;
}

function getAllowedCenters(studyCenters, allowedCentersIds) {
  let allowedCenters = [];
  studyCenters.forEach((center) => {
    if (allowedCentersIds.includes(center.id)) {
      allowedCenters.push(center);
    }
  });
  return allowedCenters;
}

function getAllowedCentersApplications(applications, allowedCentersIds) {
  let allowedApplications = [];
  applications.forEach((application) => {
    if (allowedCentersIds.includes(application.ref.path.split('/')[5])) {
      allowedApplications.push(application);
    }
  });
  return allowedApplications;
}

async function getStudiesData(studyDocument) {
  const studyData = studyDocument.data();
  let queries = [
    await studyDocument.ref.collection('centers').get(),
    db.collection('companies').doc(studyDocument.ref.parent.parent.id).get(),
    db
      .collectionGroup('applications')
      .where('studyId', '==', studyDocument.id)
      .get(),
  ];
  let queriesData = await Promise.all(queries);
  if (queriesData[0].docs.length === 0) {
    console.log('queriesData[0].docs', studyDocument, studyDocument.data());
    console.log(await studyDocument.ref.collection('centers').get());
  }
  let studyCenters = queriesData[0].docs;
  let data = {
    document: studyDocument,
    studyData: studyData,
    studyCenters: studyCenters,
    allStudyCenters: studyCenters,
  };
  let companyDocument = queriesData[1];
  data.companyName = companyDocument.data().companyName;
  // get study's applications
  let applications = queriesData[2].docs;
  data.applicants = applications.length;
  let completed = 0;
  let scheduled = 0;
  let applied = 0;
  for (let index = 0; index < applications.length; index++) {
    const application = applications[index].data();
    const applicationStatus = application.status[application.status.length - 1];
    if (applicationStatus.status === 'Completed') {
      completed += 1;
    } else if (applicationStatus.status === 'Applied') {
      applied += 1;
    } else if (applicationStatus.status === 'Confirmed Appointment') {
      scheduled += 1;
    } else {
      applied += 1;
    }
  }
  let applicationsData = [];
  for (let index = 0; index < applications.length; index++) {
    let applicationData = applications[index].data();
    applicationData.doc = applications[index];
    applicationsData.push(applicationData);
  }
  data.applications = applicationsData;
  data.completed = completed;
  data.applied = applications.length;
  data.scheduled = scheduled;
  data.withUsersData = false;

  return data;
}

async function getStudiesDataForSpecificCenters(studyDocument, allowedCenters) {
  const studyData = studyDocument.data();
  let queries = [
    await studyDocument.ref.collection('centers').get(),
    await db
      .collection('companies')
      .doc(studyDocument.ref.parent.parent.id)
      .get(),
    await db
      .collectionGroup('applications')
      .where('studyId', '==', studyDocument.id)
      .get(),
  ];
  let queriesData = await Promise.all(queries);
  let studyCenters = getAllowedCenters(queriesData[0].docs, allowedCenters);
  let data = {
    document: studyDocument,
    studyData: studyData,
    studyCenters: studyCenters,
    allStudyCenters: queriesData[0].docs,
  };
  let companyDocument = queriesData[1];
  data.companyName = companyDocument.data().companyName;
  // get study's applications
  let applications = getAllowedCentersApplications(
    queriesData[2].docs,
    allowedCenters,
  );
  data.applicants = applications.length;
  let completed = 0;
  let scheduled = 0;
  let applied = 0;
  for (let index = 0; index < applications.length; index++) {
    const application = applications[index].data();
    const applicationStatus = application.status[application.status.length - 1];
    if (applicationStatus.status === 'Completed') {
      completed += 1;
    } else if (applicationStatus.status === 'Applied') {
      applied += 1;
    } else if (applicationStatus.status === 'Confirmed Appointment') {
      scheduled += 1;
    } else {
      applied += 1;
    }
  }
  let applicationsData = [];
  for (let index = 0; index < applications.length; index++) {
    let applicationData = applications[index].data();
    applicationData.doc = applications[index];
    applicationsData.push(applicationData);
  }
  data.applications = applicationsData;
  data.completed = completed;
  data.applied = applications.length;
  data.scheduled = scheduled;

  return data;
}

async function getAllStudiesTheUserIsIn() {
  const currentUser = firebase.auth().currentUser;
  var teams = (
    await db
      .collectionGroup('team')
      .where('email', '==', currentUser.email)
      .get()
  ).docs;
  let companyId = '';
  let studiesData = [];
  for (let index = 0; index < teams.length; index++) {
    const team = teams[index];
    let ids = team.ref.path.split('/');
    companyId = ids[1];
    studiesData.push({
      NCTID: ids[3],
      center: ids[5],
      companyId,
    });
  }
  let getStudiesAwaitFunctions = [];
  let memberInCenters = [];
  for (let index = 0; index < studiesData.length; index++) {
    const studyInRelationship = studiesData[index];
    getStudiesAwaitFunctions.push(
      db
        .collection('companies')
        .doc(studyInRelationship.companyId)
        .collection('studies')
        .doc(studyInRelationship.NCTID)
        .get(),
    );
    if (!memberInCenters.includes(studyInRelationship.center)) {
      memberInCenters.push(studyInRelationship.center);
    }
  }
  let studiesDocuments = await Promise.all(getStudiesAwaitFunctions);
  return {
    studiesDocuments: studiesDocuments,
    memberInCenters: memberInCenters,
  };
}

async function getCentersByStudyRef(ref) {
  let centers = (await ref.collection('centers').get()).docs;
  return centers;
}

async function getTeamByStudyRef(ref) {
  let team = (await ref.collection('team').get()).docs;
  let teamFormattedData = [];
  for (let index = 0; index < team.length; index++) {
    const member = team[index];
    let memberData = member.data();
    let userData = await UserService.getUserDataByEmail(memberData.email);
    teamFormattedData.push({
      memberData: {
        ...memberData,
        ...userData,
      },
      memberDoc: member,
    });
  }
  return teamFormattedData;
}

async function getSupportTeamByStudyRef(ref) {
  let team = (await ref.collection('team').get()).docs;
  let teamFormattedData = [];
  for (let index = 0; index < team.length; index++) {
    const member = team[index];
    let memberData = member.data();
    if (memberData.role !== 'Support') continue;
    let userData = await UserService.getUserDataByEmail(memberData.email);
    teamFormattedData.push({
      memberData: {
        ...memberData,
        ...userData,
      },
      memberDoc: member,
    });
  }
  return teamFormattedData;
}

async function addTeamMemberToStudyByRef(ref, email, role) {
  return ref
    .collection('team')
    .add({
      email: email,
      role: role,
      status: 'pending',
    })
    .then(async (docRef) => {
      return docRef;
    })
    .catch((error) => {
      console.error('Error adding document: ', error);
      return false;
    });
}

async function updateTeamMemberByRef(ref, data) {
  await ref.update(data);
  return true;
}

export async function updateApplicationByDoc(ref, data) {
  await ref.update(data);
  return true;
}

async function updateCenterByRef(ref, data) {
  await ref.update(data);
  return true;
}

async function updateStudyByRef(ref, data) {
  await ref.update(data);
  return true;
}

async function createApplication(applicationData) {
  return await db
    .collection('applications')
    .doc()
    .set({
      ...applicationData,
      appliedAt: firebase.firestore.Timestamp.now(),
      selectedDate: null,
      selectedTime: null,
      approvedDateAndTime: null,
    })
    .then(function (docRef) {
      return { success: true };
    })
    .catch(function (error) {
      return { success: false };
      console.error('Error adding document: ', error);
    });
}

async function getApplications() {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    var applications = await db
      .collection('applications')
      .where('userId', '==', currentUser.uid)
      .get();
    return applications.docs;
  } else {
    return null;
  }
}

async function getSponsoredByStudyId(studyId) {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    var applications = await db
      .collection('sponsored')
      .where('studyId', '==', studyId)
      .get();
    return applications.docs;
  } else {
    return null;
  }
}

async function getApplicationById(uid, applications) {
  if (uid) {
    var applications = await db
      .collectionGroup('applications')
      .where('id', '==', uid)
      .get();
    if (applications.docs.length > 0) {
      return applications.docs[0];
    }
    return null;
  } else {
    return null;
  }
}

async function getUser() {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    var user = await db.collection('users').doc(currentUser.uid).get();
    if (user.exists) {
      return user.data();
    } else {
      return null;
    }
  } else {
    return null;
  }
}

async function getUserPages() {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    var pages = (await db.collection('pages').doc(currentUser.uid).get()).docs;
    return pages;
  } else {
    return null;
  }
}

async function getUserOptionalStudies() {
  let currentUser = firebase.auth().currentUser;
  if (currentUser) {
    var optionalStudies = (
      await db.collection('optionalStudies').doc(currentUser.uid).get()
    ).data();
    return optionalStudies;
  } else {
    return null;
  }
}

async function updateStudies(studies) {
  let user = firebase.auth().currentUser;
  if (user) {
    const docRef = db.collection('optionalStudies').doc(user.uid);
    await docRef.update({ studies: studies });
    return true;
  } else {
    return false;
  }
}

async function updateApplicationById(id, data) {
  if (id) {
    const docRef = db.collection('applications').doc(id);
    await docRef.update(data);
    return true;
  } else {
    return false;
  }
}

async function updateUser(newData) {
  let user = firebase.auth().currentUser;
  if (user) {
    const docRef = db.collection('users').doc(user.uid);
    await docRef.update(newData);
    return true;
  } else {
    return false;
  }
}

async function createUser(uid, userData) {
  await db
    .collection('users')
    .doc(uid)
    .set({
      ...userData,
    })
    .then(function (docRef) {
      let docId = docRef.id;
      return docId;
    })
    .catch(function (error) {
      console.error('Error adding document: ', error);
    });
}
