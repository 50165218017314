import React, { useState, useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    IconButton,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { SnackbarContext } from '../../contexts/Snackbar';
import { StudyContext } from '../../contexts/StudyContext';

const EditCenterNameDialog = ({ studyCenterData, studyCenterRef }) => {
    const { getCenters } = useContext(StudyContext);
    const { show } = useContext(SnackbarContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [centerName, setCenterName] = useState('');

    const handleOpen = () => {
        // Initialize with current name when opening
        if (studyCenterData && studyCenterData.LocationFacility) {
            setCenterName(studyCenterData.LocationFacility);
        }
        setOpen(true);
    };

    const handleClose = () => {
        if (!loading) {
            setOpen(false);
        }
    };

    const handleSave = async () => {
        if (!centerName.trim()) {
            show('error', 'Center name cannot be empty');
            return;
        }

        setLoading(true);
        try {
            // Update only the LocationFacility field, preserving other data
            await studyCenterRef.set(
                {
                    LocationFacility: centerName.trim()
                },
                { merge: true }
            );

            show('success', 'Center name updated successfully');
            getCenters(); // Refresh the centers data
            handleClose();
        } catch (error) {
            console.error('Error updating center name:', error);
            show('error', 'Failed to update center name');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Tooltip title="Edit Center Name">
                <IconButton onClick={handleOpen} size="small">
                    <EditIcon fontSize="small" />
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Edit Study Center Name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Center Name"
                        fullWidth
                        variant="outlined"
                        value={centerName}
                        onChange={(e) => setCenterName(e.target.value)}
                        disabled={loading}
                        InputProps={{
                            sx: { fontSize: '1rem' }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                        disabled={loading || !centerName.trim()}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditCenterNameDialog;