// src/components/FileUpload.js
import React, { useContext, useEffect, useRef, useState } from 'react';
import firebase from '../../../Firebase';
import { StudyContext } from '../../../contexts/StudyContext';
import { v4 as uuidv4 } from 'uuid';
import { StudiesContext } from '../../../contexts/Studies';
import { NoteAddRounded } from '@material-ui/icons';
import { IconButton, Tooltip } from '@mui/material';
import { SnackbarContext } from '../../../contexts/Snackbar';

const UploadFile = ({ study, application }) => {
  const { updateStudyApplication } = useContext(StudiesContext);
  const { show } = React.useContext(SnackbarContext);
  const { studyRef } = useContext(StudyContext);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState('');
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      setLoading(true);
      const storageRef = await firebase
        .storage()
        .ref()
        .child(`/${studyRef.path}/documents/${uuidv4()}`)
        .put(file);
      const downloadURL = await storageRef.ref.getDownloadURL();
      show('success', 'Records uploaded successfully');
      updateStudyApplication(study, application, { files: [downloadURL] });
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Uploading...</p>;
  }

  return (
    <div style={{ fontFamily: "'Poppins', sans-serif" }}>
      <Tooltip
        title={
          application?.files?.length > 0 ? 'Replace Records' : 'Upload Records'
        }
        sx={{
          fontFamily: 'arial',
        }}
        style={{ fontFamily: "'Poppins', sans-serif" }}
      >
        <IconButton
          htmlFor="file-upload"
          onClick={() => hiddenFileInput.current.click()}
        >
          <NoteAddRounded />
        </IconButton>
      </Tooltip>
      <input
        id="file-upload"
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={hiddenFileInput}
      />
    </div>
  );
};

export default UploadFile;
