const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  pageTitle: {
    color: '#4A4A4A',
    textAlign: 'left',
    fontSize: 48,
  },
  searchInput: {
    backgroundColor: '#F4F4F4',
    width: 350,
    height: 38,
    borderRadius: 10,
    color: '#4A4A4A',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    border: 'none',
    paddingRight: 8,
    paddingLeft: 8,
    marginTop: 6,
    paddingRight: 32,
  },
  adminLabel: {
    marginTop: '3%',
    fontSize: 12,
    color: '#4fb4e8',
    fontWeight: 'bold',
    letterSpacing: '0.3em',
  },
  tabButtonActive: {
    color: '#4fb4e8',
    cursor: 'pointer',
    textAlign: 'left',
    fontSize: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  tabButtonNotActive: {
    color: '#4A4A4A',
    textAlign: 'left',
    opacity: 0.5,
    cursor: 'pointer',
    fontSize: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  pageSubTitle: {
    fontSize: 17,
    color: '#4A4A4A',
    textAlign: 'left',
    marginTop: 25,
  },
  section: {
    color: '#4A4A4A',
    marginTop: 0,
    fontSize: 24,
  },
  discoverMore: {
    cursor: 'pointer',
    width: '100%',
    height: 308,
    backgroundColor: '#F4F4F4',
    borderRadius: 10,
    boxShadow: mobile ? 'none' : '0px 0px 25px -8px rgba(0, 0, 0, 0.3);',
  },
  discoverMoreTitle: {
    textAlign: 'center',
    color: '#B0B0B0',
    fontSize: 18,
    marginTop: 24,
  },
  updateTitle: {
    color: '#4fb4e8',
    fontSize: 18,
    textAlign: 'left',
    marginTop: 22,
  },
  updateSubTitle: {
    color: '#4A4A4A',
    textAlign: 'left',
    fontSize: 14,
    marginTop: 8,
  },
  articleTag: {
    color: '#5D5D5D',
    fontSize: 14,
    textAlign: 'left',
  },
  articleTitle: {
    color: '#171717',
    fontSize: 24,
    textAlign: 'left',
    marginTop: 12,
  },
  articleText: {
    color: '#171717',
    fontSize: 18,
    textAlign: 'left',
    marginTop: 12,
    paddingRight: 8,
  },
  update: {
    backgroundColor: '#F4F4F4',
    marginTop: mobile ? 18 : 32,
    borderRadius: 12,
    width: '100%',
    height: 90,
  },
  newsItem: {
    marginTop: 24,
    borderRadius: 12,
    width: '100%',
    // height: mobile ? 120 : 240
  },
  noAppointmentsBox: {
    width: '100%',
    height: 70,
    backgroundColor: '#F4F4F4',
    borderRadius: 10,
    marginTop: 24,
  },
  noAppointmentsTitle: {
    textAlign: 'center',
    color: '#B0B0B0',
    fontSize: mobile ? 15 : 17,
    fontWeight: 600,
    paddingTop: 22,
  },
  addNewStudyButton: {
    backgroundColor: '#4fb4e8',
    color: 'white',
    fontFamily: "'Poppins', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: 10,
    paddingLeft: 24,
    paddingRight: 24,
    height: 38,
    marginTop: 6,
    '&:hover': {
      backgroundColor: '#4fb4e8',
    },
  },
});
