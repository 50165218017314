import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  CircularProgress,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { TramRounded } from '@material-ui/icons';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const mobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1;

const useStyles = makeStyles((theme) => ({
  rareconnect: {
    color: '#4fb4e8',
  },
  title: {
    fontSize: 24,
    marginTop: 24,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 20,
    marginTop: 18,
    color: 'rgba(0,0,0,0.5)',
  },
  exportButton: {
    fontSize: 14,
    backgroundColor: '#4fb4e8',
    '&:hover': {
      backgroundColor: '#4fb4e8',
    },
    borderRadius: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
    position: 'absolute',
    bottom: 16,
    width: 'calc(100% - 48px)',
    height: 44,
    marginTop: 24,
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(10),
  },
}));

export default function AlertDialogSlide(props) {
  const classes = useStyles();

  const [exporting, setExporting] = useState(false);
  const [applicationsData, setApplicationsData] = useState([]);
  const [locationsData, setLocationsData] = useState([]);

  const handleClose = () => {
    props.setOpenExportPoup(false);
  };

  const getAgeFromDate = (date) => {
    let splitedDate = date.split('-');
    let formattedDate =
      splitedDate[0] + '-' + splitedDate[2] + '-' + splitedDate[1];
    return moment().diff(formattedDate, 'years', false);
  };

  const getArrayAsString = (date) => {
    return moment().diff(date, 'years', false);
  };

  useEffect(() => {
    let study = props.study;
    if (!study) return;
    getApplicationsData();
    getLocationsData();
  }, [props.study]);

  const getLocationsData = () => {
    let study = props.study;
    let applications = study.applications;
    let data = [];
    let centers = {};
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const applicationStatus =
        application.status[application.status.length - 1];
      if (centers.hasOwnProperty(application.studyLocation)) {
        if (applicationStatus === 'Completed') {
          centers[application.studyLocation].completed =
            centers[application.studyLocation].completed + 1;
        } else if (applicationStatus === 'Applied') {
          centers[application.studyLocation].applied =
            centers[application.studyLocation].applied + 1;
        } else if (applicationStatus === 'Schedule Appointment') {
          centers[application.studyLocation].scheduled =
            centers[application.studyLocation].scheduled + 1;
        } else {
          centers[application.studyLocation].applied =
            centers[application.studyLocation].applied + 1;
        }
      } else {
        let addressComponents =
          application.nearestLocationData.locationData.address_components;
        let stateShortName = '';
        let stateLongName = '';
        addressComponents.forEach((component) => {
          if (component.types.includes('administrative_area_level_1')) {
            stateShortName = component.short_name;
            stateLongName = component.long_name;
          }
        });
        if (applicationStatus === 'Completed') {
          centers[application.studyLocation] = {
            completed: 1,
            applied: 0,
            scheduled: 0,
            location: stateLongName,
            studyCenter: application.studyLocation,
          };
        } else if (applicationStatus === 'Applied') {
          centers[application.studyLocation] = {
            completed: 0,
            applied: 1,
            scheduled: 0,
            location: stateLongName,
            studyCenter: application.studyLocation,
          };
        } else if (applicationStatus === 'Schedule Appointment') {
          centers[application.studyLocation] = {
            completed: 0,
            applied: 0,
            scheduled: 1,
            location: stateLongName,
            studyCenter: application.studyLocation,
          };
        } else {
          centers[application.studyLocation] = {
            completed: 1,
            applied: 0,
            scheduled: 0,
            location: stateLongName,
            studyCenter: application.studyLocation,
          };
        }
      }
    }
    for (let index = 0; index < Object.keys(centers).length; index++) {
      const centerObject = centers[Object.keys(centers)[index]];
      data.push({
        key: index + 1,
        studyCenter: centerObject.studyCenter.replace(/-/g, ' '),
        applied: centerObject.applied,
        scheduled: centerObject.scheduled,
        completed: centerObject.completed,
        location: centerObject.location,
      });
    }
    setLocationsData(data);
  };

  const getApplicationsData = () => {
    let study = props.study;
    let applications = study.applications;
    let data = [];
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      data.push({
        key: index + 1,
        patientId: application.userId,
        age: getAgeFromDate(application.userData.birthDate),
        name: application.userData.firstName
          .replace('+', '')
          .replace('-', '')
          .replace('@', '')
          .replace('=', ''),
        email: application.userData.email,
        address: application.userData.locationData.description
          .replace(/,/g, ' -')
          .replace(/,/g, ' -'),
        phone: application.userData.phone
          .replace('-', '')
          .replace('@', '')
          .replace('=', '')
          .replace(/,/g, ' -'),
        gender: application.userData.gender
          .replace('+', '')
          .replace('-', '')
          .replace('@', '')
          .replace('=', '')
          .replace(/,/g, ' -'),
        // location: application.userData.locationDescription ? application.userData.locationDescription.replace(/,/g, ' -') : '',
        diagnosis: application.userData.bySpecialist ? 'Yes' : 'No',
        studyCenter: application.studyLocation.replace(/,/g, ' -'),
        status: application.status[application.status.length - 1].status,
        visitResults: 'Not Accepted',
        symptoms: application.symptoms
          .toString()
          .replace('+', '')
          .replace('-', '')
          .replace('@', '')
          .replace('=', '')
          .replace(/,/g, ' -'),
        medications: application.medications
          .toString()
          .replace('+', '')
          .replace('-', '')
          .replace('@', '')
          .replace('=', '')
          .replace(/,/g, ' -'),
        diseaseName: props.study.studyData.customTitle.replace(/,/g, ' -'),
        studyName: props.study.studyData.diseaseName
          ? props.study.studyData.diseaseName.replace(/,/g, ' -')
          : '',
      });
      console.log('props.study', props.study);
    }
    setApplicationsData(data);
  };

  const exportCSV = () => {
    setExporting(true);

    if (state.leads) {
      var fileTitle;
      var fileData;
      var headers;

      headers = {
        key: 'id',
        patientId: 'Patient ID',
        age: 'Age',
        name: 'Name',
        email: 'Email',
        address: 'Location',
        phone: 'Phone',
        gender: 'Gender',
        diagnosis: 'By Specialist',
        studyCenter: 'Study Center',
        status: 'Status',
        visitResults: 'Visit Results',
        symptoms: 'Symptoms',
        medications: 'Medications',
        diseaseName: 'Disease Name',
        studyName: 'Study Name',
      };
      fileTitle = 'LeadsReport';
      fileData = applicationsData;
      exportCSVFile(headers, fileData, fileTitle);
    }
    if (state.locations) {
      var fileTitle;
      var fileData;
      var headers;
      headers = {
        key: 'id',
        studyCenter: 'Study Center',
        applied: 'Applied',
        scheduled: 'Scheduled',
        completed: 'Completed',
        location: 'Location',
      };
      fileTitle = 'LocationsReport';
      fileData = locationsData;
      exportCSVFile(headers, fileData, fileTitle);
    }
    if (state.overview) {
      var fileTitle;
      var fileData;
      var headers;
      if (props.userType === 'master' || props.userType === 'study') {
        headers = {
          applied: 'Applied',
          scheduled: 'Scheduled',
          completed: 'Completed',
          gender: 'Gender',
          age: 'Age',
          diagnosed: 'Diagnosed',
          topCenters: 'Top Centers',
        };
      } else {
        headers = {
          applied: 'Applied',
          scheduled: 'Scheduled',
          completed: 'Completed',
          gender: 'Gender',
          age: 'Age',
          diagnosed: 'Diagnosed',
        };
      }

      fileTitle = 'OverviewReport';

      if (props.userType === 'master' || props.userType === 'study') {
        fileData = [
          {
            applied: props.study.applied,
            scheduled: props.study.scheduled,
            completed: props.study.completed,
            gender: `Male (${String(getMale())}%)`,
            age: `0-10 (${String(get10Minus())}%)`,
            diagnosed: `Yes (${String(getDiagnosed())}%)`,
            topCenters:
              top5.length > 0
                ? `${top5[0].stateLongName}, ${top5[0].stateShortName}`
                : '-',
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: `Male (${String(getMale())}%)`,
            age: `11-18 (${String(getAge11to18())}%)`,
            diagnosed: `No (${String(getNotDiagnosed())}%)`,
            topCenters:
              top5.length > 1
                ? `${top5[1].stateLongName}, ${top5[1].stateShortName}`
                : '-',
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: '-',
            age: `19-29 (${String(getAge19to29())}%)`,
            diagnosed: '-',
            topCenters:
              top5.length > 2
                ? `${top5[2].stateLongName}, ${top5[2].stateShortName}`
                : '-',
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: '-',
            age: `30-55 (${String(getAge30to55())}%)`,
            diagnosed: '-',
            topCenters: '-',
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: '-',
            age: `56-74 (${String(getAge56to74())}%)`,
            diagnosed: '-',
            topCenters: '',
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: '-',
            age: `75+ (${String(getAge75plus())}%)`,
            diagnosed: '-',
            topCenters: '-',
          },
        ];
      } else {
        fileData = [
          {
            applied: props.study.applied,
            scheduled: props.study.scheduled,
            completed: props.study.completed,
            gender: `Male (${String(getMale())}%)`,
            age: `0-10 (${String(get10Minus())}%)`,
            diagnosed: `Yes (${String(getDiagnosed())}%)`,
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: `Female (${String(getFemale())}%)`,
            age: `11-18 (${String(getAge11to18())}%)`,
            diagnosed: `No (${String(getNotDiagnosed())}%)`,
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: '-',
            age: `19-29 (${String(getAge19to29())}%)`,
            diagnosed: '-',
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: '-',
            age: `30-55 (${String(getAge30to55())}%)`,
            diagnosed: '-',
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: '-',
            age: `56-74 (${String(getAge56to74())}%)`,
            diagnosed: '-',
          },
          {
            applied: '-',
            scheduled: '-',
            completed: '-',
            gender: '-',
            age: `75+ (${String(getAge75plus())}%)`,
            diagnosed: '-',
          },
        ];
      }
      exportCSVFile(headers, fileData, fileTitle);
    }
  };

  const getTopLocations = () => {
    let applications = props.study.applications;
    let data = [];
    let centers = {};
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      let addressComponents =
        application.nearestLocationData.locationData.address_components;
      let stateShortName = '';
      let stateLongName = '';
      addressComponents.forEach((component) => {
        if (component.types.includes('administrative_area_level_1')) {
          stateShortName = component.short_name;
          stateLongName = component.long_name;
        }
      });
      if (centers.hasOwnProperty(stateShortName)) {
        centers[stateShortName].applications =
          centers[stateShortName].applications + 1;
      } else {
        centers[stateShortName] = {
          applications: 1,
          stateShortName,
          stateLongName,
          studyCenter: application.studyLocation.replace(/-/g, ' '),
          fill: '#4fb4e8',
        };
      }
    }

    let top5 = [
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
      { applications: -1 },
    ];

    for (let index = 0; index < Object.keys(centers).length; index++) {
      const centerObject = centers[Object.keys(centers)[index]];
      if (centerObject.applications >= top5[0].applications) {
        top5 = [centerObject, top5[0], top5[1], top5[2], top5[3]];
      } else if (centerObject.applications >= top5[1].applications) {
        top5 = [top5[0], centerObject, top5[1], top5[2], top5[3]];
      } else if (centerObject.applications >= top5[2].applications) {
        top5 = [top5[0], top5[1], centerObject, top5[2], top5[3]];
      } else if (centerObject.applications >= top5[3].applications) {
        top5 = [top5[0], top5[1], top5[2], centerObject, top5[3]];
      } else if (centerObject.applications >= top5[4].applications) {
        top5 = [top5[0], top5[1], top5[2], top5[3], centerObject];
      }
    }
    setTop5(top5);
  };

  useEffect(() => {
    getTopLocations();
  }, []);

  const [state, setState] = React.useState({
    fullReport: false,
    overview: false,
    leads: false,
    locations: false,
  });

  const [top5, setTop5] = React.useState([]);

  const handleChange = (event) => {
    if (event.target.name === 'fullReport') {
      if (event.target.checked) {
        setState({
          ...state,
          fullReport: true,
          overview: true,
          leads:
            props.userType === 'master' || props.userType === 'center'
              ? true
              : false,
          locations:
            props.userType === 'master' || props.userType === 'study'
              ? true
              : false,
        });
      } else {
        setState({
          ...state,
          fullReport: false,
          overview: false,
          leads: false,
          locations: false,
        });
      }
    } else {
      setState({ ...state, [event.target.name]: event.target.checked });
    }
  };

  const exportCSVFile = (headers, items, fileTitle) => {
    if (headers) {
      items.unshift(headers);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var universalBOM = '\uFEFF';

    csv = universalBOM + csv;

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

    setExporting(false);
    handleClose();
  };

  const convertToCSV = (objArray) => {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        var temp = array[i][index];
        if (line != '') line += ',';
        if (typeof array[i][index] === 'object') {
          temp = JSON.stringify(temp);
        }

        line += temp;
        // line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const { fullReport, overview, leads, locations } = state;

  const get10Minus = () => {
    let applications = props.study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age10minus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge11to18 = () => {
    let applications = props.study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age11to18 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge19to29 = () => {
    let applications = props.study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age19to29 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge30to55 = () => {
    let applications = props.study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age30to55 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge56to74 = () => {
    let applications = props.study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age56to74 /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getAge75plus = () => {
    let applications = props.study.applications;
    let age10minus = 0;
    let age11to18 = 0;
    let age19to29 = 0;
    let age30to55 = 0;
    let age56to74 = 0;
    let age75plus = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      const age = getAgeFromDate(application.userData.birthDate);
      if (age <= 10) {
        age10minus += 1;
      } else if (age >= 11 && age <= 18) {
        age11to18 += 1;
      } else if (age >= 19 && age <= 29) {
        age19to29 += 1;
      } else if (age >= 30 && age <= 55) {
        age30to55 += 1;
      } else if (age >= 56 && age <= 74) {
        age56to74 += 1;
      } else if (age >= 75) {
        age75plus += 1;
      }
    }
    return (
      (age75plus /
        (age10minus +
          age11to18 +
          age19to29 +
          age30to55 +
          age56to74 +
          age75plus)) *
      100
    );
  };

  const getFemale = () => {
    let applications = props.study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (female / (male + female)) * 100;
  };

  const getMale = () => {
    let applications = props.study.applications;
    let male = 0;
    let female = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (application.userData.gender === 'Male') {
        male += 1;
      } else {
        female += 1;
      }
    }
    return (male / (male + female)) * 100;
  };

  const getDiagnosed = () => {
    let applications = props.study.applications;
    let yes = 0;
    let no = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (!application.userData.diagnosedByDoctor) {
        no += 1;
      } else {
        yes += 1;
      }
    }
    return (yes / (yes + no)) * 100;
  };

  const getNotDiagnosed = () => {
    let applications = props.study.applications;
    let yes = 0;
    let no = 0;
    for (let index = 0; index < applications.length; index++) {
      const application = applications[index];
      if (!application.userData.diagnosedByDoctor) {
        no += 1;
      } else {
        yes += 1;
      }
    }
    return (no / (yes + no)) * 100;
  };

  return (
    <div>
      <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={'sm'}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-described
        by='alert-dialog-slide-description'
        style={{
          minWidth: mobile ? '100%' : 490,
          minHeight: mobile ? '100%' : 450,
        }}
        fullScreen={mobile ? true : false}
      >
        <DialogContent style={{ position: 'relative' }}>
          <IconButton
            style={{ position: 'absolute', right: 16, top: 16 }}
            onClick={handleClose}
          >
            <img
              alt=''
              src={require('../../../../Assets/Images/grayXIcon.png')}
              width={14}
              height={14}
            />
          </IconButton>
          <div>
            <Typography className={classes.title}>
              <span className={classes.rareconnect}>RareConnect</span> Report
            </Typography>
            <Typography className={classes.subTitle}>
              Please select what and how you would like to export
            </Typography>
            <FormControl
              required
              component='fieldset'
              className={classes.formControl}
            >
              {/* <FormLabel component="legend">Pick two</FormLabel> */}
              <FormGroup>
                {/* <FormControlLabel
                                control={<Checkbox checked={fullReport} onChange={handleChange} name="fullReport" />}
                                label="Full Report"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={overview} onChange={handleChange} name="overview" />}
                                label="Overview"
                            /> */}
                {(props.userType === 'master' ||
                  props.userType === 'master') && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={leads}
                          onChange={handleChange}
                          name='leads'
                        />
                      }
                      label='Leads'
                    />
                  )}
                {(props.userType === 'master' ||
                  props.userType === 'master') && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={locations}
                          onChange={handleChange}
                          name='locations'
                        />
                      }
                      label='Locations'
                    />
                  )}
              </FormGroup>
              {/* <FormHelperText>You can display an error</FormHelperText> */}
            </FormControl>
          </div>
          <Button
            className={classes.exportButton}
            onClick={exportCSV}
            disabled={
              state.fullReport === false &&
                state.overview === false &&
                state.leads === false &&
                state.locations === false
                ? true
                : false
            }
          >
            {exporting ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
              'Export CSV'
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
