import { ThemeOptions } from '@mui/material/styles';

const font3 = "'Poppins', sans-serif";

export default {
  typography: {
    fontFamily: ["'Poppins', sans-serif"].join(','),
    main: "'Poppins', sans-serif",
    h2: {
      fontFamily: font3,
      fontWeight: 900,
      fontSize: 48,
    },
    button: {
      fontFamily: font3,
    },
    outlined: {
      fontFamily: font3,
    },
    // h4: {
    //   fontFamily: font3,
    //   fontWeight: 'bold',
    // },
    input: {
      fontFamily: font3,
    },
  },

  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 'bold',
          fontSize: 16,
          borderRadius: 10,
          padding: '10px 20px',
          backgroundColor: '#4fb4e8',
          color: 'white',
          '&:hover': {
            backgroundColor: '#4fb4e8',
          },
          '&:active': {
            backgroundColor: '#4fb4e8',
          },
          '&:disabled': {
            backgroundColor: '#4fb4e8',
            color: 'white',
          },
        },
      },
    },
    MuiInput: {
      root: {
        fontFamily: font3,
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: font3,
      },
      label: {
        fontFamily: font3,
      },
    },
  },
  direction: 'ltr',
  palette: {
    background: {
      paper: 'white',
      default: 'white',
    },
    primary: {
      main: '#4fb4e8',
      secondary: '#ed3636',
      white: '#ed3636',
      dark: '#4A4A4A',
      gray: 'rgba(0, 0, 0, 0.6)',
    },
    secondary: {
      main: '#ed3636',
    },
  },
}
// as ThemeOptions;
